<template>
  <div class="container">
    <div class="box">
      <Tree name="分项能耗" :checked="false" :data="data" @gz01="getMessage02"></Tree>

      <div class="right">
        <div class="data">
          <el-radio-group v-model="radio3" size="small" style="margin-left: 1.5vw; margin-right: 1.5vw">
            <el-radio-button label="昨日"></el-radio-button>
            <el-radio-button label="上月"></el-radio-button>
            <el-radio-button label="去年"></el-radio-button>
            <el-radio-button label="自定义"></el-radio-button>
          </el-radio-group>

          <el-date-picker v-model="value4" v-if="radio3 === '自定义'" type="date" placeholder="选择日期">
          </el-date-picker>

          <el-button size="small" type="primary" style="margin-left: 1vw" @click="onSearch">查询</el-button>
        </div>
        <!-- 将所有的 box 放在同一个容器中 -->
        <div class="image">
          <div class="center">
            <div class="box1" id="box1"></div>
            <div class="box2" id="box2"></div>
            <div class="box3" id="box3"></div>
          </div>
          <div class="center-bottom">
            <div class="box4" id="box4"></div>
            <!-- <div class="box5" id="box5"></div>
            <div class="box6" id="box6"></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import Tree from "@/components/tree/leftTree.vue";
import request from "@/request/request";
import formatDates from "@/utils";
export default {
  components: {
    Tree,
  },
  data() {
    return {
      radio3: "昨日",
      value1: "2024-04-01",
      value2: "",
      value3: '',
      value4: '',
      value: "2024-1-30",
      data: [],
      areaData: [],
      valueData: [],
      type: '',
      parmas: {
        areaId: '1',
        startTime: '',
        // endTime: '',
        type: '1',
      },
    };
  },
  methods: {
    onSearch() {
      switch (this.radio3) {
        case "昨日":
          this.parmas.type = 1;
          // this.parmas.startTime = this.value1;
          // this.parmas.endTime = '';
          break;
        case "上月":
          this.parmas.type = 2;
          // this.parmas.startTime = this.value2;
          // this.parmas.endTime = '';
          break;
        case "去年":
          this.parmas.type = 3;
          // this.parmas.startTime = this.value3;
          // this.parmas.endTime = '';
          break;
        case "自定义":
          this.parmas.type = 1;

          // 将选择的日期转换为 UTC 时间
          var startDate = new Date(this.value4);
          startDate.setUTCHours(0, 0, 0, 0); // 设置时间为 00:00:00 UTC

          // 将 UTC 时间加一天
          startDate.setDate(startDate.getDate() + 1);

          // 设置 startTime
          this.parmas.startTime = startDate;

          break;


      }
      this.getPie();
      this.getData();
    },
    async getPie() {
      const res = await request({
        method: "post",
        url: "/type/getTypeTotalList",
        data: this.parmas,
      });
      this.nameData = res.data.content.map((x) => x.name);
      this.valueData = res.data.content.map((x) => x.value);
      this.areaData = res.data.content;
      this.box1();
      console.log('llllllllllllll', res.data.content);
    },

    box1() {
      var chartDom = document.getElementById("box1");

      var myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "3%",
          left: "center",
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["20%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
            },
            data: this.areaData,
            label: {
              position: "outer",
              alignTo: "none",
              bleedMargin: 20,
              formatter: "{b}: {c} ({d}%)", // 显示标签的格式
            },
          },
        ],
      };

      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },



    // async getData() {
    //   try {
    //     const res = await request({
    //       method: "post",
    //       url: "/energy/getEnergyData",
    //       data: this.parmas,
    //     });
    //     console.log('收到的数据:', res.data.content.arr);

    //     // 检查 arr 属性是否已定义
    //     if (res.data.content.arr) {
    //       const data = res.data.content.arr[0].data; // 获取数据数组
    //       console.log('这是渲染的数据', data)
    //       // 从数据数组中提取时间和数据值
    //       // 从数据数组中提取时间和数据值
    //       this.nameData = data.map(obj => {
    //         const timestamp = Object.keys(obj)[0]; // 获取时间戳
    //         return timestamp.split(' ')[1]; // 返回时间部分
    //       }); // 提取对象的第一个属性作为时间戳的时间部分
    //       this.valueData = data.map(obj => Object.values(obj)[0]); // 提取数据值
    //       console.log('nameData:', this.nameData);
    //       console.log('valueData:', this.valueData);



    //       // 数据获取成功后调用 box3 函数
    //       await this.box3();
    //       await this.box2();
    //       await this.box4();
    //     } else {
    //       console.error('数据内容未定义:', res.data);
    //     }
    //   } catch (error) {
    //     console.error('获取数据时出错:', error);
    //   }
    // },
    async getData() {
      try {
        const res = await request({
          method: "post",
          url: "/energy/getEnergyData",
          data: this.parmas,
        });
        console.log('收到的数据:', res.data.content.arr);

        if (res.data.content.arr) {
          const data = res.data.content.arr[0].data; // 获取数据数组
          console.log('这是渲染的数据', data);

          // 处理日期和值并更新参数
          this.handleData(data);

          // 数据获取成功后调用 box3 函数
          await this.box3();
          await this.box2();
          await this.box4();
        } else {
          console.error('数据内容未定义:', res.data);
        }
      } catch (error) {
        console.error('获取数据时出错:', error);
      }
    },

    handleData(data) {
      // 根据用户选择更新 x 轴和 y 轴的数据
      switch (this.radio3) {
        case "昨日":
          this.nameData = data.map(obj => {
            const timestamp = Object.keys(obj)[0]; // 获取时间戳
            return timestamp.split(' ')[1]; // 返回时间部分
          }); // 提取对象的第一个属性作为时间戳的时间部分
          this.valueData = data.map(obj => Object.values(obj)[0]); // 提取数据值
          break;
        case "上月":
          const currentDate = new Date();
          const lastMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
          const daysInLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();
          this.nameData = Array.from({ length: daysInLastMonth }, (_, i) => (i + 1).toString());
          this.valueData = data.map(obj => Object.values(obj)[0]);
          break;
        case "去年":
          const lastYearDate = new Date(new Date().getFullYear() - 1, 0, 1);
          const monthsInLastYear = 12;
          this.nameData = Array.from({ length: monthsInLastYear }, (_, i) => (i + 1).toString());
          this.valueData = data.map(obj => Object.values(obj)[0]);
          break;
        case "自定义":
          this.nameData = data.map(obj => {
            const timestamp = Object.keys(obj)[0]; // 获取时间戳
            return timestamp.split(' ')[1]; // 返回时间部分
          }); // 提取对象的第一个属性作为时间戳的时间部分
          this.valueData = data.map(obj => Object.values(obj)[0]); // 提取数据值
          break;
      }
    },



    async box3() {
      var chartDom = document.getElementById("box3");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          text: "照明插座用电",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        toolbox: {
          feature: {
            magicType: {
              type: ["line", "bar"],
            },
          },
        },
        xAxis: {
          type: "category",
          data: this.nameData, // 这里确保数据已经获取并赋值
        },
        yAxis: {
          type: "value",
          name: "kgce",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#999",
              width: 2,
              type: "solid",
            },
          },
          title: {
            text: "kgce",
            textStyle: {
              fontSize: 12,
              color: "#333",
            },
            padding: 0,
            rotate: 0,
            textAlign: "left",
            margin: 0,
          },
        },
        series: [
          {
            data: this.valueData, // 这里确保数据已经获取并赋值
            type: "bar",
          },
        ],
      };

      option && myChart.setOption(option);

      window.addEventListener("resize", function () {
        myChart.resize();
      });

    },
    async box2() {
      var chartDom = document.getElementById("box2");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          text: "区域能耗排名",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        toolbox: {
          feature: {
            magicType: {
              type: ["line", "bar"],
            },
          },
        },
        xAxis: {
          type: "category",
          data: this.nameData, // 这里确保数据已经获取并赋值
        },
        yAxis: {
          type: "value",
          name: "kgce",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#999",
              width: 2,
              type: "solid",
            },
          },
          title: {
            text: "kgce",
            textStyle: {
              fontSize: 12,
              color: "#333",
            },
            padding: 0,
            rotate: 0,
            textAlign: "left",
            margin: 0,
          },
        },
        series: [
          {
            data: this.valueData, // 这里确保数据已经获取并赋值
            type: "bar",
          },
        ],
      };

      option && myChart.setOption(option);

      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    async box4() {
      var chartDom = document.getElementById("box4");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          text: "动力用电",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        toolbox: {
          feature: {
            magicType: {
              type: ["line", "bar"],
            },
          },
        },
        xAxis: {
          type: "category",
          data: this.nameData, // 这里确保数据已经获取并赋值
        },
        yAxis: {
          type: "value",
          name: "kgce",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#999",
              width: 2,
              type: "solid",
            },
          },
          title: {
            text: "kgce",
            textStyle: {
              fontSize: 12,
              color: "#333",
            },
            padding: 0,
            rotate: 0,
            textAlign: "left",
            margin: 0,
          },
        },
        series: [
          {
            data: this.valueData, // 这里确保数据已经获取并赋值
            type: "bar",
          },
        ],
      };

      option && myChart.setOption(option);

      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },



    /*获取左侧侧边栏内容 */
    async getMessage02(data) {
      console.log(data, 677777);
      const res = await request({
        url: "/system/equipEquipment",
        params: {
          areaIds: data.join(','),
          eqName: this.eqName
        }
      });
      this.tableData = res.data.content;

    },
    transformData(data, pid = 0) {
      if (!data) {
        return []; // 如果data为null或undefined，则返回一个空数组
      }
      const result = [];
      for (const node of data) {
        if (node.pid === pid) {
          const newNode = {
            id: node.id,
            label: node.name,
            children: this.transformData(data, node.id),
          };
          result.push(newNode);
        }
      }
      return result;
    },

    /*获取左侧侧边栏内容 */
    async getArea() {
      const res = await request("/area/getAreaList");
      this.data = this.transformData(res.data.content);
      console.log('000000', res.data.content);
    },
    getMsg(msg) {
      this.isExpanded = msg;
    },



    // box5() {
    //   var chartDom = document.getElementById("box5");
    //   var myChart = echarts.init(chartDom);
    //   var option;

    //   option = {
    //     title: {
    //       text: "其他用电",
    //     },
    //     tooltip: {
    //       trigger: "axis",
    //     },
    //     legend: {
    //       data: ["Email", "Union Ads", "Video Ads", "Direct", "Search Engine"],
    //       top: "bottom",
    //       left: "center",
    //     },
    //     grid: {
    //       left: "3%",
    //       right: "20%",
    //       bottom: "20%",
    //       top: 50, // 调整上边距
    //       containLabel: true,
    //       width: "80%",
    //       height: "60%",
    //     },
    //     toolbox: {
    //       feature: {
    //         saveAsImage: {},
    //       },
    //     },
    //     xAxis: {
    //       type: "category",
    //       boundaryGap: false,
    //       data: ["0.00", "4.00", "8.00", "12.00", "16.00", "20.00"],
    //     },
    //     yAxis: {
    //       type: "value",
    //       axisLabel: {
    //         formatter: "{value} kW", // 使用 {value} 表示原始刻度值，这里添加了 "kW" 作为单位
    //       },
    //       axisLine: {
    //         show: true,
    //         lineStyle: {
    //           color: "#999",
    //           width: 2,
    //           type: "solid",
    //         },
    //       },
    //     },
    //     series: [
    //       {
    //         name: "Email",
    //         type: "line",
    //         stack: "Total",
    //         data: [120, 132, 101, 134, 90, 230, 210],
    //       },
    //       {
    //         name: "Union Ads",
    //         type: "line",
    //         stack: "Total",
    //         data: [220, 182, 191, 234, 290, 330, 310],
    //       },
    //     ],
    //     graphic: [
    //       {
    //         type: "text",
    //         left: 45,
    //         top: 26,
    //         z: 100,
    //         style: {
    //           text: "kWh",
    //           fontSize: 14,
    //           fontWeight: "bold",
    //         },
    //       },
    //     ],
    //   };

    //   // 设置图表的初始大小
    //   myChart.setOption(option);

    //   // 监听窗口大小变化，调整图表大小
    //   window.addEventListener("resize", function () {
    //     myChart.resize();
    //   });
    // },
    // box6() {
    //   var chartDom = document.getElementById("box6");
    //   var myChart = echarts.init(chartDom);
    //   var option;

    //   option = {
    //     title: {
    //       text: "真空泵",
    //     },
    //     tooltip: {
    //       trigger: "axis",
    //     },
    //     legend: {
    //       data: ["Email", "Union Ads", "Video Ads", "Direct", "Search Engine"],
    //       top: "bottom",
    //       left: "center",
    //     },
    //     grid: {
    //       left: "3%",
    //       right: "20%",
    //       bottom: "20%",
    //       top: 50, // 调整上边距
    //       containLabel: true,
    //       width: "80%",
    //       height: "60%",
    //     },
    //     toolbox: {
    //       feature: {
    //         saveAsImage: {},
    //       },
    //     },
    //     xAxis: {
    //       type: "category",
    //       boundaryGap: false,
    //       data: ["0.00", "4.00", "8.00", "12.00", "16.00", "20.00"],
    //     },
    //     yAxis: {
    //       type: "value",
    //       axisLabel: {
    //         formatter: "{value} kW", // 使用 {value} 表示原始刻度值，这里添加了 "kW" 作为单位
    //       },
    //       axisLine: {
    //         show: true,
    //         lineStyle: {
    //           color: "#999",
    //           width: 2,
    //           type: "solid",
    //         },
    //       },
    //     },
    //     series: [
    //       {
    //         name: "Email",
    //         type: "line",
    //         stack: "Total",
    //         data: [120, 132, 101, 134, 90, 230, 210],
    //       },
    //       {
    //         name: "Union Ads",
    //         type: "line",
    //         stack: "Total",
    //         data: [220, 182, 191, 234, 290, 330, 310],
    //       },
    //     ],
    //     graphic: [
    //       {
    //         type: "text",
    //         left: 45,
    //         top: 26,
    //         z: 100,
    //         style: {
    //           text: "kWh",
    //           fontSize: 14,
    //           fontWeight: "bold",
    //         },
    //       },
    //     ],
    //   };

    //   // 设置图表的初始大小
    //   myChart.setOption(option);

    //   // 监听窗口大小变化，调整图表大小
    //   window.addEventListener("resize", function () {
    //     myChart.resize();
    //   });
    // },
  },

  mounted() {
    // this.getData();
    this.getArea();
    this.onSearch()
    // this.getPie();
    // this.box1();
    // this.box2();
    // this.box3();
    // this.box4();
    // this.box5();
    // this.box6();
  },
};
</script>

<style scoped>
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  position: relative;
  width: 100vw;
  height: 6vh;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  /* 设置下边框高度 */
  background-color: #ccc;
  /* 设置下边框颜色 */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  /* 设置下边框内部阴影 */
}

.tab {
  width: 70vw;
  padding: 10px;
  box-sizing: border-box;
}

.btn {
  width: 30vw;
  padding: 10px;
  box-sizing: border-box;
}

.box {
  width: 100vw;
  height: 94vh;
  display: flex;
}

.left {
  width: 8vw;
  display: flex;
  flex-direction: column;
  background-color: #cfd3dc;
  overflow: hidden;
}

.title {
  width: 100%;
  box-sizing: border-box;
}

.inline-input {
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;
}

.select {
  width: 12vw;
  height: 80vh;
}

.right {
  width: 87vw;
  display: flex;
  flex-direction: column;
}

.data {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.date {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

/* .data .active {
  background-color: rgb(0, 204, 255);
  color: white;
} */

.image {
  width: 77vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.center,
.center-bottom {
  width: 77vw;
  height: 40vh;
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;
  margin-top: 1rem;
}

.box1,
.box2,
.box3,
.box4,
.box5,
.box6 {
  flex: 1;
  height: 40vh;
  border: 2px solid #ccc;
  border-radius: 4px;
  margin: 8px;
  margin-bottom: 20px;
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 12rem;
}

::v-deep .el-date-editor .el-range-separator {
  height: 100%;
  padding: 0 0.3rem;
  margin: 0;
  text-align: center;
  line-height: 2rem;
  font-size: 0.8rem;
  width: 5%;
  color: #303133;
}

::v-deep .el-date-editor .el-range__icon {
  line-height: 2rem;
}

::v-deep .el-date-editor .el-range-input {
  font-size: 0.8rem;
}

::v-deep .el-range-editor.el-input__inner {
  width: 16rem !important;
  border-radius: 0.25rem;
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.9rem !important;
  font-size: 0.8rem;
}

.el-button {
  width: 4rem;
  height: 2rem;
  font-size: 0.8rem !important;
  padding: 0;
}

::v-deep .el-radio-button__inner {
  padding: 0.5rem 1rem !important;
  font-size: 0.8rem !important;
}

::v-deep .el-input--suffix .el-input__inner {
  padding-left: 1.8rem !important;
  padding-right: 1.8rem !important;
}

::v-deep .el-input__inner {
  width: 12rem;
  border-radius: 0.25rem;
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.9rem;
  font-size: 0.8rem;
}

::v-deep .el-input {
  font-size: 0.8rem;
}

::v-deep .el-input__icon {
  width: 1.5rem;
  font-size: 0.8rem !important;
  line-height: 2rem;
}

.el-date-editor .el-range__icon {
  font-size: 0.8rem !important;
  line-height: 2rem;
}

::v-deep .el-date-editor .el-input,
.el-date-editor .el-input__inner {
  width: 12rem;
}

::v-deep .el-date-editor .el-range-input {
  font-size: 0.6rem;
}

::v-deep .el-date-editor .el-range__icon {
  font-size: 0.8rem;
  line-height: 1.9rem;
}

span {
  font-size: 0.8rem !important;
}
</style>
