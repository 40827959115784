<template>
  <el-card style="background-color: transparent; border: none">
    <div ref="chart" style="width: 100%; height: 160%"></div>
  </el-card>
</template>
<script>
import * as echarts from "echarts";
import request from "@/request/request";
export default {
  name: "TableOne",
  data() {
    return {
      // 定义你的图表数据
      title: "售票数量",
      EqType:[],
      color: ["#23FFF7", "#1786FF", "#FBD74B", "#F86680"],
      data1: [
        { value: 100, name: "电" },
        { value: 89, name: "天然气" },
        { value: 48, name: "汽油" },
        { value: 59, name: "柴油" },
        { value: 71, name: "柴油" },
        { value: 109, name: "柴油" },

      ],
    };
  },
  methods: {
    async getAllEqType(){
      const res = await request('/eq-type/getAllEqType')
      this.EqType = res.data.content
      this.data1.map((x,index)=>{
        x.name = this.EqType[index].name
      })
      console.log(res.data.content);
    this.initChart();

    },
    initChart() {
      // 获取容器
      const chartContainer = this.$refs.chart;

      // 使用 echarts.init 初始化图表
      const chart = echarts.init(chartContainer);
      console.log(chart, "pppppp");
      // 定义图表配置项
      const option = {
        color: this.color,
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          icon: "rect",
          x: "60%",
          y: "center",
          itemWidth: 10,
          itemHeight: 5,
          itemGap: 10,
          align: "left",
          textStyle: {
            color: "rgb(132,159,186)",
            fontSize: '0.8rem',
            padding: [0, 0, 0, 10],
          },
          formatter: (name) => {
            let total = this.data1.reduce((a, b) => a + b.value, 0);
            let str = "";
            let p = 0;
            for (let i = 0; i < this.data1.length; i++) {
              if (this.data1[i].name == name) {
                p = (this.data1[i].value / total) * 100;
                str = name + "       " + p.toFixed(0) + "%";
              }
            }
            return str;
          },
        },
        series: [
          {
            type: "pie",
            radius: ["45%", "60%"],
            center: ["35%", "50%"],
            data: this.data1,
            labelLine: {
              normal: {
                show: false,
              },
            },
            label: {
              normal: {
                show: false,
              },
            },
          },
        ],
      };

      // 使用 setOption 方法设置图表配置项
      chart.setOption(option);
      window.addEventListener("resize", function () {
        chart.resize();
      });
    },
  },
  mounted() {
    // 在组件挂载后初始化和绘制图表
    this.getAllEqType()
  
  },
};
</script>
<style lang="scss" scoped>
canvas {
  height: 210px;
}
</style>