import router from "./router";
import store from "./store";

import AOS from 'aos'
import 'aos/dist/aos.css'
AOS.init()

AOS.init({
  //全局设置：
  disable: false, //接受以下值：'phone','tablet','mobile',布尔值，表达式或函数,禁用不同设备下的aos
  startEvent: "DOMContentLoaded", //在文档上分派的事件的名称，AOS应该在该事件上进行初始化
  initClassName: "aos-init", //初始化后应用的类
  animatedClassName: "aos-animate", //应用于动画的类
  useClassNames: false, //如果为真，将在滚动中添加data-aos的内容作为类
  disableMutationObserver: false, //取消自动突变检测(高级)
  debounceDelay: 50, //在调整窗口大小时使用的取消延迟(高级)
  throttleDelay: 99, //滚动页面时使用的油门延迟（高级）

  //可以通过data-aos属性逐个设置的设置：
  offset: 120, //初始触发点的偏移量(单位:px)
  delay: 0, //延迟时间值从0到3000 ，步长为50毫秒，
  duration: 400, //持续时间,值从0到3000，步长为50毫秒
  easing: "ease", // AOS动画的默认缓动
  once: false, //动画是否应该只发生一次
  mirror: false, //当滚动经过元素时，是否应该将其动画化
  anchorPlacement: "top-bottom",
});
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import './css/a.css'
// import disableDevtool from 'disable-devtool';

// disableDevtool({});


Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");


