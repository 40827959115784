<template>
  <div class="neng" style="display: flex; width: 100vw">
    <Tree
      @transmit="getMessage"
      @gz01="getMessage02"
      :name="transformerName"
      :data="data"
      :checked="true"
    ></Tree>
    <!-- <div class="sidebar" :class="{ 'sidebar-expanded': isExpanded }">
   
      <div class="top01" v-show="isExpanded == true">
        <p>人工填报</p>
        <el-input placeholder="输入关键字进行过滤" v-model="filterText">
        </el-input>

        <el-tree
          show-checkbox
          class="filter-tree"
          :data="data"
          :props="defaultProps"
          default-expand-all
          :filter-node-method="filterNode"
          ref="tree"
        >
        </el-tree>
      </div>
    </div> -->

    <div class="re">
      <div class="right" :style="{ width: isExpanded ? '79vw' : '87vw' }">
        <!-- <div class="topp">
          <div class="data">

            <span>填报日期</span>
            <el-radio-group
              v-model="radio3"
              size="small"
              style="margin-left: 1.5vw; margin-right: 1.5vw"
            >
          >
              <el-radio-button label="月"></el-radio-button>
              <el-radio-button label="年"></el-radio-button>
            
            </el-radio-group>
            <el-date-picker
              v-if="radio3 === '日'"
              v-model="value"
              type="date"
              placeholder="选择日"
            >
            </el-date-picker>
            <el-date-picker
              v-if="radio3 === '月'"
              v-model="value2"
              type="month"
              placeholder="选择月"
            >
            </el-date-picker>
            <el-date-picker
              v-if="radio3 === '年'"
              v-model="value2"
              type="year"
              placeholder="选择年"
            >
            </el-date-picker>
            <el-date-picker
              v-if="radio3 === '自定义'"
              :picker-options="pickerOptions"
              v-model="value1"
              @change="onChange"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
            <el-form
              id="cgx"
              :model="ruleForm"
              ref="ruleForm"
              label-width="120px"
              class="demo-ruleForm"
            >
            </el-form>
            <el-button size="small" type="primary" style="margin-left: 1vw"
              >查询</el-button
            >
          </div>
        </div> -->

        <div class="bottom">
          <el-table
            :header-cell-style="{ background: '#ebeef5' }"
            :stripe="true"
            :fit="true"
            :data="tableData"
            height="400"
            border
            style="width: 100%"
          >
            <!-- <el-table-column prop="date" label="名称"> </el-table-column> -->
            <el-table-column
              width="100"
              v-for="(date, index) in convertedDates"
              :key="index"
              :prop="date.prop"
              :label="date.label"
            ></el-table-column>
            <!-- <el-table-column fixed="right" label="操作" width="100">
              <template>
                <span
                  @click="dialogVisible = true"
                  type="text"
                  size="small"
                  id="cgx"
                  >填报</span
                >
              </template>
            </el-table-column> -->
          </el-table>
          <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            width="48.5%"
            :modal-append-to-body="false"
          >
            <span
              v-for="(date, index) in dateArray"
              :key="index"
              style="padding: 1%; margin-bottom: 1%; display: inline-block"
            >
              {{ date }}
              <el-input
                v-model="inputValue"
                style="display: inline-block !important"
              ></el-input>
            </span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible = false"
                >确 定</el-button
              >
            </span>
          </el-dialog>
        </div>
      </div>
    </div>
    <!-- <button
      class="toggle-button"
      @click="toggleSidebar"
      :class="{ 'button-expanded': isExpanded }"
    >
     
      <i :class="isExpanded ? 'el-icon-s-fold' : 'el-icon-s-unfold'"></i>
    </button> -->
  </div>
</template>

<script>
import request from "../../request/request";
import * as echarts from "echarts";
import Tree from "../../components/tree/leftTree.vue";
export default {
  components: {
    Tree,
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },

  data() {
    return {
      newData: [],
      additionalData: [],
      typeOneIds: [],
      gz: [],
      cgx11: "",
      convertedDates: [],
      data: [],
      formattedDates: [],
      cgx: [],
      cgx01: [],
      transformerName: "人工填报",
      inputValue: [],
      inputValues: [],
      dialogVisible: false,
      dateArray: [],
      ruleForm: {
        name: "0.38",
      },
      radio3: "月",
      radio03: "电",
      value: "2024-1-30",
      value2: "2024-1",
      tableData:[],
      activeName: "second",

      isExpanded: true,
      filterText: "",
      data: [
        {
          id: 1,
          label: "奥瑞金（佛山）",
          children: [
            {
              id: 4,
              label: "车间4",
            },
            {
              id: 5,
              label: "辅助车间",
            },
            {
              id: 6,
              label: "G01（高压进线）",
            },
            {
              id: 7,
              label: "RTO",
            },
            {
              id: 8,
              label: "P06_1#主变进线柜",
            },
            {
              id: 9,
              label: "P08_母联4000开关",
            },
            {
              id: 10,
              label: "P09_2#主变进线柜",
            },
            {
              id: 11,
              label: "1#主变",
            },
            {
              id: 12,
              label: "2#主变",
            },
            {
              id: 13,
              label: "3#主变",
            },
            {
              id: 14,
              label: "4#主变",
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  methods: {
    async getData() {
      const res = await request({
        url: "/dataAnalysis/listEquipment",
        method: "get",
        params: {},
      });
      // = res.data.content;
      console.log(res.data.content, "98989898998989");
      this.gz = res.data.content;
      // console.log(this.gz, "gzgzgzgzgzgz");
      // for (const item of this.gz) {
      //   if (item.type === 1) {
      //     this.typeOneIds.push(item.id);
      //   }
      //   // console.log(this.typeOneIds, "qaaaaaaaaaaaaaaaaaaaaaaaa");
      // }

      this.data = this.transformData(res.data.content);
    },
    async getData02(cgx) {
      console.log(cgx, "ddddddddddddsssss");
      const res = await request({
        url: "/diagnosisDiagnosis/manually-fill-in-the-enquiry",
        method: "get",
        params: {
          date: "2024-05",
          ids: cgx,
          type: 2,
        },
      });
      console.log(res.data.content, "11111111111");

      for (var z = 0; z < res.data.content.length; z++) {
        this.cgx.push(res.data.content[z].name);
      }
      console.log(this.cgx, "qqqqqqqqqqq鬼这话说得合肥市");
      this.cgx = [...new Set(this.cgx)];
      console.log(res.data.content, "456888888");
      if (res.data.content.length > 0) {
        this.cgx01 = res.data.content[0].data.map((obj) => Object.keys(obj)[0]);
        console.log(this.cgx01, "fffffffff");
      }
      this.formattedDates = this.cgx01.map((dateString) => {
        const date = new Date(dateString);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const formattedDate = `${month}月${day}日`;
        return formattedDate;
      });
      console.log(this.formattedDates, "6666666");
      this.convertedDates = this.formattedDates.map((date, index) => {
        const prop = `time${index + 1 < 10 ? index + 1 : index + 1}`;
        return { prop, label: date };
      });
      const newDate = { prop: "name", label: "名称" };
      this.convertedDates.unshift(newDate);
      console.log(this.convertedDates, "gggggg");
      this.newData = [];
      console.log(res.data.content, "长股息ID是佛是否首都府士大夫撒放散阀");
      for (let i = 0; i < res.data.content.length; i++) {
        const item = res.data.content[i];
        const newItem = {
          name: item.name,
        };
        item.data.forEach((dataItem, index) => {
          const dataKey = Object.keys(dataItem)[0];
          const propName = `time${index + 1}`;
          newItem[propName] = dataItem[dataKey];
        });
        this.newData.push(newItem);
      }
      console.log(this.newData, "fshfdjksfhlsjdfsdal");
      this.tableData = this.newData;
      console.log(this.cgx, "现金哦发烧大部分是");
      this.additionalData = this.cgx;

      for (let q = 0; q < res.data.content.length; q++) {
        const item = res.data.content[q];
        const propName = `time${q + 1}`;
        item.data.forEach((dataItem) => {
          dataItem[propName] = dataItem[Object.keys(dataItem)[0]];
          delete dataItem[Object.keys(dataItem)[0]];
        });
      }
      console.log(this.additionalData, "打吧舒司法所打开");
      console.log(this.tableData, "00000000033333333333");

      for (var w = 0; w < this.additionalData.length; w++) {
        this.tableData[w].name = this.additionalData[w];
      }
      console.log(this.tableData, "00000000033333333333");
    },
    transformData(data, pid = 0) {
      if (!data) {
        return []; // 如果data为null或undefined，则返回一个空数组
      }
      const result = [];
      for (const node of data) {
        if (node.pid === pid) {
          const newNode = {
            id: node.id,
            label: node.name,
            children: this.transformData(data, node.id),
          };
          result.push(newNode);
        }
      }
      return result;
    },
    getMessage(data) {
      this.isExpanded = data;
    },
    async getMessage02(data) {
      const res = await request({
        url:'/diagnosisDiagnosis/manually-fill-in-the-enquiry',
        params:{
          ids:data.join(','),
          date:'2024-04',
          type:2
        }
      })
      for (var z = 0; z < res.data.content.length; z++) {
        this.cgx.push(res.data.content[z].name);
      }
      console.log(this.cgx, "qqqqqqqqqqq鬼这话说得合肥市");
      this.cgx = [...new Set(this.cgx)];
      console.log(res.data.content, "456888888");
      if (res.data.content.length > 0) {
        this.cgx01 = res.data.content[0].data.map((obj) => Object.keys(obj)[0]);
        console.log(this.cgx01, "fffffffff");
      }
      this.formattedDates = this.cgx01.map((dateString) => {
        const date = new Date(dateString);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const formattedDate = `${month}月${day}日`;
        return formattedDate;
      });
      console.log(this.formattedDates, "6666666");
      this.convertedDates = this.formattedDates.map((date, index) => {
        const prop = `time${index + 1 < 10 ? index + 1 : index + 1}`;
        return { prop, label: date };
      });
      const newDate = { prop: "name", label: "名称" };
      this.convertedDates.unshift(newDate);
      console.log(this.convertedDates, "gggggg");
      this.newData = [];
      console.log(res.data.content, "长股息ID是佛是否首都府士大夫撒放散阀");
      for (let i = 0; i < res.data.content.length; i++) {
        const item = res.data.content[i];
        const newItem = {
          name: item.name,
        };
        item.data.forEach((dataItem, index) => {
          const dataKey = Object.keys(dataItem)[0];
          const propName = `time${index + 1}`;
          newItem[propName] = dataItem[dataKey];
        });
        this.newData.push(newItem);
      }
      console.log(this.newData, "fshfdjksfhlsjdfsdal");
      this.tableData = this.newData;
      console.log(this.cgx, "现金哦发烧大部分是");
      this.additionalData = this.cgx;

      for (let q = 0; q < res.data.content.length; q++) {
        const item = res.data.content[q];
        const propName = `time${q + 1}`;
        item.data.forEach((dataItem) => {
          dataItem[propName] = dataItem[Object.keys(dataItem)[0]];
          delete dataItem[Object.keys(dataItem)[0]];
        });
      }
      console.log(this.additionalData, "打吧舒司法所打开");
      console.log(this.tableData, "00000000033333333333");

      for (var w = 0; w < this.additionalData.length; w++) {
        this.tableData[w].name = this.additionalData[w];
      }
      console.log(this.tableData, "00000000033333333333");
      console.log(res.data.content);
      // console.log(data, "eeeeeeeeeeeeeeeeerrrrrrrrrrrrrrrrrrrr");
      // data = data.filter((value) => !this.typeOneIds.includes(value));
      // console.log(data, "eeeeeeeeeeeeeeeeeeqqqqqqqqqqqqqqqqqq");
      // this.cgx11 = data.join(",");

      // console.log(this.cgx11, "ddddddddddddddsdsdsdsdsdsd");
      // this.getData02(this.cgx11);

      console.log(data,666666666666666);
    },
    getCurrentMonthDates() {
      // 获取当前日期
      var currentDate = new Date();
      
      // 获取当前月份的第一天
      var firstDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );

      // 获取当前月份的最后一天
      var lastDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );

      // 生成日期数组
      var datesArray = [];
      var tempDate = firstDayOfMonth; // 使用不同的变量名
      while (tempDate <= lastDayOfMonth) {
        var month = tempDate.getMonth() + 1;
        var day = tempDate.getDate();
        // 确保日期在单数时补零
        var formattedDate =
          (month < 10 ? "0" + month : month) +
          "月" +
          (day < 10 ? "0" + day : day) +
          "日";
        datesArray.push(formattedDate);
        tempDate.setDate(tempDate.getDate() + 1);
      }

      return datesArray;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    format(percentage) {
      return percentage === 100 ? "满" : `${percentage}%`;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    toggleSidebar() {
      this.isExpanded = !this.isExpanded;
    },
  },
  created() {
    // 调用方法获取当前月份的日期数组，并赋值给data中的dateArray
    this.dateArray = this.getCurrentMonthDates();
  },
  mounted() {
    this.getData();
    // this.getData02("11,12");
  },
};
</script>

<style lang="scss" scoped>
.zd {
  margin-top: 3px;
}
#cgx {
  margin-top: 1.2%;
  margin-left: 1%;
}
.data {
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding-top: 1%;
}
.topp {
  width: 99%;
  vertical-align: middle;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 1.5%;
  border-radius: 3px;
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.3);
  height: 10vh;
  background-color: #fff;
}
.vv {
  color: #07ccfa;
  font-size: 1.4vw;
  font-weight: 800;
}
#cgx {
  color: skyblue;
}
.topp02 {
  vertical-align: middle;
  width: 99%;
}
.topp03 {
  border-radius: 2px;
  margin-left: 0.5%;
  position: relative;
  display: inline-block;
  width: 32.8%;
  background-color: #fff;
  height: 10vh;
  margin-top: 1%;
  img {
    margin-left: 5%;
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  div {
    position: absolute;
    top: 20px;
    display: inline-block;
  }
}
#main {
  width: 100%;
  height: 50vh;
}
.elta {
  margin-left: 1%;
  margin-right: 1%;
}
.bottom {
  height: 70vh;
  width: 99%;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 1.5%;
  border-radius: 3px;
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.3);
  padding-top: 1%;
}
.el-table {
  padding: 1%;
  padding-top: 0;
}
.max02 {
  color: red;
}
.min02 {
  color: #658e63;
}
.bdy01 {
  width: 100%;
  margin: 3vw auto;

  margin-left: 3vw;
}

.kw {
  font-size: 0.8vw;
  font-weight: 400;
}
.fzl {
  font-size: 1vw;
}
.fz {
  color: #02ae4e;
}
.r05 {
  color: #02ae4e;
  font-size: 1.2vw;
  font-weight: 600;
}

::v-deep .el-progress-bar__outer {
  height: 20px !important;
}
.el-progress {
  width: 65%;
  display: inline-block;
}
.shiyong {
  color: #67c23a;
}
.re {
  width: 100%;

  background-color: #f1f4f5;
}
.neng {
  display: flex;
}
.right {
  background-color: #f1f4f5;
  width: 100%;
}
.top01 {
  p {
    display: inline-block;
    margin-top: 5%;
    margin-left: 2%;
  }
}
.sidebar {
  width: 2%;
  height: 100vh;
  background-color: #cfd3dc;
  transition: width 0.3s ease;
}

.sidebar-expanded {
  width: 12%;
}

.toggle-button {
  position: fixed;
  top: 50%;
  transform: translateY(0);
  left: 10%;
  width: 1.8vw;
  height: 2vw;
  background-color: #ccc;
  border: none;
  cursor: pointer;
  transition: transform 0.08s ease;
}
.el-tree {
  background-color: #cfd3dc;
}

.button-expanded {
  transform: translateX(495%);
}

::v-deep .el-input--suffix .el-input__inner {
  padding-left: 1.8rem !important;
  padding-right: 1.8rem !important;
}
::v-deep .el-input__inner {
  width: 10rem;
  border-radius: 0.25rem;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 0.9rem;
  font-size: 0.8rem;
}
::v-deep .el-input {
  font-size: 0.8rem;
}
::v-deep .el-input__icon {
  width: 1.5rem;
  font-size: 0.8rem !important;
  line-height: 2.5rem;
}
.el-date-editor .el-range__icon {
  font-size: 0.8rem !important;
  line-height: 2.5rem;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 10rem;
}
::v-deep .el-date-editor .el-range-input {
  font-size: 0.6rem;
}
::v-deep .el-date-editor .el-range-separator {
  font-size: 0.8rem;
  line-height: 1.9rem;
}
::v-deep .el-input--suffix .el-input__inner {
  padding-left: 1.8rem !important;
  padding-right: 1.8rem !important;
}
::v-deep .el-input__inner {
  width: 10rem;
  border-radius: 0.25rem;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 0.9rem;
  font-size: 0.8rem;
}
::v-deep .el-input {
  font-size: 0.8rem;
}
::v-deep .el-input__icon {
  width: 1.5rem;
  font-size: 0.8rem !important;
  line-height: 2.5rem;
}
.el-date-editor .el-range__icon {
  font-size: 0.8rem !important;
  line-height: 2.5rem;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 10rem;
}
::v-deep .el-date-editor .el-range-input {
  font-size: 0.6rem;
}
::v-deep .el-date-editor .el-range__icon {
  font-size: 0.8rem;
  line-height: 1.9rem;
}
::v-deep .el-table {
  font-size: 0.8rem !important;
}

::v-deep .el-tree-node__label {
  font-size: 0.7rem;
}

::v-deep .el-input__inner {
  font-size: 0.8rem;
}

.el-button {
  width: 3.5rem;
  height: 1.6rem;
  padding: 0.1rem;
  font-size: 0.8rem;
}
.el-button--primary {
  width: 3.5rem;
  height: 1.6rem;
  padding: 0.1rem;
  font-size: 0.8rem;
}
.el-button--small {
  width: 3.5rem;
  height: 1.6rem;
  padding: 0.1rem;
  font-size: 0.8rem;
}

::v-deep .el-radio-button--small .el-radio-button__inner {
  padding: 0.5rem;
  font-size: 0.8rem;
  border-radius: 0;
}

::v-deep .el-form--inline .el-form-item {
  display: inline-block;
  margin-right: 0.5rem;
  vertical-align: top;
}

::v-deep .el-form-item__label {
  font-size: 0.8rem;
}
</style>