<template>
  <div class="container">
    <div class="box">
      <Tree @transmit="getMsg" name="用能总览" :checked="false" :data="data" @gz01="getMessage02" @dataId="getId"></Tree>

      <div class="right" :style="{ width: isExpanded ? '76rem' : '86rem' }">
        <div class="data">
          <el-radio-group v-model="radio3" @change="changeStatus(radio3)" size="small"
            style="margin-left: 1.5vw; margin-right: 1.5vw">
            <el-radio-button label="昨日"></el-radio-button>
            <el-radio-button label="上月"></el-radio-button>
            <el-radio-button label="去年"></el-radio-button>
            <el-radio-button label="自定义"></el-radio-button>
          </el-radio-group>
          <!-- <el-date-picker
            v-if="radio3 === '昨日'"
            v-model="value"
            type="date"
            placeholder="选择日"
          >
          </el-date-picker>
          <el-date-picker
            v-if="radio3 === '上月'"
            v-model="value2"
            type="month"
            placeholder="选择月"
          >
          </el-date-picker>
          <el-date-picker
            v-if="radio3 === '去年'"
            v-model="value2"
            type="year"
            placeholder="选择年"
          >
          </el-date-picker> -->
          <el-date-picker v-if="radio3 == '自定义'" v-model="value1" type="date" placeholder="选择日期">
          </el-date-picker>
          <el-button v-if="radio3 == '自定义'" size="small" type="primary" style="margin-left: 1vw"
            @click="changeStatus('自定义')">查询</el-button>
          <div class="building">
            <span class="area">建筑面积:1000</span>
            <span class="type">类型:工业</span>
            <span class="people">用能人数:2000</span>
          </div>
        </div>
        <!-- 将所有的 box 放在同一个容器中 -->
        <div class="image">
          <div class="center">
            <el-card style="
                width: 33%;
                height: 40vh;
                margin-top: 0.5rem;
                margin-left: 0.5rem;
              ">
              <div class="num">
                <span class="text">总能耗</span>
                <div class="box1" id="box1"></div>
                <div class="next">
                  <div class="certain">
                    <span class="label">定额(kgce)</span><br />
                    <span class="value">111</span>
                  </div>
                  <div class="fact">
                    <span class="label">实际(kgce)</span><br />
                    <span class="value">222</span>
                  </div>
                  <div class="remain">
                    <span class="label">剩余(kgce)</span><br />
                    <span class="value">111</span>
                  </div>
                </div>
              </div>
            </el-card>
            <el-card style="
                width: 33%;
                height: 40vh;
                margin-top: 0.5rem;
                margin-left: 0.5rem;
              ">
              <div class="box2" id="box2"></div>
            </el-card>
            <el-card style="
                width: 33%;
                height: 40vh;
                margin-top: 0.5rem;
                margin-left: 0.5rem;
              ">
              <div class="pie">
                <div class="month">
                  <div class="today">
                    <span>{{ st01 }}</span><br />
                    <span class="value1">{{ st02 }}</span>
                  </div>
                  <div class="yesterday">
                    <span>{{ st03 }}</span><br />
                    <span class="value2">{{ st04 }}</span>
                  </div>
                  <div class="theday">
                    <span>{{ st05 }}</span><br />
                    <span class="value3">{{ st06 }}</span>
                  </div>
                </div>
                <div class="box3" id="box3"></div>
              </div>
            </el-card>
          </div>
          <div class="center-bottom">
            <el-card style="
                width: 33%;
                height: 40vh;
                margin-top: 1rem;
                margin-left: 0.5rem;
              ">
              <div class="box4" id="box4"></div>
            </el-card>
            <el-card style="
                width: 33%;
                height: 40vh;
                margin-top: 1rem;
                margin-left: 0.5rem;
              ">
              <div class="box5" id="box5"></div>
            </el-card>
            <el-card style="
                width: 33%;
                height: 40vh;
                margin-top: 1rem;
                margin-left: 0.5rem;
              ">
              <div class="box6" id="box6"></div>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tree from "@/components/tree/leftTree.vue";
import request from "@/request/request";
import * as echarts from "echarts";
import axios from "axios";
export default {
  components: { Tree },
  data() {
    return {
      st01: "",
      st02: "",
      st03: "",
      st04: "",
      st05: "",
      st06: "",
      dateString: "",
      xz01: [],
      xz02: [],
      xz03: [],
      riqi01: "",
      riqi02: "",
      value1: "",
      gz01: [],
      gz02: [],
      yg01: [],
      yg02: [],
      yg03: [],
      yc01: [],
      yc02: [],
      yc03: [],
      yc04: [],
      radio3: "昨日",
      value: "2024-1-30",
      value2: "2024-1",
      isExpanded: true,
      data: [],
      quid: "1",
    };
  },

  methods: {
    async getId(data) {
      console.log(data, "您好这里是选中的id");
      this.quid = data;
    },

    changeStatus(dd) {
      console.log(dd, "vvvvvvvvvvvvvvv");
      this.cgx(dd);
    },

    async cgx(dd) {
      if (dd == "上月") {
        //---------------------------------------------上月圆圈百分比的数据-------------------------
        // 假设 date 是您的日期对象

        const res02 = await request({
          url: "/energy/totalEngry",
          method: "post",
          data: {
            areaId: this.quid,
            type: "1",
          },
        });

        console.log(res02.data.content, "8888888888888888");
        this.st01 = res02.data.content[0].name.substring(0, 7);
        this.st02 = res02.data.content[0].value;

        this.st03 = res02.data.content[1].name.substring(0, 7);
        this.st04 = res02.data.content[1].value;

        this.st05 = res02.data.content[2].name.substring(0, 7);
        this.st06 = res02.data.content[2].value;
        //---------------------------------------------上月圆圈百分比的数据-------------------------
        // ----------------------------------------------------------点击上月之后的图表变化开始
        const res = await request({
          url: "/energy/getEnergyData",
          method: "post",
          data: {
            areaId: this.quid,
            type: "2", //1是昨天 2是上个月 3是去年
          },
        });

        this.gz02 = [];
        this.xz01 = [];
        this.xz02 = [];
        this.xz03 = [];
        this.riqi01 = "";
        this.riqi02 = "";
        this.gz01 = [];

        for (let g = 0; g < res.data.content.arr[0].data.length; g++) {
          const value = Object.values(res.data.content.arr[0].data[g])[0]; // 获取每个对象的值
          this.gz02.push(value);
        }
        this.xz01 = [];
        for (let i = 0; i < res.data.content.arr[0].data.length; i++) {
          const obj = res.data.content.arr[0].data[i]; // 获取每个对象
          const keys = Object.keys(obj); // 获取对象的所有属性名
          this.xz01.push(...keys);
        }
        this.xz03 = [];

        for (let p = 0; p < this.xz01.length; p++) {
          this.xz03.push(this.xz01[p].substring(6, 10));
        }

        //----------------------------------上月面积的x轴-------------------------------------
        this.yc01 = [];
        for (let e = 0; e < res.data.content.unitAreaArr[0].data.length; e++) {
          const obj = res.data.content.unitAreaArr[0].data[e]; // 获取每个对象
          const keys = Object.keys(obj); // 获取对象的所有属性名
          this.yc01.push(...keys);
        }
        this.yc02 = [];

        for (let q = 0; q < this.yc01.length; q++) {
          this.yc02.push(this.yc01[q].substring(6, 10));
        }

        //----------------------------------上月面积的x轴结束-------------------------------------

        //----------------------------------上月面积的y轴的值开始-------------------------------
        this.yc03 = [];
        for (let r = 0; r < res.data.content.unitAreaArr[0].data.length; r++) {
          const value = Object.values(
            res.data.content.unitAreaArr[0].data[r]
          )[0]; // 获取每个对象的值
          this.yc03.push(value);
        }

        //----------------------------------上月面积的y轴的值结束-------------------------------

        //----------------------------------上月单位面积能耗的y轴的值开始-------------------------------
        this.yc04 = [];
        for (let f = 0; f < res.data.content.unitAreaArr[1].data.length; f++) {
          const value = Object.values(
            res.data.content.unitAreaArr[1].data[f]
          )[0]; // 获取每个对象的值
          this.yc04.push(value);
        }

        this.box5();
        //----------------------------------上月单位面积能耗的y轴的值结束-------------------------------

        this.riqi02 = res.data.content.arr[0].name.substring(0, 7);

        this.gz01 = [];
        for (var k = 0; k < res.data.content.arr[1].data.length; k++) {
          const value = Object.values(res.data.content.arr[1].data[k])[0]; // 获取每个对象的值
          this.gz01.push(value);
        }

        this.riqi01 = res.data.content.arr[1].name.substring(0, 7);

        this.xz01 = [];
        for (let i = 0; i < res.data.content.arr[0].data.length; i++) {
          const obj = res.data.content.arr[0].data[i]; // 获取每个对象
          const keys = Object.keys(obj); // 获取对象的所有属性名
          this.xz01.push(...keys);
        }

        this.box2();

        this.yg01 = [];
        this.yg02 = [];
        this.yg03 = [];

        for (let i = 0; i < res.data.content.perCapitaArr[1].data.length; i++) {
          const obj = res.data.content.perCapitaArr[1].data[i]; // 获取每个对象
          const keys = Object.keys(obj); // 获取对象的所有属性名
          this.yg01.push(...keys);
        }

        for (let r = 0; r < this.yg01.length; r++) {
          this.yg02.push(this.yg01[r].substring(6, 10));
        }

        for (let g = 0; g < res.data.content.perCapitaArr[1].data.length; g++) {
          const value = Object.values(
            res.data.content.perCapitaArr[1].data[g]
          )[0]; // 获取每个对象的值
          this.yg03.push(value);
        }

        this.box6();
      } else if (dd == "去年") {
        //---------------------------------------------上月圆圈百分比的数据-------------------------

        const res02 = await request({
          url: "/energy/totalEngry",
          method: "post",
          data: {
            areaId: this.quid,

            type: "1",
          },
        });

        console.log(res02.data.content, "8888888888888888");
        this.st01 = res02.data.content[0].name.substring(0, 4);
        this.st02 = res02.data.content[0].value;

        this.st03 = res02.data.content[1].name.substring(0, 4);
        this.st04 = res02.data.content[1].value;

        this.st05 = res02.data.content[2].name.substring(0, 4);
        this.st06 = res02.data.content[2].value;

        // ----------------------------------------------------------点击去年之后的图表变化开始
        const res = await request({
          url: "/energy/getEnergyData",
          method: "post",
          data: {
            areaId: this.quid,
            type: "3", //1是昨天 2是上个月 3是去年
          },
        });

        this.gz02 = [];
        this.xz01 = [];
        this.xz02 = [];
        this.xz03 = [];
        this.riqi01 = "";
        this.riqi02 = "";
        this.gz01 = [];
        for (let g = 0; g < res.data.content.arr[0].data.length; g++) {
          const value = Object.values(res.data.content.arr[0].data[g])[0]; // 获取每个对象的值
          this.gz02.push(value);
        }
        this.xz01 = [];
        for (let i = 0; i < res.data.content.arr[0].data.length; i++) {
          const obj = res.data.content.arr[0].data[i]; // 获取每个对象
          const keys = Object.keys(obj); // 获取对象的所有属性名
          this.xz01.push(...keys);
        }

        for (let r = 0; r < this.xz01.length; r++) {
          this.xz03.push(this.xz01[r].substring(0, 7) + "月");
        }

        this.riqi02 = res.data.content.arr[0].name.substring(0, 4);

        this.gz01 = [];
        for (var k = 0; k < res.data.content.arr[1].data.length; k++) {
          const value = Object.values(res.data.content.arr[1].data[k])[0]; // 获取每个对象的值
          this.gz01.push(value);
        }
        console.log(this.gz01);
        this.riqi01 = res.data.content.arr[1].name.substring(0, 4);

        this.xz01 = [];
        for (let i = 0; i < res.data.content.arr[0].data.length; i++) {
          const obj = res.data.content.arr[0].data[i]; // 获取每个对象
          const keys = Object.keys(obj); // 获取对象的所有属性名
          this.xz01.push(...keys);
        }

        this.box2();

        this.yg01 = [];
        this.yg02 = [];
        this.yg03 = [];
        for (let i = 0; i < res.data.content.perCapitaArr[1].data.length; i++) {
          const obj = res.data.content.perCapitaArr[1].data[i]; // 获取每个对象
          const keys = Object.keys(obj); // 获取对象的所有属性名
          this.yg01.push(...keys);
        }

        for (let r = 0; r < this.yg01.length; r++) {
          this.yg02.push(this.yg01[r].substring(0, 7) + "月");
        }

        for (let g = 0; g < res.data.content.perCapitaArr[1].data.length; g++) {
          const value = Object.values(
            res.data.content.perCapitaArr[1].data[g]
          )[0]; // 获取每个对象的值
          this.yg03.push(value);
        }

        this.box6();

        //----------------------------------去年面积的x轴-------------------------------------
        this.yc01 = [];
        for (let e = 0; e < res.data.content.unitAreaArr[0].data.length; e++) {
          const obj = res.data.content.unitAreaArr[0].data[e]; // 获取每个对象
          const keys = Object.keys(obj); // 获取对象的所有属性名
          this.yc01.push(...keys);
        }
        this.yc02 = [];

        for (let q = 0; q < this.yc01.length; q++) {
          this.yc02.push(this.yc01[q].substring(0, 7) + "月");
        }

        //----------------------------------去年面积的x轴结束-------------------------------------

        //----------------------------------去年面积的y轴的值开始-------------------------------
        this.yc03 = [];
        for (let r = 0; r < res.data.content.unitAreaArr[0].data.length; r++) {
          const value = Object.values(
            res.data.content.unitAreaArr[0].data[r]
          )[0]; // 获取每个对象的值
          this.yc03.push(value);
        }

        //----------------------------------去年面积的y轴的值结束-------------------------------
        //----------------------------------上月单位面积能耗的y轴的值开始-------------------------------
        this.yc04 = [];
        for (let f = 0; f < res.data.content.unitAreaArr[1].data.length; f++) {
          const value = Object.values(
            res.data.content.unitAreaArr[1].data[f]
          )[0]; // 获取每个对象的值
          this.yc04.push(value);
        }

        this.box5();
        //----------------------------------上月单位面积能耗的y轴的值结束-------------------------------
      } else if (dd == "自定义") {
        // this.value1 = this.value1.substring(0, 10);

        // 假设 date 是您的日期对象
        let date = new Date(this.value1);

        // 获取年份、月份和日期
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从 0 开始，需要加 1
        let day = String(date.getDate()).padStart(2, "0");

        // 将年份、月份和日期拼接成字符串
        this.dateString = `${year}-${month}-${day}`;

        // 现在 dateString 就是 "YYYY-MM-DD" 格式的字符串

        if (this.dateString == "NaN-NaN-NaN") {
          this.dateString = null;
        }

        const res02 = await request({
          url: "/energy/totalEngry",
          method: "post",
          data: {
            areaId: this.quid,
            startTime: this.dateString,
            type: "1",
          },
        });

        console.log(res02.data.content, "8888888888888888");
        this.st01 = res02.data.content[0].name.substring(0, 10);
        this.st02 = res02.data.content[0].value;

        this.st03 = res02.data.content[1].name.substring(0, 10);
        this.st04 = res02.data.content[1].value;

        this.st05 = res02.data.content[2].name.substring(0, 10);
        this.st06 = res02.data.content[2].value;

        // ----------------------------------------------------------点击去年之后的图表变化开始

        // ---------------------------------------------------------------------点击去年之后的图表变化开始
        const res = await request({
          url: "/energy/getEnergyData",
          method: "post",
          data: {
            areaId: this.quid,
            type: "1", //1是昨天 2是上个月 3是去年
            startTime: this.dateString,
          },
        });

        this.gz02 = [];
        this.xz01 = [];
        this.xz02 = [];
        this.xz03 = [];
        this.riqi01 = "";
        this.riqi02 = "";
        this.gz01 = [];
        this.yg01 = [];
        this.yg02 = [];
        this.yg03 = [];
        for (let i = 0; i < res.data.content.perCapitaArr[0].data.length; i++) {
          const obj = res.data.content.perCapitaArr[0].data[i]; // 获取每个对象
          const keys = Object.keys(obj); // 获取对象的所有属性名
          this.yg01.push(...keys);
        }

        for (let r = 0; r < this.yg01.length; r++) {
          this.yg02.push(this.yg01[r].substring(6, 16));
        }

        for (let g = 0; g < res.data.content.perCapitaArr[0].data.length; g++) {
          const value = Object.values(
            res.data.content.perCapitaArr[0].data[g]
          )[0]; // 获取每个对象的值
          this.yg03.push(value);
        }

        this.box6();

        for (let g = 0; g < res.data.content.arr[0].data.length; g++) {
          const value = Object.values(res.data.content.arr[0].data[g])[0]; // 获取每个对象的值
          this.gz02.push(value);
        }
        this.xz01 = [];
        for (let i = 0; i < res.data.content.arr[0].data.length; i++) {
          const obj = res.data.content.arr[0].data[i]; // 获取每个对象
          const keys = Object.keys(obj); // 获取对象的所有属性名
          this.xz01.push(...keys);
        }

        for (let r = 0; r < this.xz01.length; r++) {
          this.xz03.push(this.xz01[r].substring(6, 16));
        }

        this.riqi02 = res.data.content.arr[0].name.substring(0, 10);

        this.gz01 = [];
        for (var k = 0; k < res.data.content.arr[0].data.length; k++) {
          const value = Object.values(res.data.content.arr[0].data[k])[0]; // 获取每个对象的值
          this.gz01.push(value);
        }

        this.riqi01 = res.data.content.arr[0].name.substring(0, 10);

        this.xz01 = [];
        for (let i = 0; i < res.data.content.arr[0].data.length; i++) {
          const obj = res.data.content.arr[0].data[i]; // 获取每个对象
          const keys = Object.keys(obj); // 获取对象的所有属性名
          this.xz01.push(...keys);
        }

        this.box2();

        //----------------------------------去年面积的x轴-------------------------------------
        this.yc01 = [];
        for (let e = 0; e < res.data.content.unitAreaArr[0].data.length; e++) {
          const obj = res.data.content.unitAreaArr[0].data[e]; // 获取每个对象
          const keys = Object.keys(obj); // 获取对象的所有属性名
          this.yc01.push(...keys);
        }
        this.yc02 = [];

        for (let q = 0; q < this.yc01.length; q++) {
          this.yc02.push(this.yc01[q].substring(6, 16));
        }

        //----------------------------------去年面积的x轴结束-------------------------------------

        //----------------------------------去年面积的y轴的值开始-------------------------------
        this.yc03 = [];
        for (let r = 0; r < res.data.content.unitAreaArr[0].data.length; r++) {
          const value = Object.values(
            res.data.content.unitAreaArr[0].data[r]
          )[0]; // 获取每个对象的值
          this.yc03.push(value);
        }

        //----------------------------------去年面积的y轴的值结束-------------------------------

        //----------------------------------上月单位面积能耗的y轴的值开始-------------------------------

        this.yc04 = [];
        if (res.data.content.unitAreaArr.length == 2) {
          for (
            let n = 0;
            n < res.data.content.unitAreaArr[1].data.length;
            n++
          ) {
            const value = Object.values(
              res.data.content.unitAreaArr[1].data[n]
            )[0]; // 获取每个对象的值
            this.yc04.push(value);
          }

          this.box5();
        } else {
          for (
            let n = 0;
            n < res.data.content.unitAreaArr[0].data.length;
            n++
          ) {
            const value = Object.values(
              res.data.content.unitAreaArr[0].data[n]
            )[0]; // 获取每个对象的值
            this.yc04.push(value);
          }

          this.box5();
        }

        //----------------------------------上月单位面积能耗的y轴的值结束-------------------------------
      } else {
        const res02 = await request({
          url: "/energy/totalEngry",
          method: "post",
          data: {
            areaId: this.quid,

            type: "1",
          },
        });

        console.log(res02.data.content, "8888888888888888");
        this.st01 = res02.data.content[0].name.substring(0, 10);
        this.st02 = res02.data.content[0].value;

        this.st03 = res02.data.content[1].name.substring(0, 10);
        this.st04 = res02.data.content[1].value;

        this.st05 = res02.data.content[2].name.substring(0, 10);
        this.st06 = res02.data.content[2].value;

        const res = await request({
          url: "/energy/getEnergyData",
          method: "post",
          data: {
            areaId: this.quid,
            type: "1", //1是昨天 2是上个月 3是去年
          },
        });

        this.gz02 = [];
        this.xz01 = [];
        this.xz02 = [];
        this.xz03 = [];
        this.riqi01 = "";
        this.riqi02 = "";
        this.gz01 = [];
        this.yg01 = [];
        this.yg02 = [];
        this.yg03 = [];
        for (let i = 0; i < res.data.content.perCapitaArr[1].data.length; i++) {
          const obj = res.data.content.perCapitaArr[1].data[i]; // 获取每个对象
          const keys = Object.keys(obj); // 获取对象的所有属性名
          this.yg01.push(...keys);
        }

        for (let r = 0; r < this.yg01.length; r++) {
          this.yg02.push(this.yg01[r].substring(6, 16));
        }

        for (let g = 0; g < res.data.content.perCapitaArr[1].data.length; g++) {
          const value = Object.values(
            res.data.content.perCapitaArr[1].data[g]
          )[0]; // 获取每个对象的值
          this.yg03.push(value);
        }

        this.box6();

        for (let g = 0; g < res.data.content.arr[0].data.length; g++) {
          const value = Object.values(res.data.content.arr[0].data[g])[0]; // 获取每个对象的值
          this.gz02.push(value);
        }
        this.xz01 = [];
        for (let i = 0; i < res.data.content.arr[0].data.length; i++) {
          const obj = res.data.content.arr[0].data[i]; // 获取每个对象
          const keys = Object.keys(obj); // 获取对象的所有属性名
          this.xz01.push(...keys);
        }

        for (let r = 0; r < this.xz01.length; r++) {
          this.xz03.push(this.xz01[r].substring(6, 16));
        }

        this.riqi02 = res.data.content.arr[0].name.substring(0, 10);

        this.gz01 = [];
        for (var k = 0; k < res.data.content.arr[1].data.length; k++) {
          const value = Object.values(res.data.content.arr[1].data[k])[0]; // 获取每个对象的值
          this.gz01.push(value);
        }

        this.riqi01 = res.data.content.arr[1].name.substring(0, 10);

        this.xz01 = [];
        for (let i = 0; i < res.data.content.arr[0].data.length; i++) {
          const obj = res.data.content.arr[0].data[i]; // 获取每个对象
          const keys = Object.keys(obj); // 获取对象的所有属性名
          this.xz01.push(...keys);
        }

        this.box2();

        //----------------------------------去年面积的x轴-------------------------------------
        this.yc01 = [];
        for (let e = 0; e < res.data.content.unitAreaArr[0].data.length; e++) {
          const obj = res.data.content.unitAreaArr[0].data[e]; // 获取每个对象
          const keys = Object.keys(obj); // 获取对象的所有属性名
          this.yc01.push(...keys);
        }
        this.yc02 = [];

        for (let q = 0; q < this.yc01.length; q++) {
          this.yc02.push(this.yc01[q].substring(6, 16));
        }

        //----------------------------------去年面积的x轴结束-------------------------------------

        //----------------------------------去年面积的y轴的值开始-------------------------------
        this.yc03 = [];
        for (let r = 0; r < res.data.content.unitAreaArr[0].data.length; r++) {
          const value = Object.values(
            res.data.content.unitAreaArr[0].data[r]
          )[0]; // 获取每个对象的值
          this.yc03.push(value);
        }

        //----------------------------------去年面积的y轴的值结束-------------------------------
        //----------------------------------上月单位面积能耗的y轴的值开始-------------------------------
        this.yc04 = [];
        for (let f = 0; f < res.data.content.unitAreaArr[1].data.length; f++) {
          const value = Object.values(
            res.data.content.unitAreaArr[1].data[f]
          )[0]; // 获取每个对象的值
          this.yc04.push(value);
        }

        this.box5();
        //----------------------------------上月单位面积能耗的y轴的值结束-------------------------------
      }
    },

    async cgx02() {
      const res02 = await request({
        url: "/energy/totalEngry",
        method: "post",
        data: {
          areaId: this.quid,
          startTime: "2024-02-20",

          type: "1",
        },
      });
      console.log(res02, "8888888888888888");
    },

    async getMessage02(data) {
      console.log(data, 6777777777777);
      const res = await request({
        url: "/system/equipEquipment",
        params: {
          areaIds: data.join(","),
          eqName: this.eqName,
        },
      });
      this.tableData = res.data.content;
    },

    async getMessage03(data) {
      console.log(data, 6777777777777888888888);
    },

    transformData(data, pid = 0) {
      if (!data) {
        return []; // 如果data为null或undefined，则返回一个空数组
      }
      const result = [];
      for (const node of data) {
        if (node.pid === pid) {
          const newNode = {
            id: node.id,
            label: node.name,
            children: this.transformData(data, node.id),
          };
          result.push(newNode);
        }
      }
      return result;
    },

    async getArea() {
      const res = await request("/area/getAreaList");
      this.data = this.transformData(res.data.content);
      console.log(res.data.content);
    },
    getMsg(msg) {
      this.isExpanded = msg;
    },

    box1() {
      // 获取图表容器
      var chartDom = document.getElementById("box1");
      // 初始化 ECharts 实例
      var myChart = echarts.init(chartDom);
      // 初始化选项配置
      var option;

      // 通过函数计算颜色
      function getColor(value) {
        if (value <= 30) {
          return "#00FF00";
        } else if (value <= 70) {
          return "#5c7bd9";
        } else {
          return "red";
        }
      }

      // 设置图表选项
      option = {
        tooltip: {
          formatter: "{a} <br/>{b} : {c}%",
          show: false
        },
        toolbox: {
          feature: {
            saveAsImage: {},
            restore: {},
            resize: true, // 启用缩放功能
          },
        },
        series: [
          {
            name: "Pressure",
            type: "gauge",
            detail: {
              show: false, // 隐藏原有的数值显示
            },
            axisLine: {
              lineStyle: {
                width: 6,
                color: [
                  [0.3, getColor(30)],
                  [0.7, getColor(70)],
                  [1, getColor(100)],
                ],
                progress: {
                  show: true, // 隐藏进度条
                },
              },
            },
            data: [
              {
                value: 20,
                //   name: 'Pressure', // 添加数据项的名称
                itemStyle: {
                  color: getColor(20), // 设置数据项颜色
                },
                label: {
                  show: false, // 将label的show属性设置为false，隐藏数值
                  formatter: null,
                  fontSize: 8, // 设置数据项文字大小
                },
              },
            ],
            axisPointer: {
              show: true,
              type: "line",
              lineStyle: {
                color: "#999",
                width: 2,
                type: "solid",
              },
            },
            pointer: {
              show: true,
              length: "70%", // 调整指针长度
            },
            axisLabel: {
              fontSize: '0.8rem' // 设置刻度标签的字体大小，可以根据需要调整
            }
          },
        ],
      };

      // 将配置设置到图表实例
      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },

    box2() {
      var chartDom = document.getElementById("box2");
      var myChart = echarts.init(chartDom);
      var option;

      var hours = this.xz03;
      // for (var i = 0; i < 24; i++) {
      //   hours.push(i + ":00");
      // }

      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },

        title: {
          text: "能耗变化趋势",
        },
        xAxis: {
          type: "category",
          data: hours,
          axisLabel: {
            interval: 3,
            rotate: 45,
          },
        },
        yAxis: {
          type: "value",
          name: "单位: kwh",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#999",
              width: 2,
              type: "solid",
            },
          },
          title: {
            text: "单位: kgce",
            textStyle: {
              fontSize: 12,
              color: "#333",
            },
            padding: 0,
            rotate: 0,
            textAlign: "left",
            margin: 0,
          },
        },
        legend: {
          data: [this.riqi01, this.riqi02],
          bottom: -5,

          left: "center",
        },
        toolbox: {
          feature: {
            magicType: {
              type: ["line", "bar"],
            },
          },
        },
        series: [
          {
            name: this.riqi02,
            data: this.gz02,
            type: "line",
            showSymbol: false,
            smooth: true,
          },
          {
            name: this.riqi01,
            data: this.gz01,
            type: "line",
            showSymbol: false,
            smooth: true,
          },
        ],
      };

      option && myChart.setOption(option);

      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    box3() {
      var chartDom = document.getElementById("box3");
      var myChart = echarts.init(chartDom);
      var option;

      const gaugeData = [
        {
          value: 20,
          name: "Perfect",
          title: {
            offsetCenter: ["0%", "-60%"],
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ["0%", "-40%"],
          },
        },
        {
          value: 40,
          name: "Good",
          title: {
            offsetCenter: ["0%", "-10%"],
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ["0%", "10%"],
          },
        },
      ];

      option = {
        series: [
          {
            type: "gauge",
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 1,
                borderColor: "#464646",
              },
            },
            axisLine: {
              lineStyle: {
                width: 20,
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              distance: 50,
            },
            data: gaugeData,
            title: {
              fontSize: '0.8rem',
            },
            detail: {
              width: 50,
              height: 12,
              fontSize: '0.8rem',
              color: "inherit",
              borderColor: "inherit",
              borderRadius: 20,
              borderWidth: calculateBorderWidth(), // 初始值通过函数计算
              formatter: "{value}%",
            },
          },
        ],
      };

      // 根据窗口大小计算 borderWidth 的函数
      function calculateBorderWidth() {
        // 根据屏幕宽度等比缩放 borderWidth
        return Math.max(0.5, window.innerWidth / 1000 * 0.2);
      }

      // 监听窗口大小变化事件
      window.addEventListener("resize", function () {
        // 更新 borderWidth
        option.series[0].detail.borderWidth = calculateBorderWidth();
        // 重新设置图表配置
        myChart.setOption(option);
        // 调整图表大小
        myChart.resize();
      });

      // 每隔一段时间更新数据
      setInterval(function () {
        gaugeData[0].value = +(Math.random() * 100).toFixed(2);
        gaugeData[1].value = +(Math.random() * 100).toFixed(2);
        myChart.setOption({
          series: [
            {
              data: gaugeData,
              pointer: {
                show: false,
              },
            },
          ],
        });
      }, 50000);

      // 初始化图表配置
      option && myChart.setOption(option);
      // 监听窗口大小变化，调整图表大小
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    }
    ,

    box4() {
      var chartDom = document.getElementById("box4");
      var myChart = echarts.init(chartDom);
      var option;

      const data = [
        {
          name: "电",
          value: 5501,
        },
        {
          name: "柴油",
          value: 0,
        },
        {
          name: "汽油",
          value: 0,
        },
        {
          name: "天然气",
          value: 0,
        },
      ];

      option = {
        series: [
          {
            type: "pie",
            radius: "50%", // 调整半径值
            center: ["50%", "50%"],
            data: data,
            label: {
              position: "outer",
              alignTo: "none",
              bleedMargin: 5,
              formatter: "{b}: {c} ({d}%)", // 显示标签的格式
            },
          },
        ],
      };

      // 调整图表容器的宽度和高度
      // chartDom.style.width = '25rem'; // 适应你的需要
      // chartDom.style.height = '25rem'; // 适应你的需要

      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    box5() {
      var chartDom = document.getElementById("box5");
      var myChart = echarts.init(chartDom);
      var option;

      var hours = this.yc02;
      // for (var i = 0; i < 24; i++) {
      //   hours.push(i + ":00");
      // }

      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        title: {
          text: "单位面积能耗",
        },
        xAxis: {
          type: "category",
          data: hours,
          axisLabel: {
            interval: 3,
            rotate: 45,
          },
        },
        yAxis: [
          {
            type: "value",
            name: "单位: kgce",
            axisLine: {
              show: true,
              lineStyle: {
                color: "#999",
                width: 2,
                type: "solid",
              },
            },
            title: {
              text: "kgce",
              textStyle: {
                fontSize: 12,
                color: "#333",
              },
              padding: 0,
              rotate: 0,
              textAlign: "left",
              margin: 0,
            },
            min: 0,
            max: 1000,
          },
          {
            type: "value",
            name: "m²",
            axisLine: {
              show: true,
              lineStyle: {
                color: "#999",
                width: 2,
                type: "solid",
              },
            },
            title: {
              text: "m²",
              textStyle: {
                fontSize: 12,
                color: "#333",
              },
            },
            position: "right",
            min: 0,
            max: 1000,
          },
        ],
        legend: {
          data: ["面积", "单位面积能耗", "约束性指标", "引导性指标"],
          bottom: -5,
          left: "center",
        },
        toolbox: {
          feature: {
            magicType: {
              type: ["line", "bar"],
            },
          },
        },
        series: [
          {
            name: "面积",
            data: this.yc03,
            type: "line",
            showSymbol: false,
            smooth: true,
            yAxisIndex: 1, // 指定右侧y轴的索引
          },
          {
            name: "单位面积能耗",
            data: this.yc04,
            type: "line",
            showSymbol: false,
            smooth: true,
            yAxisIndex: 1, // 指定右侧y轴的索引
          },
        ],
      };

      option && myChart.setOption(option);

      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    box6() {
      var chartDom = document.getElementById("box6");
      var myChart = echarts.init(chartDom);
      var option;

      var hours = this.yg02;
      // for (var i = 0; i < 24; i++) {
      //   hours.push(i + ":00");
      // }

      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        title: {
          text: "人均能耗",
        },
        xAxis: {
          type: "category",
          data: hours,
          axisLabel: {
            interval: 3,
            rotate: 45,
          },
        },
        yAxis: [
          {
            type: "value",
            name: "单位: kgce",
            axisLine: {
              show: true,
              lineStyle: {
                color: "#999",
                width: 2,
                type: "solid",
              },
            },
            title: {
              text: "kgce",
              textStyle: {
                fontSize: 12,
                color: "#333",
              },
              padding: 0,
              rotate: 0,
              textAlign: "left",
              margin: 0,
            },
          },
          {
            type: "value",
            name: "人",
            axisLine: {
              show: true,
              lineStyle: {
                color: "#999",
                width: 2,
                type: "solid",
              },
            },
            title: {
              text: "人",
              textStyle: {
                fontSize: 12,
                color: "#333",
              },
            },
            position: "right",
            min: 0,
            max: 2000,
          },
          {
            type: "value",
            name: "面积",
            axisLine: {
              show: true,
              lineStyle: {
                color: "#999",
                width: 2,
                type: "solid",
              },
            },
            title: {
              text: "面积",
              textStyle: {
                fontSize: 12,
                color: "#333",
              },
            },
            position: "right",
            offset: 60, // Adjust the offset as needed
            min: 0,
            max: 100, // Adjust the max value as needed
          },
        ],
        legend: {
          data: ["人数", "人均能耗", "约束性指标", "引导性指标"],
          bottom: -5,

          left: "center",
        },
        toolbox: {
          feature: {
            magicType: {
              type: ["line", "bar"],
            },
          },
        },
        series: [
          // {
          //   name: "人数",
          //   data: [
          //     2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000,
          //     2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000,
          //     2000, 2000,
          //   ],
          //   type: "line",
          //   showSymbol: false,
          //   yAxisIndex: 1,
          //   smooth: true,
          // },
          {
            name: "人均能耗",
            data: this.yg03,
            type: "line",
            showSymbol: false,
            smooth: true,
          },
          // {
          //   name: "约束性指标",
          //   data: [
          //     0.091, 0.091, 0.091, 0.091, 0.091, 0.091, 0.091, 0.091, 0.091,
          //     0.091, 0.091, 0.091, 0.091, 0.091, 0.091, 0.091, 0.091, 0.091,
          //     0.091, 0.091, 0.091, 0.091, 0.091, 0.091,
          //   ],
          //   type: "line",
          //   showSymbol: false,
          //   smooth: true,
          // },
          // {
          //   name: "引导性指标",
          //   data: [
          //     0.073, 0.073, 0.073, 0.073, 0.073, 0.073, 0.073, 0.073, 0.073,
          //     0.073, 0.073, 0.073, 0.073, 0.073, 0.073, 0.073, 0.073, 0.073,
          //     0.073, 0.073, 0.073, 0.073, 0.073, 0.073,
          //   ],
          //   type: "line",
          //   showSymbol: false,
          //   smooth: true,
          // },
        ],
      };

      option && myChart.setOption(option);

      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },

  mounted() {
    this.cgx();
    // this.cgx02();
    this.getArea();
    this.filteredTreeData = this.data;
    this.box1();
    this.box2();
    this.box3();
    this.box4();
    this.box5();
    this.box6();
    setTimeout(() => {
      this.box2();
    }, 5000);
  },
};
</script>

<style scoped>
.container {
  width: 100rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.tab {
  width: 70vw;
  padding: 0.625rem;
  box-sizing: border-box;
}

.btn {
  width: 30rem;
  padding: 0.625rem;
  box-sizing: border-box;
}

.box {
  width: 100vw;
  height: 94vh;
  display: flex;
}

.title {
  width: 100%;
  box-sizing: border-box;
}

.select {
  width: 12vw;
  height: 80vh;
}

.right {
  width: 98% !important;
  display: flex;
  flex-direction: column;
}

.data {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.date {
  display: flex;
  align-items: center;
  margin-left: 0.625rem;
}

.image {
  width: 98% !important;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.center,
.center-bottom {
  width: 90%;
  height: 40vh;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.125rem;
}

.num {
  transform: translateY(-1rem);
}

.num,
.pie {
  flex: 1;
  height: 100%;
  /* border:0.125rem solid #ccc;
    border-radius: 4px; */
  margin: 0.5rem;
  margin-bottom: 0.6rem;
  width: 100%;
}

.box2,
.box4,
.box5,
.box6 {
  flex: 1;
  height: 30vh;
  /* border:0.125rem solid #ccc; */
  /* border-radius:0.2rem; */
  margin: 0.5rem;
  margin-bottom: 1.25rem;
  width: 100%;
}

.box4,
.box5,
.box6 {
  margin-top: 1.25rem;
}

.pie {
  display: flex;
  flex-direction: row;
}

.box3 {
  /* Adjust the width based on your design */
  width: 70%;
  height: 30vh;
}

.month {
  width: 30%;
  margin-top: 2.5rem;
  margin-left: 1.25rem;
}

.yesterday {
  margin-top: 1rem;
}

.theday {
  margin-top: 1rem;
}

.value1 {
  margin-top: 0.125rem;
  color: orange;
}

.value2 {
  margin-top: 0.125rem;
  color: greenyellow;
}

.value3 {
  margin-top: 0.125rem;
  color: blue;
}

.next {
  width: 20vw;
  padding: 0rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 0.625rem;
  /* 添加一些间距 */
}

.building {
  margin-left: 23rem;
}

.area {
  margin-right: 1.25rem;
}

.type {
  margin-right: 1.25rem;
}

.certain,
.fact,
.remain {
  width: 5vw;
  height: 6vh;
  margin-right: 1.25rem;
  /* margin-bottom:0.3rem;  */
}

.certain {
  margin-left: 5rem;
}

.value {
  color: orange;
}

.text {
  margin-top: 1.25rem;

  margin-left: 0.625rem;
  color: #636363;
  font-size: 0.8rem;
}

.box1 {
  left: -1rem;
  top: -1rem;
  width: 24rem;
  height: 12.5rem;
  user-select: none;

  padding: 0rem;
  margin: 0rem;
  border-width: 0rem;
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 12rem;
}

::v-deep .el-date-editor .el-range-separator {
  height: 100%;
  padding: 0 0.3rem;
  margin: 0;
  text-align: center;
  line-height: 2rem;
  font-size: 0.8rem;
  width: 5%;
  color: #303133;
}

::v-deep .el-date-editor .el-range__icon {
  line-height: 2rem;
}

::v-deep .el-date-editor .el-range-input {
  font-size: 0.8rem;
}

::v-deep .el-range-editor.el-input__inner {
  width: 16rem !important;
  border-radius: 0.25rem;
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.9rem !important;
  font-size: 0.8rem;
}

.el-button {
  width: 4rem;
  height: 2rem;
  font-size: 0.8rem !important;
  padding: 0;
}

::v-deep .el-radio-button__inner {
  padding: 0.5rem 1rem !important;
  font-size: 0.8rem !important;
}

::v-deep .el-input--suffix .el-input__inner {
  padding-left: 1.8rem !important;
  padding-right: 1.8rem !important;
}

::v-deep .el-input__inner {
  width: 12rem;
  border-radius: 0.25rem;
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.9rem;
  font-size: 0.8rem;
}

::v-deep .el-input {
  font-size: 0.8rem;
}

::v-deep .el-input__icon {
  width: 1.5rem;
  font-size: 0.8rem !important;
  line-height: 2rem;
}

.el-date-editor .el-range__icon {
  font-size: 0.8rem !important;
  line-height: 2rem;
}

::v-deep .el-date-editor .el-input,
.el-date-editor .el-input__inner {
  width: 12rem;
}

::v-deep .el-date-editor .el-range-input {
  font-size: 0.6rem;
}

::v-deep .el-date-editor .el-range__icon {
  font-size: 0.8rem;
  line-height: 1.9rem;
}

span {
  font-size: 0.8rem !important;
}

/* ::v-deep .element.style {
 width: 29rem;
    height: 14.5rem;
   
} */
</style>
