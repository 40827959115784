<template>
    <div style="display: flex;width: 100rem">
      <Tree :name="'碳排查询'" :checked=false></Tree>
      <div class="right">
        <el-table :data="tableData" border style="width: 80vw;">
          <el-table-column prop="type" label="分区" width="180">
          </el-table-column>
          <el-table-column prop="time" label="时间" width="180">
          </el-table-column>
          <el-table-column prop="areaName" label="名称"> </el-table-column>
          <el-table-column prop="value" label="排放量(吨)"> </el-table-column
          ><el-table-column prop="value" label="二氧化碳(吨)"> </el-table-column>
        </el-table>
      </div>
    </div>
  </template>
  
  <script>

  import Tree from '@/components/tree/leftTree.vue'
   import request from '@/request/request';
  export default {
    components:{
      Tree
    },
    data() {
      return {
        isExpanded: false,
        tableData: [],
        tags: [
          { name: "标签一", type: "" },
          { name: "标签二", type: "" },
          { name: "标签三", type: "" },
          { name: "标签四", type: "" },
          { name: "标签五", type: "" },
        ],
        searchKeyword: "",
        filteredTreeData: [],
        data:[],
        defaultProps: {
          children: "children",
          label: "label",
        },
      };
    },
    methods: {
      toggleSidebar() {
      this.isExpanded = !this.isExpanded;
    },
      closeTag(tag) {
        this.tags = this.tags.filter((t) => t !== tag);
      },
      handleDropdownCommand(command) {
        if (command === "closeAll") {
          this.tags = [];
        } else if (command === "closeOther") {
          this.tags = [this.tags[0]];
        }
      },
      querySearch(queryString, cb) {
        if (queryString) {
          const filteredTreeData = this.generateFilteredTreeData(queryString);
          this.filteredTreeData = filteredTreeData;
          cb(filteredTreeData);
        } else {
          this.filteredTreeData = this.data;
          cb(this.data);
        }
      },
      handleSelect(item) {
        console.log(item);
      },
      handleNodeClick(data) {
        console.log(data);
      },
      generateFilteredTreeData(queryString) {
        return this.data
          .filter((branch) =>
            branch.children.some((leaf) =>
              leaf.label.toLowerCase().includes(queryString.toLowerCase())
            )
          )
          .map((branch) => ({
            ...branch,
            children: branch.children.filter((leaf) =>
              leaf.label.toLowerCase().includes(queryString.toLowerCase())
            ),
          }));
      },
      transformData(data, pid = 0) {
        if (!data) {
          return []; // 如果data为null或undefined，则返回一个空数组
        }
        const result = [];
        for (const node of data) {
          if (node.pid === pid) {
            const newNode = {
              id: node.id,
              label: node.name,
              children: this.transformData(data, node.id),
            };
            result.push(newNode);
          }
        }
        return result;
      },
      // transformData(data, pid = 0) {
      //   if (!data) {
      //     return []; // 如果data为null或undefined，则返回一个空数组
      //   }
      //   const result = [];
      //   for (const node of data) {
      //     // console.log(node);
      //     if (node.pid === pid) {
      //       const newNode = {
      //         id: node.id,
      //         label: node.name,
      //         children: this.transformData(data, node.id),
      //       };
      //       result.push(newNode);
      //     }
      //   }
      //   return result;
      // },
      async getListType(){
        const res = await request('/carbon/listType')
        this.filteredTreeData=  this.transformData(res.data.content);
      },
      async getData(){
        const res = await request({
         url: '/carbon/getCarbonData',
         method:'get',
         params:{
          areaId : 1,
          carbonTypeId:1,
          page:1,
          size:2
         }
        })
        this.tableData = res.data.content.list
        console.log(res.data.content);
      }
    },
    mounted() {
      this.getListType()
      this.getData()
      // console.log(document.querySelectorAll('tr'));
      document.querySelectorAll('tr').forEach(x=>{
          x.style.backgroundColor = '#333'
      })
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .left {
    width: 12vw;
    display: flex;
    flex-direction: column;
    background-color: #cfd3dc;
    height: 130vh;
    //overflow-y: auto;
  }
  
  .title {
    width: 12vw;
    height: 20vh;
  }
  
  .select {
    margin-top: 2vh;
    width: 12vw;
    height: 80vh;
  }
  .right {
    flex: 1;
     width: 50%;
    height: 80vh;
    // background-color: #cfd3dc;
    margin: 2vw;
  }
  ::-webkit-scrollbar {
    width: 12px; /* 设置滚动条宽度 */
    display: none;
  }
  
  .sidebar {
  width: 2%;
  height: 100vh;
  background-color: #cfd3dc;
  transition: width 0.3s ease;
}

.sidebar-expanded {
  width: 12%;
}

.toggle-button {
  position: fixed;
  top: 50%;
  transform: translateY(0);
  left: 10%;
  width: 1.8vw;
  height: 2vw;
  background-color: #ccc;
  border: none;
  cursor: pointer;
  transition: transform 0.08s ease;
}
.el-tree {
  background-color: #cfd3dc;
}

.button-expanded {
  transform: translateX(550%);
}
.el-table{
    font-size: 0.8rem;
}
::v-deep .el-table .el-table__cell{
    padding: 0.8rem 0;
}
  </style>