<template>
  <div class="neng" style="display: flex; width: 100vw">
    <Tree @transmit="getMessage" :name="transformerName" :checked="false" :data="data" @dataId="getId"></Tree>
    <!-- <div class="sidebar" :class="{ 'sidebar-expanded': isExpanded }">
  
      <div class="top01" v-show="isExpanded == true">
        <p>三项不平衡</p>
        <el-input placeholder="输入关键字进行过滤" v-model="filterText">
        </el-input>

        <el-tree
          class="filter-tree"
          :data="data"
          :props="defaultProps"
          default-expand-all
          :filter-node-method="filterNode"
          ref="tree"
        >
        </el-tree>
      </div>
    </div> -->

    <div class="re">
      <div class="right" :style="{ width: isExpanded ? '77vw' : '87vw' }">
        <div class="topp">
          <div class="data">
            <el-radio-group v-model="radio3" size="small" style="margin-left: 1.5vw; margin-right: 1.5vw">
              <el-radio-button label="日"></el-radio-button>
              <el-radio-button label="月"></el-radio-button>
              <!-- <el-radio-button label="年"></el-radio-button> -->
              <el-radio-button label="自定义"></el-radio-button>
            </el-radio-group>
            <el-date-picker v-if="radio3 === '日'" v-model="value1" type="date" placeholder="选择日">
            </el-date-picker>
            <el-date-picker v-if="radio3 === '月'" v-model="value2" type="month" placeholder="选择月">
            </el-date-picker>

            <el-date-picker v-if="radio3 === '自定义'" v-model="value3" type="daterange" range-separator="-"
              start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
            <el-form :inline="true" id="cgx" :model="ruleForm" ref="ruleForm" class="demo-ruleForm">
              <el-form-item label="查询类型">
                <el-select v-model="ruleForm.region" placeholder="电压不平衡度">
                  <el-option label="电压不平衡度" value="voltage"></el-option>
                  <el-option label="电流不平衡度" value="current"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="数据密度" v-if="radio3 == '日'">
                <el-select v-model="ruleForm.region02" placeholder="5分钟">
                  <el-option label="5分钟" value="5"></el-option>
                  <el-option label="10分钟" value="10"></el-option>
                  <el-option label="30分钟" value="30"></el-option>
                  <el-option label="1小时" value="60"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="数据密度" v-else>
                <el-select v-model="ruleForm.region02" placeholder="1天">
                  <el-option label="1天" value="1440"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <el-button size="small" type="primary" style="margin-left: 1vw" @click="onSearch">查询</el-button>
            <!-- <el-button type="success" size="small">导出</el-button> -->
            <el-dropdown @command="handleOption">
              <el-button type="success" size="small" style="margin-left: 1rem;">
                导出
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="pdf">导出为 Text</el-dropdown-item>
                <el-dropdown-item command="excel">导出为 Excel</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="topp02">
          <div class="topp03">
            <img src="../../assets/xia01.png" alt="" />
            <div>
              <span v-if="totalData && totalData.length > 0" class="zd">{{
      totalData[0].name
    }}</span><br />
              <span class="vv" v-if="totalData && totalData.length > 0">
                <span>{{ parseFloat(totalData[0].value).toFixed(2) }}</span><span>{{ totalData[0].unit }}</span>
              </span>
            </div>
          </div>
          <div class="topp03">
            <img src="../../assets/xia02.png" alt="" />
            <div>
              <span v-if="totalData && totalData.length > 0" class="zd">{{
      totalData[1].name
    }}</span><br />
              <span class="vv" v-if="totalData && totalData.length > 0">
                <span>{{ parseFloat(totalData[1].value).toFixed(2) }}</span><span>{{ totalData[1].unit }}</span>
              </span>
            </div>
          </div>
          <div class="topp03">
            <img src="../../assets/xia03.png" alt="" />
            <div>
              <span v-if="totalData && totalData.length > 0" class="zd">{{
      totalData[2].name
    }}</span><br />
              <span class="vv" v-if="totalData && totalData.length > 0">
                <span>{{ parseFloat(totalData[2].value).toFixed(2) }}</span><span>{{ totalData[2].unit }}</span>
              </span>
            </div>
          </div>
          <!-- <div class="topp03">
            <img src="../../assets/xia04.png" alt="" />
            <div>
              <span class="zd">电压不平衡度</span><br />
              <span class="vv">
                <span>6.8%</span><span>(0.406<span>kV</span>)</span></span
              >
            </div>
          </div> -->
        </div>
        <div class="bottom">
          <el-tabs v-model="activeName" @tab-click="handleClick" class="elta">
            <el-tab-pane label="表格" name="first">
              <el-table :header-cell-style="{ background: '#ebeef5' }" :stripe="true" :fit="true" :data="tableData"
                height="400" border style="width: 100%">
                <el-table-column prop="index" label="#">
                  <template slot-scope="scope">
                    {{ scope.$index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column prop="time" label="时间"> </el-table-column>
                <el-table-column prop="a" label="AB线电压不平衡度">
                </el-table-column>
                <el-table-column prop="b" label="BC线电压不平衡度">
                </el-table-column>
                <el-table-column prop="c" label="CA线电压不平衡度">
                </el-table-column>
                <!-- <el-table-column prop="dian05" label="电压不平衡度">
                </el-table-column> -->
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="图表" name="second">
              <div id="main"></div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <!-- <button
      class="toggle-button"
      @click="toggleSidebar"
      :class="{ 'button-expanded': isExpanded }"
    >
     
      <i :class="isExpanded ? 'el-icon-s-fold' : 'el-icon-s-unfold'"></i>
    </button> -->
  </div>
</template>

<script>
import * as echarts from "echarts";
import Tree from "../../components/tree/leftTree.vue";
import request from "@/request/request";
import formatDates from "@/utils/index.js";
import { min } from 'date-fns';
export default {
  components: {
    Tree,
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  data() {
    return {
      showOptions: false,
      transformerName: "三项不平衡",
      ruleForm: {
        region: "voltage",
        region02: "5",
      },
      radio3: "日",
      value1: "2024-04-01",
      value2: "",
      value3: "",
      tableData: [],
      activeName: "second",
      totalData: [],
      isExpanded: true,
      filterText: "",
      data: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      eqId: 8,
      type: 1,
      dates: [],
      rawData: [],
      xAxis: [],
      maxValue: '',
      minValue: ''
    };
  },
  methods: {
    handleOption(command) {
      if (command === 'pdf') {
        this.exportAsPDF();
      } else if (command === 'excel') {
        this.exportAsExcel();
      }
    },
    exportAsPDF() {
      // 实现导出为PDF的逻辑
      console.log("导出为 PDF");
    },
    exportAsExcel() {
      // 实现导出为Excel的逻辑
      console.log("导出为 Excel");
    },
    onSearch() {
      if (this.radio3 == "日") {
        this.type = 1;
        if (this.value1 != "2024-04-01") {
          this.value1 = new Date(this.value1);
        }
        this.getThree(this.value1);
        this.getData(this.value1);
        this.value2 = "";
        this.value3 = "";
      } else if (this.radio3 == "月") {
        this.type = 2;
        this.ruleForm.region02 = 1440;
        this.value1 = "";
        this.value3 = "";
        if (this.value2 != "") {
          // this.value2 = new Date(this.value2);
          const value2 = []
          value2.push(this.value2)
          console.log(formatDates(value2), 666666666666666666);
          this.getThree(formatDates(value2)[0]);
          this.getData(formatDates(value2)[0]);
        }
      } else if (this.radio3 == "自定义") {
        this.ruleForm.region02 = 1440;
        this.type = 1;
        this.value1 = "";
        this.value2 = "";
        if (this.value3 != "") {
          console.log(this.value3, 333333333333333);
          this.value3 = formatDates(this.value3);
          //  console.log(this.value3);
          this.getThree(this.value3.join(";"));
          this.getData(this.value3.join(";"));
        }
      }
    },
    async getThree(date) {
      const res = await request({
        url: "/diagnosisDiagnosis/ThreeTermUnbalanceTotal",
        params: {
          date: date,
          type: this.type,
          eqId: this.eqId,
          interval: +this.ruleForm.region02,
          queryType: this.ruleForm.region,
        },
      });
      this.totalData = res.data.content;
      // console.log(this.totalData[0].name);
    },
    async getId(data) {
      console.log(data);
      this.eqId = data;
      this.getThree("2024-04-01");
    },
    transformData(data, pid = 0) {
      if (!data) {
        return []; // 如果data为null或undefined，则返回一个空数组
      }
      const result = [];
      for (const node of data) {
        if (node.pid === pid) {
          const newNode = {
            id: node.stationId,
            label: node.name,
            children: this.transformData(data, node.id),
          };
          result.push(newNode);
        }
      }
      return result;
    },
    async getShebei() {
      const res = await request({
        url: "/dataAnalysis/listEquipment",
        method: "get",
        params: {},
      });
      // = res.data.content;
      // console.log(res.data.content, "98989898998989");
      // this.gz = res.data.content;
      // console.log(this.gz, "gzgzgzgzgzgz");
      // for (const item of this.gz) {
      //   if (item.type === 1) {
      //     this.typeOneIds.push(item.id);
      //   }
      //   // console.log(this.typeOneIds, "qaaaaaaaaaaaaaaaaaaaaaaaa");
      // }
      console.log(res.data.content);
      this.data = this.transformData(res.data.content);
      console.log(this.data);
    },
    async getData(date) {
      const res = await request({
        url: "/diagnosisDiagnosis/ThreeTermUnbalance",
        params: {
          date: date,
          type: this.type,
          eqId: this.eqId,
          interval: +this.ruleForm.region02,
          queryType: this.ruleForm.region,
        },
      });
      this.dates = res.data.content.map((x) =>
        x.data.map((entry) => Object.keys(entry)[0])
      );
      if (+this.ruleForm.region02 < 1440) {
        this.xAxis = this.dates[0].map((x) => x.substring(11, 16));
      } else {
        this.xAxis = this.dates[0].map((x) => x.substring(0, 10));
      }
      console.log(this.dates[0]);
      this.rawData = res.data.content.map((x) =>
        x.data.map((entry) => {
          const value = entry[Object.keys(entry)[0]];
          // 将 "NaN" 替换为合适的数值，这里假设替换为 0
          return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
        })
      );
      this.maxValue = this.rawData[0][0];
      this.minValue = this.rawData[0][0];

      // 遍历每个数组
      for (let i = 0; i < this.rawData.length; i++) {
        const currentArray = this.rawData[i];
        // 遍历当前数组的每个元素
        for (let j = 0; j < currentArray.length; j++) {
          const currentValue = currentArray[j];
          // 比较当前值与最大值和最小值
          if (currentValue > this.maxValue) {
            this.maxValue = currentValue;
          }
          if (currentValue < this.minValue) {
            this.minValue = currentValue;
          }
        }
      }
      this.tu02();
      this.tableData = this.dates[0].map((time, index) => ({
        time,
        a: this.rawData[0][index],
        b: this.rawData[1][index],
        c: this.rawData[2][index],
        // 设置默认的名称
      }));

      console.log(this.tableData, 77777777777777);
    },

    getMessage(data) {
      this.isExpanded = data;
    },
    tu02() {
      var chartDom = document.getElementById("main");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [
            "AB线电压不平衡度",
            "BC线电压不平衡度",
            "CA线电压不平衡度",
            "电压不平衡度",
          ],
        },
        toolbox: {
          show: true,
          feature: {
            dataView: { show: false, readOnly: false },
            magicType: { show: true, type: ["line", "bar"] },
            restore: { show: false },
            saveAsImage: { show: false },
          },
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            data: this.xAxis,
          },
        ],
        yAxis: [
          {
            type: "value",
            min: this.minValue.toFixed(2),
            max: this.maxValue.toFixed(2), // 设置y轴范围为0到15
          },
        ],
        series: [
          {
            name: "AB线电压不平衡度",
            type: "bar",
            data: this.rawData[0],

            markLine: {
              data: [
                { yAxis: 7, name: "Average", lineStyle: { color: "red" } },
              ],
            },
          },
          {
            name: "BC线电压不平衡度",
            type: "bar",
            data: this.rawData[1],
            markLine: {
              data: [
                { yAxis: 0, name: "Average", lineStyle: { color: "red" } },
              ],
            },
          },
          {
            name: "CA线电压不平衡度",
            type: "bar",
            data: this.rawData[2],
            markLine: {
              data: [
                { yAxis: -7, name: "Average", lineStyle: { color: "red" } },
              ],
            },
          },
          // {
          //   name: "电压不平衡度",
          //   type: "bar",
          //   data: [
          //     2.6, 5.9, 9.0, 6.4, 8.7, 7.7, 5.6, 2.2, 8.7, 12.8, 6.0, 2.3, 2.0,
          //     4.9, 7.0, 3.2, 5.6, 6.7, 15.0, 6.2, 2.6, 12.0, 6.4, 3.3,
          //   ],
          // },
        ],
      };

      option && myChart.setOption(option);
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    format(percentage) {
      return percentage === 100 ? "满" : `${percentage}%`;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    toggleSidebar() {
      this.isExpanded = !this.isExpanded;
    },
  },
  mounted() {
    this.getShebei();
    this.getThree("2024-04-01");
    this.getData("2024-04-01");
    // this.tu02();
    // 提取日期和值为两个数组
    // const dates = rawData.map((entry) => Object.keys(entry)[0]);
    // const values = rawData.map((entry) => {
    //   const value = entry[Object.keys(entry)[0]];
    //   // 将 "NaN" 替换为合适的数值，这里假设替换为 0
    //   return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
    // });

    // console.log("Dates:", dates.map(x=>x.substring(11, 16)));
    // console.log("Values:", values);
  },
};
</script>

<style lang="scss" scoped>
.zd {
  margin-top: 3px;
}

#cgx {
  margin-top: 0.5rem;
  margin-left: 1%;
}

.data {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.topp {
  width: 99%;
  vertical-align: middle;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 1.5%;
  border-radius: 3px;
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.3);
  height: 10vh;
  background-color: #fff;
}

.vv {
  color: #07ccfa;
  font-size: 1.4vw;
  font-weight: 800;
}

.topp02 {
  vertical-align: middle;
  width: 99%;
}

.topp03 {
  border-radius: 2px;
  margin-left: 0.5%;
  position: relative;
  display: inline-block;
  width: 24.5%;
  background-color: #fff;
  height: 10vh;
  margin-top: 1%;

  img {
    margin-left: 5%;
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  div {
    position: absolute;
    top: 20px;
    display: inline-block;
  }
}

#main {
  width: 100%;
  height: 50vh;
}

.elta {
  margin-left: 1%;
  margin-right: 1%;
}

.bottom {
  height: 70vh;
  width: 99%;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 1.5%;
  border-radius: 3px;
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.3);
}

.max02 {
  color: red;
}

.min02 {
  color: #658e63;
}

.bdy01 {
  width: 100%;
  margin: 3vw auto;

  margin-left: 3vw;
}

.kw {
  font-size: 0.8vw;
  font-weight: 400;
}

.fzl {
  font-size: 1vw;
}

.fz {
  color: #02ae4e;
}

.r05 {
  color: #02ae4e;
  font-size: 1.2vw;
  font-weight: 600;
}

::v-deep .el-progress-bar__outer {
  height: 20px !important;
}

.el-progress {
  width: 65%;
  display: inline-block;
}

.shiyong {
  color: #67c23a;
}

.re {
  width: 100%;

  background-color: #f1f4f5;
}

.neng {
  display: flex;
}

.right {
  background-color: #f1f4f5;
  width: 100%;
}

.top01 {
  p {
    display: inline-block;
    margin-top: 5%;
    margin-left: 2%;
  }
}

.sidebar {
  width: 2%;
  height: 100vh;
  background-color: #cfd3dc;
  transition: width 0.3s ease;
}

.sidebar-expanded {
  width: 12%;
}

.toggle-button {
  position: fixed;
  top: 50%;
  transform: translateY(0);
  left: 10%;
  width: 1.8vw;
  height: 2vw;
  background-color: #ccc;
  border: none;
  cursor: pointer;
  transition: transform 0.08s ease;
}

.el-tree {
  background-color: #cfd3dc;
}

.button-expanded {
  transform: translateX(495%);
}

::v-deep .el-input--suffix .el-input__inner {
  padding-left: 1.8rem !important;
  padding-right: 1.8rem !important;
}

::v-deep .el-input__inner {
  width: 10rem;
  border-radius: 0.25rem;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 0.9rem;
  font-size: 0.8rem;
}

::v-deep .el-input {
  font-size: 0.8rem;
}

::v-deep .el-input__icon {
  width: 1.5rem;
  font-size: 0.8rem !important;
  line-height: 2.5rem;
}

.el-date-editor .el-range__icon {
  font-size: 0.8rem !important;
  line-height: 2.5rem;
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 10rem;
}

::v-deep .el-date-editor .el-range-input {
  font-size: 0.6rem;
}

::v-deep .el-date-editor .el-range-separator {
  font-size: 0.8rem;
  line-height: 1.9rem;
}

::v-deep .el-input--suffix .el-input__inner {
  padding-left: 1.8rem !important;
  padding-right: 1.8rem !important;
}

::v-deep .el-input__inner {
  width: 10rem;
  border-radius: 0.25rem;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 0.9rem;
  font-size: 0.8rem;
}

::v-deep .el-input {
  font-size: 0.8rem;
}

::v-deep .el-input__icon {
  width: 1.5rem;
  font-size: 0.8rem !important;
  line-height: 2.5rem;
}

.el-date-editor .el-range__icon {
  font-size: 0.8rem !important;
  line-height: 2.5rem;
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 10rem;
}

::v-deep .el-date-editor .el-range-input {
  font-size: 0.6rem;
}

::v-deep .el-date-editor .el-range__icon {
  font-size: 0.8rem;
  line-height: 1.9rem;
}

::v-deep .el-table {
  font-size: 0.8rem !important;
}

::v-deep .el-tree-node__label {
  font-size: 0.7rem;
}

::v-deep .el-input__inner {
  font-size: 0.8rem;
}

.el-button--primary {
  width: 3.5rem;
  height: 1.6rem;
  padding: 0.1rem;
  font-size: 0.8rem;
}

.el-button--small {
  width: 3.5rem;
  height: 1.6rem;
  padding: 0.1rem;
  font-size: 0.8rem;
}

::v-deep .el-radio-button--small .el-radio-button__inner {
  padding: 0.5rem;
  font-size: 0.8rem;
  border-radius: 0;
}

::v-deep .el-form--inline .el-form-item {
  display: inline-block;
  margin-right: 0.5rem;
  vertical-align: top;
}

::v-deep .el-form-item__label {
  font-size: 0.8rem;
}

::v-deep .el-tabs__item {
  padding: 0 1.2rem;
  height: 2.2rem;
  box-sizing: border-box;
  line-height: 2.2rem;
  font-size: 0.7rem;
}

::v-deep .el-form-item {
  margin-bottom: 0 !important;
}
</style>