<template>
  <div>
    <div class="sidebar" :class="{ 'sidebar-expanded': isExpanded }">
      <!-- 侧边栏内容 -->
      <div class="top01" v-show="isExpanded == true">
        <p style="height: 2rem; font-size: 0.8rem; line-height: 2rem">
          {{ name }}
        </p>
        <el-input placeholder="输入关键字进行过滤" v-model="filterText">
        </el-input>
        <el-tree @check-change="handleCheckChange" @current-change="handleCurrentChange" @node-click="getCheckedNodes"
          current-node-key="string" node-key="id" class="filter-tree" :data="data" :props="defaultProps"
          :highlight-current="true" :default-checked-keys="checkedxuan" default-expand-all
          :filter-node-method="filterNode" ref="tree" :show-checkbox="checked">
        </el-tree>
      </div>
    </div>
    <button class="toggle-button" @click="toggleSidebar" :class="{ 'button-expanded': isExpanded }">
      <!-- 切换按钮的图标 -->
      <i :class="isExpanded ? 'el-icon-s-fold' : 'el-icon-s-unfold'" style="font-size: 0.6rem"></i>
    </button>
  </div>
</template>

<script>
import { interval } from 'date-fns';

export default {
  props: {
    name: String,
    checked: Boolean,
    data: Array,
    checkedxuan: Array,
  },
  data() {
    return {
      cgx10: [],
      cgx666: [],
      selectedIds: [],
      filterText: "",
      isExpanded: true,
      count: [
        {
          label: "捷百安",
          children: [
            {
              label: "二级 1-1",
              children: [
                {
                  label: "三级 1-1-1",
                },
              ],
            },
          ],
        },
        {
          label: "一级 2",
          children: [
            {
              label: "二级 2-1",
              children: [
                {
                  label: "三级 2-1-1",
                },
              ],
            },
            {
              label: "二级 2-2",
              children: [
                {
                  label: "三级 2-2-1",
                },
              ],
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    handleCheckChange(data, checked, indeterminate) {
      if (checked) {
        this.cgx666.push(data)
        this.cgx10 = this.cgx666.map(x => x.id)
        this.cgx();
        console.log(this.cgx10);
      }
      // this.cgx666.push(data);
      // console.log(this.cgx666, "网规则昂贵在网规则");
      // for (var f = 0; f < this.cgx666.length; f++) {
      //   this.cgx10.push(this.cgx666[f].id);
      // }
      // console.log(this.cgx10, "opopopopopopopopop");
      // this.cgx10 = [...new Set(this.cgx10)];
      // console.log(this.cgx10, "opopopopopopopopop");
    },
    handleCurrentChange(nodeData) {
      // 当前选中节点变化时的处理方法
      this.currentNodeKey = nodeData.id;
    },
    handleNodeClick(nodeData) {
      // 节点点击事件处理方法
      this.selectedNodes.push(nodeData);
    },

    getCheckedNodes(data) {
      console.log(data, 6666666);
      this.$emit("dataId", data.id);
    },

    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    toggleSidebar() {
      this.isExpanded = !this.isExpanded;
      this.$emit("transmit", this.isExpanded);
    },
    cgx() {
      this.$emit("gz01", this.cgx10);
      // console.log(this.cgx10, "qqqqqqqqqqqqqqqqqqqqqqqqwwwwwwwwwwwwwwww");
    },
  },
  mounted() {
    // this.getCheckedNodes();
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  width: 2rem;
  height: 100vh;
  background-color: #cfd3dc;
  transition: width 0.3s ease;
}

.sidebar-expanded {
  width: 12rem !important;
}

.toggle-button {
  position: absolute;
  top: 50%;

  left: 0.2%;
  width: 1.8vw;
  height: 2vw;
  background-color: #cfd3dc;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.el-tree {
  background-color: #cfd3dc;
}

.button-expanded {
  transform: translateX(495%);
}

::v-deep .el-input__inner {
  height: 2rem !important;
  line-height: 2rem !important;
  font-size: 0.8rem !important;
  padding: 0 0.8rem !important;
  width: 12rem !important;
}

::v-deep .el-tree-node__label {
  font-size: 0.8rem;
}

::v-deep .el-tree-node__content {
  height: 1.8rem;
  // width: 2.5rem;
}

// ::v-deep .el-tree-node__content:hover, .el-upload-list__item:hover{
//   width: 2.5rem;
// }
// ::v-deep .el-tree-node:focus>.el-tree-node__content{
//   width: 2.5rem;
// }
::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 0.125rem;
  box-sizing: border-box;
  width: 0.7rem;
  height: 0.7rem;
}

.el-tree-node__expand-icon {
  font-size: 0.8rem;
}

::v-deep .is-current .el-tree-node__content {
  background-color: #ffffff !important;
}
</style>