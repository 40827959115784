<template>
  <el-card style="background-color: transparent; border: none">
    <div ref="chart" style="width: 110%; height: 200%"></div>
  </el-card>
</template>
<script>
import * as echarts from "echarts";
import request from "@/request/request";
export default {
  name: "TableThree",
  data() {
    return {
      todayValue:[],
      yesterdayValue:[]
    };
  },
  methods: {
    async getData() {
      const res = await request("/electric-data/getHomeData");
      this.yesterdayValue = res.data.content.yesterdayValue.map(Number)
      this.todayValue = res.data.content.todayValue.map(Number)
      this.initChart();
    },
    initChart() {
      const chartContainer = this.$refs.chart;

      // 使用 echarts.init 初始化图表
      const chart = echarts.init(chartContainer);
      let legendData = ["今日", "昨日"];
      let xAxisData = [
        "0时",
        "1时",
        "2时",
        "3时",
        "4时",
        "5时",
        "6时",
        "7时",
        "8时",
        "9时",
        "10时",
        "11时",
        "12时",
        "13时",
        "14时",
        "15时",
        "16时",
        "17时",
        "18时",
        "19时",
        "20时",
        "21时",
        "22时",
        "23时",
        "24时",
      ];
      let seriesData = this.todayValue
      let seriesData1 = this.yesterdayValue
      var option;
      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "30%",
          left: "2%",
          right: "10%",
          bottom: "5%",
          containLabel: true,
        },
        legend: {
          icon: "rect",
          right: "center",
          top: "2%",
          itemWidth: 7, // 设置宽度
          itemHeight: 7, // 设置高度
          itemGap: 15, // 设置间距
          textStyle: {
            //图例文字的样式
            color: "#89BFE5",
            fontSize: 12,
          },
        },
        xAxis: {
          boundaryGap: true,
          data: xAxisData,
          axisLine: {
            lineStyle: {
              color: "rgba(117, 168, 202, 0.4)",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: "#fff",
          },
        },
        yAxis: [
          {
            // name: '单位：个',
            // type: "value",
            inverse: false,
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(117, 168, 202, 0.3)",
                type: "dashed",
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#0A5C95",
              },
            },
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#89BFE5",
                fontSize: 12,
              },
            },
            nameTextStyle: {
              color: "#89BFE5",
              padding: [0, 0, 0, 15],
              fontSize: 12,
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: legendData[0],
            type: "line",
            symbol: "circle", //设定为实心点
            showAllSymbol: true,
            symbolSize: 5,
            smooth: true,
            itemStyle: {
              normal: {
                color: `#2BDFD4`,
                lineStyle: {
                  //线的颜色
                  color: `#2BDFD4`,
                  width: 1.5,
                },
                areaStyle: {
                  //type: 'default',
                  //旧版渐变色实现
                  // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, //变化度
                  //    //三种由深及浅的颜色
                  //    [{
                  //       offset: 0,
                  //       color: 'rgba(1, 180, 255, 0.2)'
                  //    }, {
                  //       offset: 1,
                  //       color: 'rgba(1, 180, 255, 0.1)'
                  //    }])

                  //新版渐变色实现
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "rgba(1, 180, 255, 0.2)",
                      },
                      {
                        offset: 1,
                        color: "rgba(1, 180, 255, 0.1)",
                      },
                    ],
                  },
                },
              },
            },
            data: seriesData,
          },
          {
            name: legendData[1],
            type: "line",
            symbol: "circle", //设定为实心点
            showAllSymbol: true,
            symbolSize: 5,
            smooth: true,
            itemStyle: {
              normal: {
                color: `#FFD076`,
                lineStyle: {
                  //线的颜色
                  color: `#FFD076`,
                  width: 1.5,
                },
                areaStyle: {
                  //旧版渐变色实现
                  // type: 'default',
                  // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, //变化度
                  //    //三种由深及浅的颜色
                  //    [{
                  //       offset: 0,
                  //       color: 'rgba(255, 223, 4, 0.2)'
                  //    }, {
                  //       offset: 1,
                  //       color: 'rgba(255, 223, 4, 0.1)'
                  //    }])
                  //新版渐变色实现
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "rgba(255, 223, 4, 0.2)",
                      },
                      {
                        offset: 1,
                        color: "rgba(255, 223, 4, 0.1)",
                      },
                    ],
                  },
                },
              },
            },
            data: seriesData1,
          },
        ],
      };
      // 使用 setOption 方法设置图表配置项
      chart.setOption(option);
      window.addEventListener("resize", function () {
        chart.resize();
      });
    },
  },
  mounted() {
    // 在组件挂载后初始化和绘制图表
    this.getData();
  },
};
</script>