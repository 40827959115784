<template>
  <div id="main11" style="width: 100%; height: 90%"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "ThreeTable",
  props: ['data'],
  data() {
    return {};
  },
  watch: {
    data: {
      handler() {
        // 当 data 属性发生变化时，重新初始化图表
        this.initChart();
      },
      // immediate: true, // 立即执行监听器以确保初始化
    },
  },
  methods: {
    initChart() {
      var chartDom = document.getElementById("main11");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          text: "状态统计",

          left: "0",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "right",
          top: "center",
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
            },
            emphasis: {
              label: {
                show: true,

                fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
            },
            data: this.data
          },
        ],
      };

      option && myChart.setOption(option);

      option && myChart.setOption(option);
    },
  },
  mounted() {
    // 在组件挂载后初始化和绘制图表
    this.initChart();
  },
};
</script>