<template>
  <div class="neng">
    <Tree
      @transmit="getMessage"
      :name="transformerName"
      :checked="false"
    ></Tree>
    <!-- <div class="sidebar" :class="{ 'sidebar-expanded': isExpanded }">
     
      <div class="top01" v-show="isExpanded == true">
        <p>按需抄水表</p>
        <el-input placeholder="输入关键字进行过滤" v-model="filterText">
        </el-input>

        <el-tree
          show-checkbox
          class="filter-tree"
          :data="data"
          :props="defaultProps"
          default-expand-all
          :filter-node-method="filterNode"
          ref="tree"
        >
        </el-tree>
      </div>
    </div> -->

    <div class="re">
      <div class="right" :style="{ width: isExpanded ? '79vw' : '87vw' }">
        <div class="topp">
          <div class="data">
            <span>抄表时间: &nbsp;</span>
            <el-date-picker
              v-model="value1"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>

            <el-form
              id="cgx"
              :model="ruleForm"
              ref="ruleForm"
              label-width="120px"
              class="demo-ruleForm"
            >
            </el-form>
            <el-button size="small" type="primary" style="margin-left: 1vw"
              >查询</el-button
            >
            <el-button size="small" type="success" style="margin-left: 1vw"
              >导出</el-button
            >
          </div>
        </div>

        <div class="bottom">
          <h4 class="tablelast">
            <span>2024-02-22 00:00</span><span>至</span
            ><span>2024-02-22 12:28</span>抄表数据
          </h4>
          <el-table
            :show-summary="true"
            :header-cell-style="{ background: '#ebeef5' }"
            :stripe="true"
            :fit="true"
            :data="tableData"
            height="400"
            border
            style="width: 100%"
          >
            <el-table-column prop="date" label="设备"> </el-table-column>
            <el-table-column prop="name" label="数据项"> </el-table-column>
            <el-table-column prop="address" label="本次表底"> </el-table-column>
            <el-table-column prop="dian" label="上次表底"> </el-table-column>
            <el-table-column prop="dian05" label="总用量(kWh)">
            </el-table-column>
            <el-table-column prop="dian05" label="总用量(kWh)">
            </el-table-column>
            <el-table-column prop="dian05" label="金额(元)"> </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- <button
      class="toggle-button"
      @click="toggleSidebar"
      :class="{ 'button-expanded': isExpanded }"
    >
  
      <i :class="isExpanded ? 'el-icon-s-fold' : 'el-icon-s-unfold'"></i>
    </button> -->
  </div>
</template>
  
  <script>
import * as echarts from "echarts";
import Tree from "../../components/tree/leftTree.vue";
export default {
  components: {
    Tree,
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },

  data() {
    return {
      transformerName: "按需抄水表",
      value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
      ruleForm: {
        name: "0.38",
      },
      radio3: "月",
      radio03: "电",
      value: "2024-1-30",
      value2: "2024-1",
      tableData: [],
      activeName: "second",

      isExpanded: true,
      filterText: "",
      data: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  methods: {
    getMessage(data) {
      this.isExpanded = data;
    },

    handleClick(tab, event) {
      console.log(tab, event);
    },
    format(percentage) {
      return percentage === 100 ? "满" : `${percentage}%`;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    toggleSidebar() {
      this.isExpanded = !this.isExpanded;
    },
  },
  mounted() {},
};
</script>
  
  <style lang="scss" scoped>
.tablelast {
  text-align: center;
}
.zd {
  margin-top: 3px;
}
#cgx {
  margin-top: 1.2%;
  margin-left: 1%;
}
.data {
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding-top: 1%;
}
.topp {
  width: 99%;
  vertical-align: middle;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 1.5%;
  border-radius: 3px;
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.3);
  height: 10vh;
  background-color: #fff;
}
.vv {
  color: #07ccfa;
  font-size: 1.4vw;
  font-weight: 800;
}
.topp02 {
  vertical-align: middle;
  width: 99%;
}
.topp03 {
  border-radius: 2px;
  margin-left: 0.5%;
  position: relative;
  display: inline-block;
  width: 32.8%;
  background-color: #fff;
  height: 10vh;
  margin-top: 1%;
  img {
    margin-left: 5%;
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  div {
    position: absolute;
    top: 20px;
    display: inline-block;
  }
}
#main {
  width: 100%;
  height: 50vh;
}
.elta {
  margin-left: 1%;
  margin-right: 1%;
}
.bottom {
  width: 99%;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 1.5%;
  border-radius: 3px;
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.3);
  padding-top: 1%;
}
.el-table {
  padding: 1%;
  padding-top: 0;
}
.max02 {
  color: red;
}
.min02 {
  color: #658e63;
}
.bdy01 {
  width: 100%;
  margin: 3vw auto;

  margin-left: 3vw;
}

.kw {
  font-size: 0.8vw;
  font-weight: 400;
}
.fzl {
  font-size: 1vw;
}
.fz {
  color: #02ae4e;
}
.r05 {
  color: #02ae4e;
  font-size: 1.2vw;
  font-weight: 600;
}

::v-deep .el-progress-bar__outer {
  height: 20px !important;
}
.el-progress {
  width: 65%;
  display: inline-block;
}
.shiyong {
  color: #67c23a;
}
.re {
  width: 80%;
  background-color: #f1f4f5;
}
.neng {
  display: flex;
}
.right {
  background-color: #f1f4f5;
  width: 100%;
}
.top01 {
  p {
    display: inline-block;
    margin-top: 5%;
    margin-left: 2%;
  }
}
.sidebar {
  width: 2%;
  height: 100vh;
  background-color: #cfd3dc;
  transition: width 0.3s ease;
}

.sidebar-expanded {
  width: 12%;
}

.toggle-button {
  position: fixed;
  top: 50%;
  transform: translateY(0);
  left: 10%;
  width: 1.8vw;
  height: 2vw;
  background-color: #cfd3dc;
  border: none;
  cursor: pointer;
  transition: transform 0.08s ease;
}
.el-tree {
  background-color: #cfd3dc;
}

.button-expanded {
  transform: translateX(495%);
}

::v-deep .el-input--suffix .el-input__inner {
  padding-left: 1.8rem !important;
  padding-right: 1.8rem !important;
}
::v-deep .el-input__inner {
  width: 10rem;
  border-radius: 0.25rem;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 0.9rem;
  font-size: 0.8rem;
}
::v-deep .el-input {
  font-size: 0.8rem;
}
::v-deep .el-input__icon {
  width: 1.5rem;
  font-size: 0.8rem !important;
  line-height: 2.5rem;
}
.el-date-editor .el-range__icon {
  font-size: 0.8rem !important;
  line-height: 2.5rem;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 10rem;
}
::v-deep .el-date-editor .el-range-input {
  font-size: 0.6rem;
}
::v-deep .el-date-editor .el-range-separator {
  font-size: 0.8rem;
  line-height: 1.9rem;
}
::v-deep .el-input--suffix .el-input__inner {
  padding-left: 1.8rem !important;
  padding-right: 1.8rem !important;
}
::v-deep .el-input__inner {
  width: 10rem;
  border-radius: 0.25rem;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 0.9rem;
  font-size: 0.8rem;
}
::v-deep .el-input {
  font-size: 0.8rem;
}
::v-deep .el-input__icon {
  width: 1.5rem;
  font-size: 0.8rem !important;
  line-height: 2.5rem;
}
.el-date-editor .el-range__icon {
  font-size: 0.8rem !important;
  line-height: 2.5rem;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 20rem;
}
::v-deep .el-date-editor .el-range-input {
  font-size: 0.6rem;
}
::v-deep .el-date-editor .el-range__icon {
  font-size: 0.8rem;
  line-height: 1.9rem;
}
::v-deep .el-table {
  font-size: 0.8rem !important;
}

::v-deep .el-tree-node__label {
  font-size: 0.7rem;
}

::v-deep .el-input__inner {
  font-size: 0.8rem;
}
.el-button--primary {
  width: 3.5rem;
  height: 1.6rem;
  padding: 0.1rem;
  font-size: 0.8rem;
}
.el-button--small {
  width: 3.5rem;
  height: 1.6rem;
  padding: 0.1rem;
  font-size: 0.8rem;
}

::v-deep .el-radio-button--small .el-radio-button__inner {
  padding: 0.5rem;
  font-size: 0.8rem;
  border-radius: 0;
}

::v-deep .el-form--inline .el-form-item {
  display: inline-block;
  margin-right: 0.5rem;
  vertical-align: top;
}

::v-deep .el-form-item__label {
  font-size: 0.8rem;
}

::v-deep .el-tabs__item {
  padding: 0 1.2rem;
  height: 2.2rem;
  box-sizing: border-box;
  line-height: 2.2rem;
  font-size: 0.7rem;
}
</style>