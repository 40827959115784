<template>
  <div class="center0101">
    <div class="t01" ref="echarts"></div>
    <div class="t02">
      <div class="bdy01" v-if="data.name">
        <p>{{ data.name }}</p>
        <p class="max02">MAX: {{ data.max+data.unit }}</p>
        <p class="min02">MIN: {{ data.min+data.unit }}</p>
        <p>AVG: {{ data.avg+data.unit }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TableNine",
  props:['data'],
  data() {
    return {
      data02: {
        min: this.data.min,
        max: this.data.max,
        avg: this.data.avg,
        unit: this.data.unit,
      },
      chart: null,
  
    };
  },
  watch: {
    data: {
      handler() {
        // 在 data 变化时重新初始化图表
        this.initChart();
      },
    },
  },
  
  methods: {
    initChart() {
      const echarts = require("echarts");
      this.chart = echarts.init(this.$refs.echarts);
      this.updateChart();
    },
    updateChart() {
      const { min, max, avg, unit } = this.data02;
      console.log(this.data);
      this.chart.setOption({
        backgroundColor: "#fff",
        series: [
          {
            name: "压力",
            type: "gauge",
            pointer: {
              itemStyle: {
                color: "#1c8e19", // 设置指针颜色为红色
              },
            },
            min: min,
            max: max,
            radius: "80%",

            detail: {
              fontSize: 20,
              formatter: avg + unit,
              top: 50,
            },
            title: {
              fontSize: 0,
            },
            splitNumber: 1, //这个是刻度分区的
            axisLine: {
              lineStyle: {
                color: [
                  [min / 500, "#1c8e19"],
                  [max / 300, "#58d9f9"],
                  [1, "#e5921a"],
                ],
                width: 10,
              },
            },

            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,

              formatter: function (value) {
                // 在最小值和最大值位置显示数值
                if (value === min || value === max) {
                  return value;
                } else {
                  return ""; // 其他位置隐藏数值
                }
              },
            },
            data: [
              {
                value: avg,
                name: unit,
              },
            ],
          },
        ],
      });
    },
  },
  mounted() {
    this.initChart();
  },
};
</script>
<style lang="scss" scoped>
.center0103,
.center0102,
.center0101 {
  border-left: 1px solid black;
  display: flex;
  width: 33.3%;
  height: 34vh;
  background-color: #02ae4e;
  .t01 {
    flex: 0.6;
    background-color: #fff;
  }
  .t02 {
    flex: 0.4;
    display: flex;
    background-color: #fff;
  }
}
.bdy01 {
  width: 100%;
  margin: 3vw auto;

  margin-left: 3vw;
}
</style>