<template>
  <div style="display: flex; width: 100vw">
    <Tree :name="'需量分析'" :checked="true" :data="data" @gz01="getIds"></Tree>

    <div class="right" :style="{ width: isExpanded ? '77vw' : '87vw' }">
      <div
        style="
          display: flex;
          align-items: center;
          width: 60vw;
          position: absolute;
          left: 2vw;
        "
      >
        <span for="">查询时间</span>
        <div class="content">
          <el-radio-group
            v-model="radio3"
            size="small"
            style="margin-left: 1.5vw; margin-right: 1.5vw"
          >
            <el-radio-button label="日"></el-radio-button>
            <el-radio-button label="月"></el-radio-button>
            <el-radio-button label="自定义"></el-radio-button>
          </el-radio-group>
          <el-date-picker
            v-if="radio3 === '日'"
            v-model="date"
            type="date"
            placeholder="选择日"
          >
          </el-date-picker>
          <el-date-picker
            v-if="radio3 === '月'"
            v-model="date"
            type="month"
            placeholder="选择月"
          >
          </el-date-picker>
          <el-date-picker
            v-if="radio3 === '自定义'"
            v-model="date"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <el-button
          size="small"
          type="primary"
          style="margin-left: 3rem"
          @click="onSearch"
          >查询</el-button
        >
        <el-button size="small" type="success" style="margin-left: 1vw"
          >需量申报</el-button
        >
      </div>
      <div id="tu01" ref="tu01"></div>
      <el-table
        :data="tableData"
        border
        style="width: 73%; margin-top: 3vh; height: 12vh; overflow-y: auto"
      >
        <el-table-column prop="name" label="名称" width="180">
        </el-table-column>
        <el-table-column prop="max" label="最大需量(KW)" width="180">
        </el-table-column>
        <el-table-column prop="maxTime" label="日期"> </el-table-column>
        <el-table-column prop="min" label="最小需量(KW)"> </el-table-column
        ><el-table-column prop="minTime" label="日期"> </el-table-column>
        <el-table-column prop="svg" label="平均需量(KW)"> </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import request from "@/request/request";
import Tree from "@/components/tree/leftTree.vue";
import formatDates from "@/utils";
export default {
  components: {
    Tree,
  },
  data() {
    return {
      isExpanded: false,
      value1: "",
      list: [],
      tableData: [],
      radio3: "日",
      value: "2024-1-30",
      value2: "2024-1",
      tags: [
        { name: "标签一", type: "" },
        { name: "标签二", type: "" },
        { name: "标签三", type: "" },
        { name: "标签四", type: "" },
        { name: "标签五", type: "" },
      ],
      searchKeyword: "",
      filteredTreeData: [],
      data: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      type: 1,
      ids: "11",
      date: "2024-04-01",
    };
  },
  methods: {
    onSearch() {
      if (this.radio3 == "日" && this.date != "2024-04-01") {
        this.type = 1;
        const date = [];
        date.push(this.date);
        this.date = formatDates(date)[0];
        this.getList();
      }
      if (this.radio3 == "月" && this.date != "2024-04-01") {
        this.type = 2;
        const date = [];
        date.push(this.date);
        this.date = formatDates(date)[0];
        this.getList();
      }
      if (this.radio3 == "年" && this.date != "2024-04-01") {
        console.log(this.date);
        this.type = 3;
        const date = [];
        date.push(this.date);
        this.date = formatDates(date)[0];
        this.getData();
      }
      if (this.radio3 == "自定义" && this.date != "2024-04-01") {
        console.log(this.date);
        this.type = 1;
        this.date = formatDates(this.date).join(";");
        this.getList();
      }
    },
    transformData(data, pid = 1) {
      // console.log(6666);
      if (!data) {
        return []; // 如果data为null或undefined，则返回一个空数组
      }
      const result = [];
      for (const node of data) {
        if (node.pid === pid) {
          // console.log(`Processing node ${node.id}`);
          const newNode = {
            id: node.stationId,
            label: node.name,
            type: node.type,
            children: this.transformData(data, node.id),
          };
          result.push(newNode);
        }
      }
      return result;
    },
    async getData() {
      const res = await request("/dataAnalysis/listEquipment");
      // console.log(res.data.content);
      this.data = this.transformData(res.data.content);
      // console.log(this.filteredTreeData);
      // console.log(['2024-02-20','2024-02-20'].join(';'));
    },
    async getList() {
      const res = await request({
        url: "/dataAnalysis/seriesHistory",
        method: "get",
        params: {
          ids: this.ids,
          date: this.date,
          type: this.type,
        },
      });
      this.tableData = res.data.content.tableArr;
      console.log(res.data.content);
      this.keysArray = res.data.content.data[0].data.map(
        (obj) => Object.keys(obj)[0]
      );
      if (this.radio3 == "日") {
        this.keysArray = this.keysArray.map((x) => x.substring(11, 16));
      } else {
        this.keysArray = this.keysArray.map((x) => x.substring(0, 10));
      }
      // console.log(keysArray,7777);
      this.list = this.convertToChartFormat(res.data.content);
      console.log(this.list, 666666666);
      this.tu();
    },
    getIds(data) {
      // console.log('ids',data);
      this.ids = data.join(",");
      this.getList();
    },
    tu() {
      var myChart = echarts.init(this.$refs.tu01);
      var option;
      option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: this.list.series.map((x) => x.name), // 设置图例的数据，对应系列的名称
        },
        xAxis: {
          type: "category",
          data: this.keysArray,
        },
        yAxis: {
          type: "value",
        },
        toolbox: {
          feature: {
            magicType: {
              type: ["bar", "line"], // 可切换的图表类型
              title: {
                bar: "切换为柱状图",
                line: "切换为折线图",
              },
            },
          },
          iconStyle: {
            borderColor: "#1e90ff",
          },
          emphasis: {
            iconStyle: {
              color: "#1e90ff",
            },
          },
        },
        series: this.list.series,

        dataZoom: [
          {
            id: "dataZoomX",
            type: "slider",
            backgroundColor: "#F2F5F9",
            fillerColor: "#BFCCE3",
            showDataShadow: false,
            height: 13,
            handleSize: 22,
            right: 60,
            left: 60,
            bottom: 15,
            handleSize: "200%", // 手柄大小
            borderColor: "none",
            handleIcon:
              "M8.2,9.6V-0.1H6.3v9.7H3.1v14.9h3.3v9.7h1.8v-9.7h3.3V9.6H8.2z M9.7,20.4H4.8v-1.4h4.9V20.4z M9.7,15.1H4.8v-1.4h4.9V15.1z",
            handleStyle: {
              color: "#BFCCE3",
              global: true, // 缺省为 false
              shadowBlur: 6,
              shadowColor: "rgba(123, 154, 204, 0.5)",
              shadowOffsetX: 0, // 阴影偏移x轴多少
              shadowOffsetY: 0, // 阴影偏移y轴多少
            },
          },
        ],
      };

      // option = {
      //   title: {
      //     text: "带有可拖动进度条的图表",
      //     left: "center",
      //   },
      //   tooltip: {
      //     trigger: "axis",
      //     axisPointer: {
      //       animation: false,
      //     },
      //   },
      //   xAxis: {
      //     type: "category",
      //     boundaryGap: false,
      //     data: [
      //       "Mon",
      //       "Tue",
      //       "Wed",
      //       "Thu",
      //       "Fri",
      //       "Sat",
      //       "Sun",
      //       "Fri",
      //       "Sat",
      //       "Sun",
      //       "Wed",
      //       "Thu",
      //       "Fri",
      //       "Sat",
      //       "Sun",
      //     ],
      //   },
      //   yAxis: {
      //     type: "value",
      //     boundaryGap: [0, "100%"],
      //   },
      //   dataZoom: [
      //     {
      //       id: "dataZoomX",
      //       type: "slider",
      //       backgroundColor: "#F2F5F9",
      //       fillerColor: "#BFCCE3",
      //       showDataShadow: false,
      //       height: 13,
      //       handleSize: 22,
      //       right: 60,
      //       left: 60,
      //       bottom: 15,
      //       handleSize: "200%", // 手柄大小
      //       borderColor: "none",
      //       handleIcon:
      //         "M8.2,9.6V-0.1H6.3v9.7H3.1v14.9h3.3v9.7h1.8v-9.7h3.3V9.6H8.2z M9.7,20.4H4.8v-1.4h4.9V20.4z M9.7,15.1H4.8v-1.4h4.9V15.1z",
      //       handleStyle: {
      //         color: "#BFCCE3",
      //         global: true, // 缺省为 false
      //         shadowBlur: 6,
      //         shadowColor: "rgba(123, 154, 204, 0.5)",
      //         shadowOffsetX: 0, // 阴影偏移x轴多少
      //         shadowOffsetY: 0, // 阴影偏移y轴多少
      //       },
      //     },
      //   ],
      //   // dataZoom: [
      //   //   {
      //   //     type: "slider",
      //   //     show: true,
      //   //     height: 16,
      //   //     bottom: 10,
      //   //     borderColor: "transparent",
      //   //     backgroundColor: "#e2e2e2",
      //   //     // 拖拽手柄样式 svg 路径
      //   //     // handleIcon: 'M0,0 L1,0 L1,2 L0,2 Z',
      //   //     handleIcon:
      //   //       "M8.2,9.6V-0.1H6.3v9.7H3.1v14.9h3.3v9.7h1.8v-9.7h3.3V9.6H8.2z M9.7,20.4H4.8v-1.4h4.9V20.4z M9.7,15.1H4.8v-1.4h4.9V15.1z",

      //   //     handleColor: "#aab6c6",
      //   //     handleSize: 20,
      //   //     handleStyle: {
      //   //       borderColor: "#aab6c6",
      //   //       shadowBlur: 4,
      //   //       shadowOffsetX: 1,
      //   //       shadowOffsetY: 1,
      //   //       shadowColor: "#e5e5e5",
      //   //     },
      //   //     start: 0,
      //   //     end: 100,
      //   //   },
      //   // ],
      //   series: [
      //     {
      //       name: "模拟数据",
      //       type: "line",
      //       smooth: true,
      //       symbol: "none",
      //       stack: "a",
      //       areaStyle: {
      //         normal: {},
      //       },
      //       data: [
      //         820, 932, 901, 934, 1290, 1330, 1320, 932, 901, 934, 1290, 1330,
      //         932, 901, 934, 1290, 1330,
      //       ],
      //     },
      //   ],
      // };
      option && myChart.setOption(option);
    },
    // tu() {
    //   var myChart = echarts.init(this.$refs.tu01);

    //   var option;
    //   option = {
    //     legend: {
    //       data: ["1",'2'], // 设置图例的数据，对应系列的名称
    //     },
    //     xAxis: {
    //       type: "category",
    //       data: [
    //         "00:00",
    //         "01:00",
    //         "02:00",
    //         "03:00",
    //         "04:00",
    //         "05:00",
    //         "06:00",
    //         "07:00",
    //         "08:00",
    //         "09:00",
    //         "10:00",
    //         "11:00",
    //         "12:00",
    //         "13:00",
    //         "14:00",
    //         "15:00",
    //         "16:00",
    //       ],
    //     },
    //     yAxis: {
    //       type: "value",
    //     },
    //     toolbox: {
    //       feature: {
    //         magicType: {
    //           type: ["bar", "line"], // 可切换的图表类型
    //           title: {
    //             bar: "切换为柱状图",
    //             line: "切换为折线图",
    //           },
    //         },
    //       },
    //       iconStyle: {
    //         borderColor: "#1e90ff",
    //       },
    //       emphasis: {
    //         iconStyle: {
    //           color: "#1e90ff",
    //         },
    //       },
    //     },
    //     series: [
    //       {
    //         data: [
    //           934, 1290, 1330, 366, 1320, 932, 820, 932, 901, 934, 1320, 932,
    //           901, 934, 129, 820, 932, 901, 934, 1290, 1330, 1320, 932, 901,
    //           934, 1290, 1330, 34, 1290, 1330, 1320, 932, 820, 932, 901, 934,
    //           1320, 932, 901,
    //         ],
    //         type: "line",
    //         smooth: true,
    //         name:'1'
    //       },
    //       {
    //         data: [
    //           934, 1290, 1330, 366, 1320, 932, 820, 932, 901, 934, 1320, 932,
    //           901, 934, 129, 34, 1290, 1330, 1320, 932, 820, 932, 901, 934,
    //           1320, 932, 901,

    //           820, 932, 901, 934, 1290, 1330, 1320, 932, 901, 934, 1290, 1330,
    //         ],
    //         type: "line",
    //         smooth: true,
    //         name:'2'
    //       },
    //     ],
    //     dataZoom: [
    //       {
    //         id: "dataZoomX",
    //         type: "slider",
    //         backgroundColor: "#F2F5F9",
    //         fillerColor: "#BFCCE3",
    //         showDataShadow: false,
    //         height: 13,
    //         handleSize: 22,
    //         right: 60,
    //         left: 60,
    //         bottom: 15,
    //         handleSize: "200%", // 手柄大小
    //         borderColor: "none",
    //         handleIcon:
    //           "path://M8.2,9.6V-0.1H6.3v9.7H3.1v14.9h3.3v9.7h1.8v-9.7h3.3V9.6H8.2z M9.7,20.4H4.8v-1.4h4.9V20.4z M9.7,15.1H4.8v-1.4h4.9V15.1z",
    //         handleStyle: {
    //           color: "#BFCCE3",
    //           global: true, // 缺省为 false
    //           shadowBlur: 6,
    //           shadowColor: "rgba(123, 154, 204, 0.5)",
    //           shadowOffsetX: 0, // 阴影偏移x轴多少
    //           shadowOffsetY: 0, // 阴影偏移y轴多少
    //         },
    //       },
    //     ],
    //   };

    //   // option = {
    //   //   title: {
    //   //     text: "带有可拖动进度条的图表",
    //   //     left: "center",
    //   //   },
    //   //   tooltip: {
    //   //     trigger: "axis",
    //   //     axisPointer: {
    //   //       animation: false,
    //   //     },
    //   //   },
    //   //   xAxis: {
    //   //     type: "category",
    //   //     boundaryGap: false,
    //   //     data: [
    //   //       "Mon",
    //   //       "Tue",
    //   //       "Wed",
    //   //       "Thu",
    //   //       "Fri",
    //   //       "Sat",
    //   //       "Sun",
    //   //       "Fri",
    //   //       "Sat",
    //   //       "Sun",
    //   //       "Wed",
    //   //       "Thu",
    //   //       "Fri",
    //   //       "Sat",
    //   //       "Sun",
    //   //     ],
    //   //   },
    //   //   yAxis: {
    //   //     type: "value",
    //   //     boundaryGap: [0, "100%"],
    //   //   },
    //   //   dataZoom: [
    //   //     {
    //   //       id: "dataZoomX",
    //   //       type: "slider",
    //   //       backgroundColor: "#F2F5F9",
    //   //       fillerColor: "#BFCCE3",
    //   //       showDataShadow: false,
    //   //       height: 13,
    //   //       handleSize: 22,
    //   //       right: 60,
    //   //       left: 60,
    //   //       bottom: 15,
    //   //       handleSize: "200%", // 手柄大小
    //   //       borderColor: "none",
    //   //       handleIcon:
    //   //         "M8.2,9.6V-0.1H6.3v9.7H3.1v14.9h3.3v9.7h1.8v-9.7h3.3V9.6H8.2z M9.7,20.4H4.8v-1.4h4.9V20.4z M9.7,15.1H4.8v-1.4h4.9V15.1z",
    //   //       handleStyle: {
    //   //         color: "#BFCCE3",
    //   //         global: true, // 缺省为 false
    //   //         shadowBlur: 6,
    //   //         shadowColor: "rgba(123, 154, 204, 0.5)",
    //   //         shadowOffsetX: 0, // 阴影偏移x轴多少
    //   //         shadowOffsetY: 0, // 阴影偏移y轴多少
    //   //       },
    //   //     },
    //   //   ],
    //   //   // dataZoom: [
    //   //   //   {
    //   //   //     type: "slider",
    //   //   //     show: true,
    //   //   //     height: 16,
    //   //   //     bottom: 10,
    //   //   //     borderColor: "transparent",
    //   //   //     backgroundColor: "#e2e2e2",
    //   //   //     // 拖拽手柄样式 svg 路径
    //   //   //     // handleIcon: 'M0,0 L1,0 L1,2 L0,2 Z',
    //   //   //     handleIcon:
    //   //   //       "M8.2,9.6V-0.1H6.3v9.7H3.1v14.9h3.3v9.7h1.8v-9.7h3.3V9.6H8.2z M9.7,20.4H4.8v-1.4h4.9V20.4z M9.7,15.1H4.8v-1.4h4.9V15.1z",

    //   //   //     handleColor: "#aab6c6",
    //   //   //     handleSize: 20,
    //   //   //     handleStyle: {
    //   //   //       borderColor: "#aab6c6",
    //   //   //       shadowBlur: 4,
    //   //   //       shadowOffsetX: 1,
    //   //   //       shadowOffsetY: 1,
    //   //   //       shadowColor: "#e5e5e5",
    //   //   //     },
    //   //   //     start: 0,
    //   //   //     end: 100,
    //   //   //   },
    //   //   // ],
    //   //   series: [
    //   //     {
    //   //       name: "模拟数据",
    //   //       type: "line",
    //   //       smooth: true,
    //   //       symbol: "none",
    //   //       stack: "a",
    //   //       areaStyle: {
    //   //         normal: {},
    //   //       },
    //   //       data: [
    //   //         820, 932, 901, 934, 1290, 1330, 1320, 932, 901, 934, 1290, 1330,
    //   //         932, 901, 934, 1290, 1330,
    //   //       ],
    //   //     },
    //   //   ],
    //   // };
    //   option && myChart.setOption(option);
    // },
    closeTag(tag) {
      this.tags = this.tags.filter((t) => t !== tag);
    },
    handleDropdownCommand(command) {
      if (command === "closeAll") {
        this.tags = [];
      } else if (command === "closeOther") {
        this.tags = [this.tags[0]];
      }
    },
    querySearch(queryString, cb) {
      if (queryString) {
        const filteredTreeData = this.generateFilteredTreeData(queryString);
        this.filteredTreeData = filteredTreeData;
        cb(filteredTreeData);
      } else {
        this.filteredTreeData = this.data;
        cb(this.data);
      }
    },
    handleSelect(item) {
      console.log(item);
    },
    handleNodeClick(data) {
      console.log(data);
    },
    generateFilteredTreeData(queryString) {
      return this.data
        .filter((branch) =>
          branch.children.some((leaf) =>
            leaf.label.toLowerCase().includes(queryString.toLowerCase())
          )
        )
        .map((branch) => ({
          ...branch,
          children: branch.children.filter((leaf) =>
            leaf.label.toLowerCase().includes(queryString.toLowerCase())
          ),
        }));
    },
    convertToChartFormat(rawData) {
      const chartData = {
        series: [],
      };

      rawData.data.forEach((meter) => {
        const meterData = {
          data: [],
          type: "line",
          smooth: true,
          name: meter.name, // 使用电表的名称作为 series 的名称
        };

        meter.data.forEach((entry) => {
          const timestamp = Object.keys(entry)[0];
          const value = entry[timestamp]; // 添加随机值
          // 使用正确的日期格式
          meterData.data.push(value);
        });

        chartData.series.push(meterData);
      });

      return chartData;
    },
  },
  mounted() {
    this.getData();
    this.getList();
    // this.filteredTreeData = this.data;
    setTimeout(() => {
      this.tu();
    }, 1000);

    // const debounce = (fn, delay) => {
    //   let timer;
    //   return function () {
    //     if (timer) {
    //       clearTimeout(timer);
    //     }
    //     timer = setTimeout(() => {
    //       fn();
    //     }, delay);
    //   };
    // };
    // const getWindowInfo = () => {

    // this.tu();
    // };
    // const cancalDebounce = debounce(getWindowInfo, 500);

    // this.$nextTick(()=>{
    //     window.addEventListener("resize", ()=>{
    //       if (this.myChart) {
    //       this.myChart.dispose();

    //     }
    //     });
    //     window.addEventListener("resize", cancalDebounce);
    //   })
  },
};
</script>

<style lang="scss" scoped>
.title {
  width: 12vw;
  //   height: 20vh;
}

.el-tree {
  background-color: #cfd3dc;
}
.right {
  position: relative;
  width: 70rem;
  height: 60rem;
  //   background-color: gray;
  margin: 2vh;
  #tu01 {
    margin-top: 7vh;
    width: 70rem;
    height: 30rem;
  }
}
.el-table {
  font-size: 0.8rem;
}
::v-deep .el-table .el-table__cell {
  padding: 0.8rem 0;
}
.el-button {
  width: 4rem;
  height: 2rem;
  font-size: 0.8rem;
  padding: 0;
}

::v-deep .el-radio-button__inner {
  padding: 0.5rem 1rem !important;
  font-size: 0.8rem !important;
}

::v-deep .el-input--suffix .el-input__inner {
  padding-left: 1.8rem !important;
  padding-right: 1.8rem !important;
}
::v-deep .el-input__inner {
  width: 12rem;
  border-radius: 0.25rem;
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.9rem;
  font-size: 0.8rem;
}
::v-deep .el-input {
  font-size: 0.8rem;
}
::v-deep .el-input__icon {
  width: 1.5rem;
  font-size: 0.8rem !important;
  line-height: 2rem;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 12rem;
}
::v-deep .el-date-editor .el-range-separator {
  height: 100%;
  padding: 0 0.3rem;
  margin: 0;
  text-align: center;
  line-height: 2rem;
  font-size: 0.8rem;
  width: 5%;
  color: #303133;
}
::v-deep .el-date-editor .el-range__icon {
  line-height: 2rem;
}
::v-deep .el-date-editor .el-range-input {
  font-size: 0.8rem;
}
::v-deep .el-range-editor.el-input__inner {
  width: 16rem !important;
  border-radius: 0.25rem;
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.9rem !important;
  font-size: 0.8rem;
}
</style>