<template>
  <div id="login">
    <div class="ms-login">
      <div class="platName">传统能源运营平台</div>
      <img
        src="../../assets/leftTop.png"
        alt=""
        style="position: absolute; left: -5px; top: -5px"
      />
      <img
        src="../../assets/rightTop.png"
        alt=""
        style="position: absolute; right: -5px; top: -5px"
      />
      <img
        src="../../assets/leftBOttom.png"
        alt=""
        style="position: absolute; left: -5px; bottom: -5px"
      />
      <img
        src="../../assets/rightBottom.png"
        alt=""
        style="position: absolute; right: -5px; bottom: -5px"
      />
      <div class="loginTitle">用户登录</div>

      <el-input
        style="margin-bottom: 2vh; margin-top: 1vh"
        placeholder="请输入账号"
        v-model="input4"
      >
        <i slot="prefix" class="el-input__icon el-icon-user-solid"></i>
      </el-input>
      <el-input
        placeholder="请输入密码"
        v-model="input3"
        :type="showPassword ? 'text' : 'password'"
      >
        <i slot="prefix" class="el-input__icon el-icon-lock"></i>
        <i
          slot="suffix"
          :class="
            showPassword
              ? 'el-input__icon el-icon-view'
              : 'el-input__icon el-icon-view'
          "
          @click="togglePasswordVisibility"
        >
        </i>
      </el-input>
      <button
        type="button"
        class="el-button el-button--primary"
        @click="onLogin"
      >
        <span>登录</span>
      </button>
    </div>
  </div>
</template>

<script>
import request from "@/request/request";

import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      input3: "",
      input4: "",
      showPassword: false,
    };
  },
  computed: {
    ...mapState(["selectedBoxIndex", "activeIndex"]),
  },
  methods: {
    ...mapMutations([
      "selectedIndex",
      "setActiveIndex",
      "selectMenu",
      "selectparentmenu",
    ]),
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async onLogin() {
      const res = await request({
        method: "post",
        url: "/user/login",
        data: {
          username: this.input4,
          password: this.input3,
        },
      });
      if (res.data.success) {
        localStorage.setItem("token", res.data.content);
        const token = localStorage.getItem("token");
        if (token) {
          console.log("看看到底有没有token",token);
          this.selectparentmenu("measure");
          localStorage.setItem("selectedBoxIndex", 0);
          localStorage.setItem("activeIndex", "/industry");
          this.selectMenu({
            label: "首页",
            name: "industry",
            path: "/industry",
          });
          this.setActiveIndex("/industry");
          this.$router.push("/shouye");
          this.$message({
            type: "success",
            message: "登录成功!",
          });
          window.location.reload()
        }
       
      }else{
        this.$message({
          type: "error",
          message: "登录失败",
        });
      }
      
    },
  },
};
</script>

<style lang="scss" scoped>
#login {
  width: 100vw;
  height: 100vh;
  background-image: url(../../assets/loginbg.59b9c092.png);
  background-size: 100% 100%;
  background-position: 50%;
  overflow: auto;
  overflow-x: hidden;
  position: relative;
  .ms-login {
    position: absolute;
    left: 60%;
    top: 35%;
    width: 20vw;
    height: 20vh;
    margin: auto;
    background: rgba(36, 41, 124, 0.45);
    border: 1px solid #02c6ff;
    line-height: 3vh;
    padding: 2vw 3vw;
    border-radius: 3px;
    .platName {
      height: 3vh;
      font-size: 1.5vw;
      margin-left: 1vw;
      margin-top: -5vw;
      text-align: center;
      margin-bottom: 3vw;
      font-weight: 600;
      font-style: italic;
      background-image: -webkit-linear-gradient(left, #02c6ff, #00e8cf);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .loginTitle {
      height: 1rem;
      font-size: 1rem;
      font-weight: 400;
      color: #02c6ff;
      line-height: 1rem;
      text-align: center;
      font-family: PingFangSC-Regular, PingFang SC;
      margin-bottom: 1rem;
    }

    button {
      margin-top: 1rem;
      width: 20rem;
      height: 2rem;
      color: #000;
      // height: 0.239583rem;
      font-size: 1vw;
      background-color: #02c6ff;
      padding: 0;
      span {
        line-height: 2rem;
      }
    }
  }
}
::v-deep .el-input__inner {
  font-size: 0.8rem;
  padding: 0;
  width: 20rem;
  height: 2rem;
  line-height: 2rem;
  background-color: rgba(36, 41, 124, 0.45);
  // font-size: .09375rem;
  color: #fff;
  border-color: #02c6ff !important;
  // background-color:red!important;
}
::v-deep .el-input__icon {
  font-size: 0.8rem;
  line-height: 2rem;
  width: 1.4rem;
}
::v-deep .el-input--prefix .el-input__inner {
  padding-left: 1.8rem;
}
</style>
