<template>
  <el-card style="background-color: transparent; border: none">
    <div ref="chart" style="width: 100%; height: 190%"></div>
  </el-card>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "TableFive",
  data() {
    return {};
  },
  methods: {
    initChart() {
      const chartContainer = this.$refs.chart;

      // 使用 echarts.init 初始化图表
      const chart = echarts.init(chartContainer);
      var option;
      option = {
        tooltip: {
          trigger: "axis",
          show: false,
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          show: false,
        },
        grid: {
          left: "3%",
          right: "10%",
          bottom: "3%",
          top: "5%",
          containLabel: true,
        },
        xAxis: [
          {
            splitLine: {
              show: false,
            },
            type: "value",
            show: false,
          },
        ],
        yAxis: [
          {
            splitLine: {
              show: false,
            },
            axisLine: {
              //y轴
              show: false,
            },
            type: "category",
            axisTick: {
              show: false,
            },
            inverse: true,
            data: [
              "设备一",
              "设备二",
              "设备三",
              "设备四",
              "设备五",
              "设备六",
              "设备七",
              "设备八",
            ],
            axisLabel: {
              color: "#A7D6F4",
              fontSize: '0.8rem',
            },
          },
        ],
        series: [
          {
            name: "业务数量统计",
            type: "bar",
            barWidth: 12, // 柱子宽度
            label: {
              show: true,
              position: "right", // 位置
              color: "#A7D6F4",
              fontSize: '0.8rem',
              distance: 15, // 距离
              formatter: "{c}", // 这里是数据展示的时候显示的数据
            }, // 柱子上方的数值
            itemStyle: {
              barBorderRadius: [0, 20, 20, 0], // 圆角（左上、右上、右下、左下）

              color: new echarts.graphic.LinearGradient(
                1,
                0,
                0,
                0,
                [
                  {
                    offset: 0,
                    color: "#51C5FD",
                  },
                  {
                    offset: 1,
                    color: "#005BB1",
                  },
                ],
                false
              ), // 渐变
            },
            data: [1029, 1300, 2727, 439, 500, 600, 2393, 905],
          },
        ],
      };
      chart.setOption(option);
      window.addEventListener("resize", function () {
        chart.resize();
      });
    },
  },
  mounted() {
    // 在组件挂载后初始化和绘制图表
    this.initChart();
  },
};
</script>