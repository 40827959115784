// request.js
import axios from 'axios';
import router from '@/router';
import { Message } from 'element-ui';
// let loadingrequest = Loading.service(options);
// mqtttransformerMonitor.hualaizhike.com
// 120.224.50.45:8001/api
// https://mqtt.hualaizhike.com
// http://192.168.3.7:8000
console.log(localStorage.getItem('token'));
const request = axios.create({
    baseURL: 'https://mqtt.hualaizhike.com', // 设置基础URL
    headers: {
        'Content-Type': 'application/json',
        hualai: localStorage.getItem('token'),
        // 其他全局的请求头设置
    },
});

// 在请求发送前执行的拦截器
// request.interceptors.request.use(
//     (config) => {
//         // 在请求头中添加身份验证 token
//         const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYmYiOjE3MDQ3Njg3NDIsImV4cCI6MTcwNDg1NTE0MiwiaWF0IjoxNzA0NzY4NzQyLCJ1c2VyIjp7ImlkIjoxLCJhY2NvdW50IjoiMTIzIiwibmFtZSI6IjEyMyIsInN0YXR1cyI6dHJ1ZSwicm9sZUlkIjoiMTIzIiwicGVybWlzc2lvbnMiOiIxMjMifX0.EpXf68FibEtt7oupm2G0amluVmt0ZkOloZ72ciQqImM';
//         if (token) {
//             config.headers.Authorization = `Bearer ${token}`;
//         }
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

// request.interceptors.request.use(
//     (config) => {
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );
request.interceptors.request.use(
    (config) => {
        // 在请求头中添加身份验证 token
        // const token = localStorage.getItem('token'); // 在这里替换成你的 token
        // if (token) {
        //     config.headers.Authorization = `Bearer ${token}`;
        // }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截器
// request.interceptors.response.use(
//     async (response) => {
//         return response;
//     },
//     (error) => {
//         // return Promise.reject(error);
//         // if (error.response.status === 401) {
//         //     console.error('Unauthorized error: ', error)
//         //     // 检查当前路径是否是登录页
//         //     if (router.currentRoute.path !== '/login') {
//         //         // 不是登录页，则重定向到登录页
//         //         Message({
//         //             message: '身份过期请重新登录!',
//         //             type: 'info',
//         //         });
//         //         localStorage.clear();
//         //         router.push('/login');

//         //     }
//         //   }
//         return Promise.reject(error);

//     }
// );
request.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 500) {
            // 处理500错误，比如显示错误信息给用户
            Message.error('服务器错误，请稍后再试！');
            // 还可以进行其他操作，比如记录错误日志等
        }
        return Promise.reject(error);
    }
);
export default request;
