import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    parentName: "measure",
    tabsList: [], //面包屑的数据
    isCollapse: false, //用于控制菜单的展开还是收起
    asideShow: false,
    selectedBoxIndex: 0,
    username: "",
    activeIndex: "",
    menus: [
      {
        name: "measure",
        label: "能源计量",
        url: "/assets/measure.png",
        children: [
          {
            path: "/industry",
            name: "industry",
            label: "首页",
          },
          // {
          //   path: "/energyFlow",
          //   name: "energyFlow",
          //   label: "能源流图",
          // },
        ],
      },
      {
        name: "consumption",
        label: "能耗评价",
        url: "/assets/consumption.png",
        children: [
          {
            path: "/ConsumptionAnalysis",
            name: "ConsumptionAnalysis",
            label: "用能总览",
          },
          {
            path: "/classifyAndSub",
            name: "classifyAndSub",
            label: "分项能耗",
          },
          // {
          //   path: "/energyConsumptionRanking",
          //   name: "energyConsumptionRanking",
          //   label: "分区能耗",
          // },
        ],
      },
      {
        name: "carbon",
        label: "碳核算",
        url: "/assets/carbon.png",
        children: [
          // {
          //   path: "/carbonSjlr",
          //   name: "carbonSjlr",
          //   label: "碳排录入",
          // },
          {
            path: "/carbonQuery",
            name: "carbonQuery",
            label: "碳排查询",
          },
          {
            path: "/carbonAnalysis",
            name: "carbonAnalysis",
            label: "碳分析",
          },
        ],
      },
      {
        name: "data",
        label: "数据分析",
        url: "/assets/data.png",
        children: [
          {
            path: "/demandAnalysis",
            name: "demandAnalysis",
            label: "不同设备分析",
          },
          // {
          //   path: "/loadAnalysis",
          //   name: "loadAnalysis",
          //   label: "负荷分析",
          // },
          // {
          //   path: "/fengPingGu",
          //   name: "fengPingGu",
          //   label: "尖峰平台",
          // },
          {
            path: "/powerFactorAnalysis",
            name: "powerFactorAnalysis",
            label: "功率因数",
          },
          {
            path: "/comparisondAnalysis",
            name: "comparisondAnalysis",
            label: "同设备不同天分析",
          },
          {
            path: "/shirtReport",
            name: "shirtReport",
            label: "班次用能",
          },
          {
            path: "/energySavingStatistics",
            name: "energySavingStatistics",
            label: "节能量统计",
          },
          // {
          //   path: "/tableReport",
          //   name: "tableReport",
          //   label: "数据报表",
          // },
        ],
      },
      {
        name: "diagnosis",
        label: "运行诊断",
        url: "/assets/diagnosis.png",
        children: [
          {
            path: "/warningReport",
            name: "warningReport",
            label: "报警查询",
          },
          {
            path: "/transformerMonitor",
            name: "transformerMonitor",
            label: "变压器",
          },
          // {
          //   path: "/harmonicMonitoring",
          //   name: "harmonicMonitoring",
          //   label: "谐波监测",
          // },
          {
            path: "/voltageDeviation",
            name: "voltageDeviation",
            label: "电压偏差",
          },
          {
            path: "/threePhaseImbalance",
            name: "threePhaseImbalance",
            label: "三项不平衡",
          },
          {
            path: "/commStatus",
            name: "commStatus",
            label: "通讯状态",
          },
          
          // {
          //   path: "/manualfilling",
          //   name: "manualfilling",
          //   label: "人工填报",
          // },
          {
            path: "/wattHourMeterRealData",
            name: "wattHourMeterRealData",
            label: "电表实时数据",
          },
          {
            path: "/wattHourMeterHistoryData",
            name: "wattHourMeterHistoryData",
            label: "电表历史数据",
          },
          // {
          //   path: "/waterMeterHistoryData",
          //   name: "waterMeterHistoryData",
          //   label: "水表历史数据",
          // },
          // {
          //   path: "/asNeededEle",
          //   name: "asNeededEle",
          //   label: "按需抄电表",
          // },
          // {
          //   path: "/asNeededWater",
          //   name: "asNeededWater",
          //   label: "按需抄水表",
          // },
          {
            path: "/diagramMonitor",
            name: "diagramMonitor",
            label: "图形监控",
          },
          // {
          //   path: "/oneBoiler",
          //   name: "oneBoiler",
          //   label: "1号电锅炉",
          // },
          {
            path: "/systemlog",
            name: "systemlog",
            label: "操作日志",
          },
          {
            path: "/loginLog",
            name: "loginLog",
            label: "登录日志",
          },
        ],
      },
      {
        name: "spotChecks",
        label: "点检管理",
        url: "/assets/spotChecks.png",
        children: [
          {
            path: "/inspectionItem",
            name: "inspectionItem",
            label: "点检库",
          },
          {
            path: "/inspectionContent",
            name: "inspectionContent",
            label: "点检模板",
          },
          {
            path: "/inspectionPlan",
            name: "inspectionPlan",
            label: "点检计划",
          },
        
         
          {
            path: "/workOrderM",
            name: "workOrderM",
            label: "工单管理",
          },
          {
            path: "/spotStatistics",
            name: "spotStatistics",
            label: "点检统计",
          },
        ],
      },
      {
        name: "asset",
        label: "资产管理",
        url: "/assets/asset.png",
        children: [
          {
            path: "/assetsM",
            name: "assetsM",
            label: "资产管理",
          },
          // {
          //   path: "/assetsClassifyM",
          //   name: "assetsClassifyM",
          //   label: "资产维护",
          // },
        ],
      },
      {
        name: "efficiency",
        label: "能效管理",
        url: "/assets/efficiency.png",
        children: [
          {
            path: "/energyInspectionItem",
            name: "energyInspectionItem",
            label: "能效点检库",
          },
          // {
          //   path: "/energyInspectionContent",
          //   name: "energyInspectionContent",
          //   label: "能效点检库模板",
          // },
        ],
      },
      {
        name: "management",
        label: "系统管理",
        url: "/assets/management.png",
        children: [
          {
            path: "/acountM",
            name: "acountM",
            label: "用户管理",
          },
          // {
          //   path: "/roleM",
          //   name: "roleM",
          //   label: "角色管理",
          // },
          {
            path: "/gatewayConfig",
            name: "gatewayConfig",
            label: "网关",
          },
          {
            path: "/commConfig",
            name: "commConfig",
            label: "设备",
          },
          {
            path: "/structureEnergyDevice",
            name: "structureEnergyDevice",
            label: "用能配置",
          },
          // {
          //   path: "/paramConfig",
          //   name: "paramConfig",
          //   label: "参数配置",
          // },
          {
            path: "/shiftM",
            name: "shiftM",
            label: "班次管理",
          },
          // {
          //   path: "/strategy",
          //   name: "strategy",
          //   label: "计费标准",
          // },
          // {
          //   path: "/graphicEdit",
          //   name: "graphicEdit",
          //   label: "图形编辑",
          // },
          {
            path: "/spareM",
            name: "spareM",
            label: "备品备件管理",
          },
          {
            path: "/consumableM",
            name: "consumableM",
            label: "耗材管理",
          },
        ],
      },
    ],
    LargeScreendata: true,
    mapdata: [],
  },
  mutations: {
    setActiveIndex(state, newIndex) {
      state.activeIndex = newIndex;
    },
    setSelectedBoxIndex(state, index) {
      state.selectedBoxIndex = index;
    },
    ChangeLargeScreen(state) {
      state.LargeScreendata = !state.LargeScreendata;
    },
    BackLargeScreen(state) {
      state.LargeScreendata = true;
    },
    selectedIndex(state, val) {
      state.selectedBoxIndex = val;
    },
    selectparentmenu(state, val) {
      state.parentName = val;
    },
    //修改菜单展开收起的方法
    collapseMenu(state) {
      state.isCollapse = !state.isCollapse;
      state.asideShow = !state.asideShow;
    },
    //更新面包屑数据
    selectMenu(state, val) {
      //判断添加的数据是否为首页
      const existingItem = state.tabsList.find(
        (item) => item.label === val.label
      );

      // 如果不存在相同名字的项
      if (!existingItem) {
        // 向 tabsList 中加入数据
        state.tabsList.unshift(val);
      }
    },
    updateTabsList(state, tabsList) {
      state.tabsList = tabsList;
    },
    //清空面包屑
    deletMenu(state) {
      state.tabsList = [];
    },
    //删除指定的tag数据
    closeTag(state, item) {
      const index = state.tabsList.findIndex((val) => val.name === item.name);
      state.tabsList.splice(index, 1);
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});
