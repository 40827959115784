<template>
  <div class="layout">
    <Header></Header>
    <Menu></Menu>
    <!-- <Tag></Tag> -->
    <router-view style="position: fixed; left: 10vw"></router-view>
  </div>
</template>

<script>
import Header from "./header/HeaderAside.vue";
import Menu from "./menu/MenuAside.vue";
// import Tag from './tag/Tag.vue'
export default {
  components: {
    Header,
    Menu,
    // Tag
  },
};
</script>

<style lang="scss" scoped>
.layout {
  width: 100vw;
  height: 100vh;
}
</style>
