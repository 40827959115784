import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/login/LoginAside.vue";
import industry from "../views/measure/industry.vue";
import energyFlow from "../views/measure/energyFlow.vue";
import ConsumptionAnalysis from "../views/consumption/energyConsumptionAnalysis.vue";
import classifyAndSub from "../views/consumption/classifyAndSub.vue";
import energyConsumptionRanking from "../views/consumption/ConsumptionRanking.vue";
import carbonQuery from "../views/carbon/carbonQuery.vue";
import carbonAnalysis from "../views/carbon/carbonAnalysis.vue";
import demandAnalysis from "../views/data/demandAnalysis.vue";
import loadAnalysis from "../views/data/loadAnalysis.vue";
import transformerMonitor from "../views/diagnosis/transformerMonitor.vue";
import harmonicMonitoring from "../views/diagnosis/harmonicMonitoring.vue";
import voltageDeviation from "../views/diagnosis/voltageDeviation.vue";
import threePhaseImbalance from "../views/diagnosis/threePhaseImbalance.vue";
import manualfilling from "../views/diagnosis/manualfilling.vue";
import warningReport from "../views/diagnosis/warningReport.vue";
import wattHourMeterRealData from "../views/diagnosis/wattHourMeterRealData.vue";
import asNeededWater from "../views/diagnosis/asNeededWater.vue";
import asNeededEle from "../views/diagnosis/asNeededEle.vue";
import shouye from "../views/measure/shouye.vue";
import Main from "../views/layout/MainLayout.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "main",
    component: Main,
    redirect: "/industry",

    children: [
      {
        path: "/energyFlow",
        name: "energyFlow",
        component: energyFlow,
      },
      {
        path: "/industry",
        name: "industry",
        component: industry,
      },
      {
        path: "/ConsumptionAnalysis",
        name: "ConsumptionAnalysis",
        component: ConsumptionAnalysis,
      },
      {
        path: "/classifyAndSub",
        name: "classifyAndSub",
        component: classifyAndSub,
      },
      {
        path: "/energyConsumptionRanking",
        name: "energyConsumptionRanking",
        component: energyConsumptionRanking,
      },
      {
        path: "/carbonQuery",
        name: "carbonQuery",
        component: carbonQuery,
      },
      {
        path: "/carbonAnalysis",
        name: "carbonAnalysis",
        component: carbonAnalysis,
      },
      {
        path: "/carbonSjlr",
        name: "carbonSjlr",
        component: () => import("../views/carbon/carbonSjlr.vue"),
      },
      {
        path: "/demandAnalysis",
        name: "demandAnalysis",
        component: demandAnalysis,
      },
      {
        path: "/transformerMonitor",
        name: "transformerMonitor",
        component: transformerMonitor,
      },
      {
        path: "/harmonicMonitoring",
        name: "harmonicMonitoring",
        component: harmonicMonitoring,
      },
      {
        path: "/voltageDeviation",
        name: "voltageDeviation",
        component: voltageDeviation,
      },
      {
        path: "/threePhaseImbalance",
        name: "threePhaseImbalance",
        component: threePhaseImbalance,
      },
      {
        path: "/manualfilling",
        name: "manualfilling",
        component: manualfilling,
      },
      {
        path: "/warningReport",
        name: "warningReport",
        component: warningReport,
      },
      {
        path: "/wattHourMeterRealData",
        name: "wattHourMeterRealData",
        component: wattHourMeterRealData,
      },
      {
        path: "/asNeededWater",
        name: "asNeededWater",
        component: asNeededWater,
      },
      {
        path: "/asNeededEle",
        name: "asNeededEle",
        component: asNeededEle,
      },
      {
        path: "/loadAnalysis",
        name: "loadAnalysis",
        component: loadAnalysis,
      },
      {
        path: "/fengPingGu",
        name: "fengPingGu",
        component: () => import("../views/data/fengPingGu.vue"),
      },
      {
        path: "/powerFactorAnalysis",
        name: "powerFactorAnalysis",
        component: () => import("../views/data/powerFactorAnalysis.vue"),
      },
      {
        path: "/comparisondAnalysis",
        name: "comparisondAnalysis",
        component: () => import("../views/data/comparisondAnalysis.vue"),
      },
      {
        path: "/shirtReport",
        name: "shirtReport",
        component: () => import("../views/data/shirtReport.vue"),
      },
      {
        path: "/energySavingStatistics",
        name: "energySavingStatistics",
        component: () => import("../views/data/energySavingStatistics.vue"),
      },
      {
        path: "/tableReport",
        name: "tableReport",
        component: () => import("../views/data/tableReport.vue"),
      },
      {
        path: "/commStatus",
        name: "commStatus",
        component: () => import("../views/diagnosis/commStatus.vue"),
      },
      {
        path: "/waterMeterHistoryData",
        name: "waterMeterHistoryData",
        component: () => import("../views/diagnosis/waterMeterHistoryData.vue"),
      },
      {
        path: "/wattHourMeterHistoryData",
        name: "wattHourMeterHistoryData",
        component: () =>
          import("../views/diagnosis/wattHourMeterHistoryData.vue"),
      },
      {
        path: "/diagramMonitor",
        name: "diagramMonitor",
        component: () => import("../views/diagnosis/diagramMonitor.vue"),
      },
      {
        path: "/oneBoiler",
        name: "oneBoiler",
        component: () => import("../views/diagnosis/oneBoiler.vue"),
      },
      {
        path: "/systemlog",
        name: "systemlog",
        component: () => import("../views/diagnosis/systemlog.vue"),
      },
      {
        path: "/loginLog",
        name: "loginLog",
        component: () => import("../views/diagnosis/loginLog.vue"),
      },
      {
        path: "/inspectionPlan",
        name: "inspectionPlan",
        component: () => import("../views/spotChecks/inspectionPlan.vue"),
      },
      {
        path: "/inspectionContent",
        name: "inspectionContent",
        component: () => import("../views/spotChecks/inspectionContent.vue"),
      },
      {
        path: "/inspectionItem",
        name: "inspectionItem",
        component: () => import("../views/spotChecks/inspectionItem.vue"),
      },
      {
        path: "/workOrderM",
        name: "workOrderM",
        component: () => import("../views/spotChecks/workOrderM.vue"),
      },
      {
        path: "/spotStatistics",
        name: "spotStatistics",
        component: () => import("../views/spotChecks/spotStatistics.vue"),
      },
      {
        path: "/assetsM",
        name: "assetsM",
        component: () => import("../views/asset/assetsM.vue"),
      },
      {
        path: "/editAssets",
        name: "editAssets",
        component: () => import("../views/asset/editAssets.vue"),
      },
      {
        path: "/assetsClassifyM",
        name: "assetsClassifyM",
        component: () => import("../views/asset/assetsClassifyM.vue"),
      },
      {
        path: "/energyInspectionItem",
        name: "energyInspectionItem",
        component: () => import("../views/efficiency/energyInspectionItem.vue"),
      },
      {
        path: "/energyInspectionContent",
        name: "energyInspectionContent",
        component: () =>
          import("../views/efficiency/energyInspectionContent.vue"),
      },
      {
        path: "/acountM",
        name: "acountM",
        component: () => import("../views/management/acountM.vue"),
      },
      {
        path: "/addAccount",
        name: "addAccount",
        component: () => import("../views/management/addAccount.vue"),
      },
      {
        path: "/addRole",
        name: "addRole",
        component: () => import("../views/management/addRole.vue"),
      },
      {
        path: "/commConfig",
        name: "commConfig",
        component: () => import("../views/management/commConfig.vue"),
      },
      {
        path: "/consumableM",
        name: "consumableM",
        component: () => import("../views/management/consumableM.vue"),
      },
      {
        path: "/equipmentReport",
        name: "equipmentReport",
        component: () => import("../views/management/equipmentReport.vue"),
      },
      {
        path: "/event",
        name: "event",
        component: () => import("../views/management/event.vue"),
      },
      {
        path: "/gatewayConfig",
        name: "gatewayConfig",
        component: () => import("../views/management/gatewayConfig.vue"),
      },
      {
        path: "/graphicEdit",
        name: "graphicEdit",
        component: () => import("../views/management/graphicEdit.vue"),
      },
      {
        path: "/paramConfig",
        name: "paramConfig",
        component: () => import("../views/management/paramConfig.vue"),
      },
      {
        path: "/roleM",
        name: "roleM",
        component: () => import("../views/management/roleM.vue"),
      },
      {
        path: "/shiftM",
        name: "shiftM",
        component: () => import("../views/management/shiftM.vue"),
      },
      {
        path: "/structureEnergyDevice",
        name: "structureEnergyDevice",
        component: () =>
          import("../views/management/structureEnergyDevice.vue"),
      },
      {
        path: "/spareM",
        name: "spareM",
        component: () => import("../views/management/spareM.vue"),
      },
      {
        path: "/strategy",
        name: "strategy",
        component: () => import("../views/management/strategy.vue"),
      },
      {
        path: "/onRouter",
        name: "onRouter",
        component: () => import("../views/measure/onRouter.vue"),
      },
    ],
  },
  {
    path: "/shouye",
    name: "shouye",
    component: shouye,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(to);
  // 判断 token 是否存在
  const token = localStorage.getItem("token");
  // 判断是否是刷新页面
  // const isRefresh = !from.name;

  // token 不存在，说明当前用户未登录，应该跳转至登录页
  if (!token && to.path !== "/login") {
    if (to.name === "login") {
      // 如果要访问的是登录页或注册页，则放行
      next();
    } else {
      // 其他情况重定向到登录页
      next({ name: "login" });
    }
  } else if (token) {
    // token 存在，说明用户已登录
    if (to.path === "/login") {
      // 如果要访问的是登录页，跳转至首页
      next({ path: "/industry" });
    } else {
      next();
    }
  }
  else {
    next();
  }
});
export default router;
