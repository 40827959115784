<template>
  <div class="neng">
    <div class="re">
      <div class="right" :style="{ width: isExpanded ? '79vw' : '89vw' }">
        <div class="topp">
          <div class="data">
            <el-form ref="form" :model="form" label-width="90px">
              <el-form-item label="发生时间：" id="fas">
                <el-date-picker
                  v-model="dateRange"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleDateChange"
                >
                </el-date-picker>
              </el-form-item>

              <!-- <el-form-item label="报警状态：" id="huodong">
                <el-select v-model="form.region" placeholder="全部">
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item> -->
            </el-form>
          </div>
        </div>
        <div class="bot0">
          <div><OneTable :data="data01"></OneTable></div>
          <div><TwoTable :data="data02"></TwoTable></div>
          <div><ThreeTable :data="data03"></ThreeTable></div>
        </div>
        <div class="bottom">
          <el-table
            :header-cell-style="{ background: '#ebeef5' }"
            :stripe="true"
            :fit="true"
            :data="tableData"
            height="400"
            border
            style="width: 100%"
          >
            <el-table-column prop="date" label="#"> </el-table-column>
            <el-table-column prop="name" label="等级"> </el-table-column>
            <el-table-column prop="address" label="报警内容"> </el-table-column>
            <el-table-column prop="dian" label="发生时间"> </el-table-column>
            <el-table-column prop="dian05" label="恢复时间"> </el-table-column>
            <el-table-column prop="dian05" label="报警类型"> </el-table-column>
            <el-table-column prop="dian05" label="来源设备"> </el-table-column>
            <el-table-column prop="dian05" label="所属架构"> </el-table-column>
            <el-table-column prop="dian05" label="确认人"> </el-table-column>
            <el-table-column prop="dian05" label="确认时间"> </el-table-column>
            <el-table-column fixed="right" label="操作" width="100">
              <template>
                <el-button @click="open" type="text" size="small"
                  >填报</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import OneTable from "../../components/OneTable.vue";
import TwoTable from "../../components/TwoTable.vue";
import ThreeTable from "../../components/ThreeTable.vue";
import getTodayTimeArray from "@/utils/time.js";
import request from "@/request/request";
import { getDate } from "date-fns";
export default {
  components: {
    OneTable,
    TwoTable,
    ThreeTable,
  },


  data() {
    return {
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      dateRange: "",
      data01: [],
      data02: [],
      data03: [],
      ruleForm: {
        name: "0.38",
      },
      radio3: "月",
      radio03: "电",
      value: "2024-1-30",
      value2: "2024-1",
      tableData: [],
      activeName: "second",
      isExpanded: false,
      data: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      beginTime: "",
      endTime: "",
    };
  },
  methods: {
    async handleDateChange(value) {
      // value 是一个数组，包含开始日期和结束日期

      const formattedDates = value.map((date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始，需要加1
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
      });
      const res = await request({
        url: "/diagnosisDiagnosis/levelStatistics",
        params: {
          page: 1,
          size: 1,
          beginTime: formattedDates[0],
          endTime: formattedDates[1],
          status: 0,
        },
      });
      this.data01 = res.data.content;
      
      const res1 = await request({
        url: "/diagnosisDiagnosis/typeStatistics",
        params: {
          page: 1,
          size: 1,
          beginTime: formattedDates[0],
          endTime: formattedDates[1],
          status: 0,
        },
      });
      this.data02 = res1.data.content;
      const res2 = await request({
        url: "/diagnosisDiagnosis/statusStatistics",
        params: {
          page: 1,
          size: 1,
          beginTime: formattedDates[0],
          endTime: formattedDates[1],
          status: 0,
        },
      });
      this.data03 = res2.data.content;
      console.log("选取的日期范围：", formattedDates);
      // 在这里可以进行相应的处理
    },
    async getLevel() {
      const res = await request({
        url: "/diagnosisDiagnosis/levelStatistics",
        params: {
          page: 1,
          size: 1,
          beginTime: this.beginTime,
          endTime: this.endTime,
          status: 0,
        },
      });
      this.data01 = res.data.content;
      console.log(res.data.content);
    },
    async getType() {
      const res = await request({
        url: "/diagnosisDiagnosis/typeStatistics",
        params: {
          page: 1,
          size: 1,
          beginTime: getTodayTimeArray()[0],
          endTime: getTodayTimeArray()[1],
          status: 0,
        },
      });
      this.data02 = res.data.content;
    },
    async getStatus() {
      const res = await request({
        url: "/diagnosisDiagnosis/statusStatistics",
        params: {
          page: 1,
          size: 1,
          beginTime: getTodayTimeArray()[0],
          endTime: getTodayTimeArray()[1],
          status: 0,
        },
      });
      this.data03 = res.data.content;
    },
    open() {
      this.$prompt("请输入邮箱", "人工填报", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern:
          /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        inputErrorMessage: "邮箱格式不正确",
      })
        .then(({ value }) => {
          this.$message({
            type: "success",
            message: "你的邮箱是: " + value,
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    async getData() {
      const res = await request({
        url: "/diagnosisDiagnosis/alarmList",
        params: {
          page: 1,
          size: 1,
          beginTime: getTodayTimeArray()[0],
          endTime: getTodayTimeArray()[1],
          status: 0,
        },
      });
      this.tableData = res.data.content.list
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    format(percentage) {
      return percentage === 100 ? "满" : `${percentage}%`;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    toggleSidebar() {
      this.isExpanded = !this.isExpanded;
    },
  },
  mounted() {
    this.beginTime = getTodayTimeArray()[0];
    this.endTime = getTodayTimeArray()[1];
    console.log(getTodayTimeArray());
    this.getLevel();
    this.getType();
    this.getStatus();
  },
};
</script>
  
  <style lang="scss" scoped>
#huodong {
  display: inline-block !important;
}
.zd {
  margin-top: 3px;
}
#cgx {
  margin-top: 1.2%;
  margin-left: 1%;
}
.data {
  display: flex;
  align-items: center;
  margin-left: 8px;
  padding-top: 1%;
}
#fas {
  display: inline-block !important;
}
.topp {
  width: 99%;
  vertical-align: middle;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 1.5%;
  border-radius: 3px;
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.3);
  height: 10vh;
  background-color: #fff;
}
.vv {
  color: #07ccfa;
  font-size: 1.4vw;
  font-weight: 800;
}
.topp02 {
  vertical-align: middle;
  width: 99%;
}
.topp03 {
  border-radius: 2px;
  margin-left: 0.5%;
  position: relative;
  display: inline-block;
  width: 32.8%;
  background-color: #fff;
  height: 10vh;
  margin-top: 1%;
  img {
    margin-left: 5%;
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  div {
    position: absolute;
    top: 20px;
    display: inline-block;
  }
}
#main {
  width: 100%;
  height: 50vh;
}
.elta {
  margin-left: 1%;
  margin-right: 1%;
}
.bot0 {
  display: flex;
  height: 30vh;
  width: 99%;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 1.5%;
  border-radius: 3px;
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.3);
  padding-top: 1%;
  div {
    width: 33.3%;
    height: 100%;
    background-color: #fff;
  }
}
.bottom {
  height: 50vh;
  width: 99%;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 1.5%;
  border-radius: 3px;
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.3);
  padding-top: 1%;
}
.el-table {
  padding: 1%;
  padding-top: 0;
}
.max02 {
  color: red;
}
.min02 {
  color: #658e63;
}
.bdy01 {
  width: 100%;
  margin: 3vw auto;

  margin-left: 3vw;
}

.kw {
  font-size: 0.8vw;
  font-weight: 400;
}
.fzl {
  font-size: 1vw;
}
.fz {
  color: #02ae4e;
}
.r05 {
  color: #02ae4e;
  font-size: 1.2vw;
  font-weight: 600;
}

::v-deep .el-progress-bar__outer {
  height: 20px !important;
}
.el-progress {
  width: 65%;
  display: inline-block;
}
.shiyong {
  color: #67c23a;
}
.re {
  width: 100%;
  height: 90vh;
  overflow-y: auto;
  background-color: #f1f4f5;
}
.neng {
  display: flex;
}
.right {
  background-color: #f1f4f5;
  width: 100%;
}
.top01 {
  p {
    display: inline-block;
    margin-top: 5%;
    margin-left: 2%;
  }
}
.sidebar {
  width: 2%;
  height: 100vh;
  background-color: #cfd3dc;
  transition: width 0.3s ease;
}

.sidebar-expanded {
  width: 12%;
}

.toggle-button {
  position: fixed;
  top: 50%;
  transform: translateY(0);
  left: 0.5%;
  width: 1.8vw;
  height: 2vw;
  background-color: #ccc;
  border: none;
  cursor: pointer;
  transition: transform 0.08s ease;
}
.el-tree {
  background-color: #cfd3dc;
}

.button-expanded {
  transform: translateX(401%);
}

::v-deep .el-input--suffix .el-input__inner {
  padding-left: 1.8rem !important;
  padding-right: 1.8rem !important;
}
::v-deep .el-input__inner {
  width: 10rem;
  border-radius: 0.25rem;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 0.9rem;
  font-size: 0.8rem;
}
::v-deep .el-input {
  font-size: 0.8rem;
}
::v-deep .el-input__icon {
  width: 1.5rem;
  font-size: 0.8rem !important;
  line-height: 2.5rem;
}
.el-date-editor .el-range__icon {
  font-size: 0.8rem !important;
  line-height: 2.5rem;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 10rem;
}
::v-deep .el-date-editor .el-range-input {
  font-size: 0.6rem;
}
::v-deep .el-date-editor .el-range-separator {
  font-size: 0.8rem;
  line-height: 1.9rem;
}
::v-deep .el-input--suffix .el-input__inner {
  padding-left: 1.8rem !important;
  padding-right: 1.8rem !important;
}
::v-deep .el-input__inner {
  width: 10rem;
  border-radius: 0.25rem;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 0.9rem;
  font-size: 0.8rem;
}
::v-deep .el-input {
  font-size: 0.8rem;
}
::v-deep .el-input__icon {
  width: 1.5rem;
  font-size: 0.8rem !important;
  line-height: 2.5rem;
}
.el-date-editor .el-range__icon {
  font-size: 0.8rem !important;
  line-height: 2.5rem;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 13rem;
}
::v-deep .el-date-editor .el-range-input {
  font-size: 0.6rem;
}
::v-deep .el-date-editor .el-range__icon {
  font-size: 0.8rem;
  line-height: 1.9rem;
}
::v-deep .el-table {
  font-size: 0.8rem !important;
}

::v-deep .el-tree-node__label {
  font-size: 0.7rem;
}

::v-deep .el-input__inner {
  font-size: 0.8rem;
}
.el-button--primary {
  width: 3.5rem;
  height: 1.6rem;
  padding: 0.1rem;
  font-size: 0.8rem;
}
.el-button--small {
  width: 3.5rem;
  height: 1.6rem;
  padding: 0.1rem;
  font-size: 0.8rem;
}

::v-deep .el-radio-button--small .el-radio-button__inner {
  padding: 0.5rem;
  font-size: 0.8rem;
  border-radius: 0;
}

::v-deep .el-form--inline .el-form-item {
  display: inline-block;
  margin-right: 0.5rem;
  vertical-align: top;
}

::v-deep .el-form-item__label {
  font-size: 0.8rem;
}
</style>