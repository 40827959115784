<template>
  <div class="neng" style="display: flex; width: 100vw">
    <Tree
      @transmit="getMessage"
      :name="transformerName"
      :checked="false"
      :data="data"
      @dataId="getId"
    ></Tree>
    <!-- <div class="sidebar" :class="{ 'sidebar-expanded': isExpanded }">
      
      <div class="top01" v-show="isExpanded == true">
        <p>能源流图</p>
        <el-input placeholder="输入关键字进行过滤" v-model="filterText">
        </el-input>

        <el-tree
          class="filter-tree"
          :data="data"
          :props="defaultProps"
          default-expand-all
          :filter-node-method="filterNode"
          ref="tree"
        >
        </el-tree>
      </div>
    </div> -->
    <div class="re">
      <div class="right" :style="{ width: isExpanded ? '78vw' : '87vw' }" v-if="rawData.asset">
        <div class="right01">
          <div class="right02">
            <span >{{ rawData.asset.name }}</span>
          </div>
          <div class="right03">
            <div class="right04">
              <p>
                <span class="span01">{{ rawData.asset.name }}</span
                ><span> {{ rawData.asset.code }}</span>
              </p>
              <div class="right0401">
                <div class="left01">
                  <span>品牌：</span
                  ><span>{{ rawData.asset.maker }}<br /></span>
                  <p>
                    类型：<span>{{ rawData.asset.transtormerType }}</span>
                  </p>
                  <p>
                    状态：<span class="shiyong">{{
                      rawData.asset.state == 1 ? "使用" : "未使用"
                    }}</span>
                  </p>
                </div>
                <div class="left02">
                  <span>额定容量：</span
                  ><span>{{ rawData.asset.ratedCapacity }}</span
                  ><br /><br />
                  <p>
                    额定电流：<span>{{ rawData.asset.ratedElectricity }}</span>
                  </p>
                  <p>
                    额定相电压：<span>{{
                      rawData.asset.ratedPhaseVoltage
                    }}</span>
                  </p>
                </div>
                <div class="left03">
                  <span>购入时间：</span><span>{{ rawData.asset.buyTime }}</span
                  ><br /><br />
                  <p>
                    投运时间：<span>{{ rawData.asset.useTime }}</span>
                  </p>
                  <p>
                    额定相电压：<span>{{
                      rawData.asset.ratedLineVoltage
                    }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="right05">
              <div class="right0501">
                <span class="r05"
                  ><span>{{ rawData.load.real }}</span
                  ><span class="kw">kW</span></span
                ><el-progress
                  define-back-color="#4755ff"
                  :percentage="rawData.load.realLoadRate"
                  :show-text="false"
                  color="#02ae55"
                ></el-progress
                ><br />
                <span>实时负荷</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                  class="fzl"
                  >负载率：</span
                ><span class="fz">{{ rawData.load.realLoadRate }}</span>
              </div>
              <div class="right0502">
                <span class="r05"
                  ><span>{{ rawData.load.dayMax }}</span
                  ><span class="kw">kW</span></span
                ><el-progress
                  define-back-color="#4755ff"
                  :percentage="rawData.load.dayMaxLoadRate"
                  :show-text="false"
                  color="#02ae55"
                ></el-progress
                ><br />
                <span>实时负荷</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                  class="fzl"
                  >负载率：</span
                ><span class="fz">{{ rawData.load.dayMaxLoadRate }}</span>
              </div>
              <div class="right0503">
                <span class="r05"
                  ><span>{{ rawData.load.monthMax }}</span
                  ><span class="kw">kW</span></span
                ><el-progress
                  define-back-color="#4755ff"
                  :percentage="rawData.load.monthMaxLoadRate"
                  :show-text="false"
                  color="#02ae55"
                ></el-progress
                ><br />
                <span>实时负荷</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                  class="fzl"
                  >负载率：</span
                ><span class="fz">{{ rawData.load.monthMaxLoadRate }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="center" v-if="rawData != []">
          <div>
            <el-radio-group v-model="radio3" style="margin-bottom: 10px">
              <el-radio-button label="相电压"></el-radio-button>
              <!-- <el-radio-button label="线电压"></el-radio-button> -->
              <el-radio-button label="电流"></el-radio-button>
              <!-- <el-radio-button label="频率"></el-radio-button> -->
              <el-radio-button label="有功功率"></el-radio-button>
              <el-radio-button label="无功功率"></el-radio-button>
              <el-radio-button label="视在功率"></el-radio-button>
              <el-radio-button label="功率因数"></el-radio-button>
              <!-- <el-radio-button label="有功电能"></el-radio-button>
              <el-radio-button label="无功电能"></el-radio-button>
              <el-radio-button label="谐波监测"></el-radio-button> -->
            </el-radio-group>
            <div class="center01" v-if="radio3 === '相电压'">
              <TableNine :data="rawData.rateCharArr[0]"></TableNine>
              <TableNine :data="rawData.rateCharArr[1]"></TableNine>
              <TableNine :data="rawData.rateCharArr[2]"></TableNine>
            </div>
            <!-- <div class="center01" v-if="radio3 === '线电压'">
              <TableNine  :data="rawData.rateCharArr[0]"></TableNine>
              <TableNine  :data="rawData.rateCharArr[1]"></TableNine>
              <TableNine  :data="rawData.rateCharArrp[2]"></TableNine>
            </div> -->
            <div class="center01" v-if="radio3 === '电流'">
              <TableNine :data="rawData.rateCharArr[0]"></TableNine>
              <TableNine :data="rawData.rateCharArr[1]"></TableNine>
              <TableNine :data="rawData.rateCharArr[2]"></TableNine>
            </div>
            <!-- <div class="center01" v-if="radio3 === '频率'">
              <TableNine  :data="rawData.rateCharArr"></TableNine>
            </div> -->
            <div class="center01" v-if="radio3 === '有功功率'">
              <TableNine :data="rawData.rateCharArr[0]"></TableNine>
              <TableNine :data="rawData.rateCharArr[1]"></TableNine>
              <TableNine :data="rawData.rateCharArr[2]"></TableNine>
            </div>
            <div class="center01" v-if="radio3 === '无功功率'">
              <TableNine :data="rawData.rateCharArr[0]"></TableNine>
              <TableNine :data="rawData.rateCharArr[1]"></TableNine>
              <TableNine :data="rawData.rateCharArr[2]"></TableNine>
            </div>
            <div class="center01" v-if="radio3 === '视在功率'">
              <TableNine :data="rawData.rateCharArr[0]"></TableNine>
              <TableNine :data="rawData.rateCharArr[1]"></TableNine>
              <TableNine :data="rawData.rateCharArr[2]"></TableNine>
            </div>

            <div class="center01" v-if="radio3 === '功率因数'">
              <TableNine :data="rawData.rateCharArr[0]"></TableNine>
              <TableNine :data="rawData.rateCharArr[1]"></TableNine>
              <TableNine :data="rawData.rateCharArr[2]"></TableNine>
            </div>
            <!-- <div class="center01" v-if="radio3 === '有功电能'">
              <TableNine  :data="rawData.rateCharArr"></TableNine>
              <TableNine  :data="rawData.rateCharArr"></TableNine>
            </div> -->
          </div>
        </div>
        <div class="bottom">
          <el-tabs v-model="activeName" @tab-click="handleClick" class="elta">
            <el-tab-pane label="表格" name="first">
              <el-table
                :header-cell-style="{ background: '#ebeef5' }"
                :stripe="true"
                :fit="true"
                :data="tableData"
                height="350"
                border
                style="width: 100%"
              >
                <el-table-column prop="time" label="时间"> </el-table-column>
                <el-table-column prop="a" :label="name[0]"> </el-table-column>
                <el-table-column prop="b" :label="name[1]"> </el-table-column>
                <el-table-column prop="c" :label="name[2]"> </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="图表" name="second">
              <div id="main"></div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <!-- <button
      class="toggle-button"
      @click="toggleSidebar"
      :class="{ 'button-expanded': isExpanded }"
    >
    
      <i :class="isExpanded ? 'el-icon-s-fold' : 'el-icon-s-unfold'"></i>
    </button> -->
  </div>
</template>
  
  <script>
import * as echarts from "echarts";
import Tree from "../../components/tree/leftTree.vue";
import TableNine from "../../components/TableNine.vue";
import request from "@/request/request";
import { getDate } from "date-fns";

export default {
  components: {
    TableNine,
    Tree,
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    radio3(newVal, oldVal) {
      // 在这里执行相应的操作
      console.log("radio3 变化了：", newVal);
      if (newVal == "相电压") {
        this.type = 1;
        this.getData();
      } else if (newVal == "电流") {
        this.type = 2;
        this.getData();
      } else if (newVal == "有功功率") {
        this.type = 3;
        this.getData();
      } else if (newVal == "无功功率") {
        this.type = 4;
        this.getData();
      } else if (newVal == "视在功率") {
        this.type = 5;
        this.getData();
      } else if (newVal == "功率因数") {
        this.type = 6;
        this.getData();
      }
      // 这里可以添加你想要执行的代码，例如更新数据、调用函数等
    },
  },

  data() {
    return {
      
      transformerName: "变压器",
      tableData: [],
      activeName: "first",
      radio3: "相电压",
      isExpanded: true,
      filterText: "",
      data: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      type: 1,
      eqId: 11,
      rawData: [],
      extractedValues: [],
      name: [],
      dates: [],
    };
  },
  methods: {
    async getData() {
      const res = await request({
        url: "/diagnosisDiagnosis/voltage-data",
        params: {
          date: this.getTodayDate(),
          type: this.type,
          ids: this.eqId,
        },
      });
      this.rawData = res.data.content;
      // console.log(this.rawData,88888888);
      console.log(res.data.content);
      this.name = res.data.content.data.map((x) => x.name);
      this.extractedValues = res.data.content.data.map((item) => {
        const values = item.data.map((obj) => {
          // 获取对象中的值
          return Object.values(obj)[0];
        });
        return values;
      });
      this.dates = res.data.content.data.map((x) =>
        x.data.map((entry) => Object.keys(entry)[0])
      );

      this.tableData = this.dates[0].map((time, index) => ({
        time,
        a: this.extractedValues[0][index],
        b: this.extractedValues[1][index],
        c: this.extractedValues[2][index],
        // 设置默认的名称
      }));
    },
    async getId(data) {
      console.log(data);
      this.eqId = data;
      this.getData();
    },
    async getShebei() {
      const res = await request({
        url: "/dataAnalysis/listEquipment",
        method: "get",
        params: {},
      });
      // = res.data.content;
      // console.log(res.data.content, "98989898998989");
      // this.gz = res.data.content;
      // console.log(this.gz, "gzgzgzgzgzgz");
      // for (const item of this.gz) {
      //   if (item.type === 1) {
      //     this.typeOneIds.push(item.id);
      //   }
      //   // console.log(this.typeOneIds, "qaaaaaaaaaaaaaaaaaaaaaaaa");
      // }
      console.log(res.data.content);
      this.data = this.transformData(res.data.content);
      // console.log(this.data);
    },
    transformData(data, pid = 0) {
      if (!data) {
        return []; // 如果data为null或undefined，则返回一个空数组
      }
      const result = [];
      for (const node of data) {
        if (node.pid === pid) {
          const newNode = {
            id: node.stationId,
            label: node.name,
            children: this.transformData(data, node.id),
          };
          result.push(newNode);
        }
      }
      return result;
    },

    async getArea() {
      const res = await request("/area/getAreaList");
      this.data = this.transformData(res.data.content);
      console.log(res.data.content);
    },
    getMessage(data) {
      this.isExpanded = data;
    },
    tu02() {
      var chartDom = document.getElementById("main");
      console.log(chartDom, 5555555555555555);
      var myChart = echarts.init(chartDom);

      var option;

      option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: this.name,
        },
        toolbox: {
          show: true,
          feature: {
            dataView: { show: false, readOnly: false },
            magicType: { show: true, type: ["line", "bar"] },
            restore: { show: false },
            saveAsImage: { show: false },
          },
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            // prettier-ignore
            data: [
    '00:00', '01:00', '02:00', '03:00', '04:00', '05:00',
    '06:00', '07:00', '08:00', '09:00', '10:00', '11:00',
    '12:00', '13:00', '14:00', '15:00', '16:00', '17:00',
    '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'
  ],
          },
        ],
        yAxis: [
          {
            type: "value",
            min: -15,
            max: 15, // 设置y轴范围为0到15
          },
        ],
        series: [
          {
            name: this.name[0],
            type: "bar",
            data: this.extractedValues[0],

            markLine: {
              data: [
                { yAxis: 7, name: "Average", lineStyle: { color: "red" } },
              ],
            },
          },
          {
            name: this.name[1],
            type: "bar",
            data: this.extractedValues[1],

            markLine: {
              data: [
                { yAxis: 0, name: "Average", lineStyle: { color: "red" } },
              ],
            },
          },
          {
            name: this.name[2],
            type: "bar",
            data: this.extractedValues[2],

            markLine: {
              data: [
                { yAxis: -7, name: "Average", lineStyle: { color: "red" } },
              ],
            },
          },
          // {
          //   name: "P06_1#主变进线柜.合相有功功率kW",
          //   type: "bar",
          //   data: [
          //     2.6, 5.9, 9.0, 6.4, 8.7, 7.7, 5.6, 2.2, 8.7, 12.8, 6.0, 2.3, 2.0,
          //     4.9, 7.0, 3.2, 5.6, 6.7, 15.0, 6.2, 2.6, 12.0, 6.4, 3.3,
          //   ],
          // },
        ],
      };

      option && myChart.setOption(option);
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    format(percentage) {
      return percentage === 100 ? "满" : `${percentage}%`;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    toggleSidebar() {
      this.isExpanded = !this.isExpanded;
    },
    getTodayDate() {
      var today = new Date(); // 创建一个表示当前日期和时间的 Date 对象
      var year = today.getFullYear(); // 获取当前年份
      var month = today.getMonth() + 1; // 获取当前月份，注意月份从0开始计数，所以需要加1
      var day = today.getDate(); // 获取当前日期

      // 格式化月份和日期，确保它们以两位数形式显示
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;

      // 返回格式化后的日期字符串，格式为 YYYY-MM-DD
      return year + "-" + month + "-" + day;
    },

    // 调用函数并输出当天日期
  },
  mounted() {
    this.getShebei();
    this.getData();
    this.$watch("activeName", (newVal, oldVal) => {
      if (newVal === "second") {
        this.tu02();
      }
    });
  },
};
</script>
  
  <style lang="scss" scoped>
::v-deep .el-tree-node__label {
  font-size: 0.7rem;
}
::v-deep .el-table {
  font-size: 0.8rem !important;
}
::v-deep .el-input__inner {
  font-size: 0.8rem;
}
::v-deep .el-radio-button__inner {
  font-size: 0.8rem;
}
#main {
  width: 70vw;
  height: 50vh;
}
.elta {
  margin-left: 1%;
  margin-right: 1%;
}
.bottom {
  width: 99%;

  background-color: #fff;
  margin: 0 auto;
  margin-top: 1.5%;
  border-radius: 3px;
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.3);
}
.max02 {
  color: red;
}
.min02 {
  color: #658e63;
}
.bdy01 {
  width: 100%;
  margin: 3vw auto;

  margin-left: 3vw;
}
.center {
  width: 99%;
  height: 40vh;
  background-color: #fff;
  margin: 0 auto;
  border-radius: 3px;
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.3);
  .center01 {
    display: flex;
    width: 100%;
    height: 34vh;
    background-color: #fff;
  }
}
.kw {
  font-size: 0.8vw;
  font-weight: 400;
}
.fzl {
  font-size: 1vw;
}
.fz {
  color: #02ae4e;
}
.r05 {
  color: #02ae4e;
  font-size: 1.2vw;
  font-weight: 600;
}
.right0503,
.right0502,
.right0501 {
  width: 95%;
  margin: 0 auto;
  padding-left: 5%;
  margin-top: 2.5%;
}
::v-deep .el-progress-bar__outer {
  height: 20px !important;
}
.el-progress {
  width: 65%;
  display: inline-block;
}
.shiyong {
  color: #67c23a;
}
.re {
  width: 100%;
  height: 90vh;
  overflow-y: auto;

  background-color: #f1f4f5;
}
.neng {
  display: flex;
  width: 70vw;
}
.right {
  background-color: #f1f4f5;
  width: 80%;
}
.top01 {
  p {
    display: inline-block;
    margin-top: 5%;
    margin-left: 2%;
  }
}
.sidebar {
  width: 2%;
  height: 100vh;
  background-color: #cfd3dc;
  transition: width 0.3s ease;
}

.sidebar-expanded {
  width: 12%;
}

.toggle-button {
  position: fixed;
  top: 50%;
  transform: translateY(0);
  left: 10%;
  width: 1.8vw;
  height: 2vw;
  background-color: #cfd3dc;
  border: none;
  cursor: pointer;
  transition: transform 0.01s ease;
}
.el-tree {
  background-color: #cfd3dc;
}

.button-expanded {
  transform: translateX(490%);
}
.right01 {
  width: 99%;
  height: 37vh;
  margin: 1% auto;
  background-color: #fff;
  .right02 {
    width: 100%;
    height: 22%;
    background-color: #fff;

    span {
      margin-top: 1%;
      display: inline-block;
      padding-left: 2%;
      font-size: 1vw;
      border-left: 1vh solid #409eff;
    }
  }
  .right03 {
    width: 100%;
    height: 77%;
    background-color: #f4f4f4;
    display: flex;
    justify-content: space-between;
    .right04 {
      width: 59.9%;
      height: 100%;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #f1f4f5;
      box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.3);
      .span01 {
        font-weight: 600;
        font-size: 1vw;
        margin-left: 1%;
      }
      .right0401 {
        display: flex;
        width: 95%;
        height: 75%;
        margin: 0 auto;
        justify-content: space-between;
        background-color: #fff;
        .left02,
        .left03,
        .left01 {
          width: 33%;
          height: 100%;
          background-color: #fff;
          font-size: 1vw;
        }
      }
    }
    .right05 {
      width: 39.5%;
      height: 100%;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #f1f4f5;
      box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.3);
    }
  }
}
.center01 div:first-child {
  border: none !important;
}
::v-deep .el-tabs__item {
  padding: 0 1.2rem;
  height: 2.2rem;
  box-sizing: border-box;
  line-height: 2.2rem;
  font-size: 0.7rem;
}
</style>