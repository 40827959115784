<template>
  <el-card style="background-color: transparent; border: none">
    <div ref="chart" style="width: 300%; height: 160%"></div>
  </el-card>
</template>
<script>
import * as echarts from "echarts";
import request from "@/request/request";
export default {
  name: "TableTwo",
  data() {
    return {
      data:[
         {
          value: 10,
          name: "重要警告",
          progress: 10,
        },
        {
          value: 30,
          name: "消息",
          progress: 30,
        },
        {
          value: 20,
          name: "预警",
          progress: 20,
        },
        {
          value: 40,
          name: "预警",
          progress: 40,
        },
      ]
    };
  },
  methods: {
    async getData(){
      const res = await request('/warn-category/query-all')
   this.data.map((x,index)=>{
        x.name = res.data.content[index].name
      })
      console.log(this.data);

    this.initChart();
    },
    initChart() {
      const chartContainer = this.$refs.chart;
     
      // 使用 echarts.init 初始化图表
      const chart = echarts.init(chartContainer);
      var echartData = this.data

      var color = [
        [
          { offset: 0, color: "#FF8B55" },
          { offset: 1, color: "#FF8B55" },
        ],
        [
          { offset: 0, color: "#6DBEFF" },
          { offset: 1, color: "#6DBEFF" },
        ],
        [
          { offset: 0, color: "#28F2E7" },
          { offset: 1, color: "#28F2E7" },
        ],
        [
          { offset: 0, color: "#fbd74b" },
          { offset: 1, color: "#fbd74b" },
        ],
      ];
      echartData.forEach((item, index) => {
        item.itemStyle = {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, color[index]),
        };
      });
      var option;
      option = {
        color,

        series: [
          {
            type: "pie",
            minAngle: 5,
            startAngle: 10, //起始角度
            labelLine: {
              show: true,
              length: 20,
              length2: 10,
              lineStyle: {
                width: 2,
              },
            },
            label: {
              formatter: (params) => {
                const name = params.name;
                return `{f${params.dataIndex + 1}|${params.data.progress
                  }%}\n{t|${name + " " + params.value}}`;
              },
              rich: {
                t: {
                  color: "#FFF",
                  lineHeight: 20,
                  fontSize: '0.8rem',
                  align: "left",
                },
                f1: {
                  color: "#FF8B55",
                  fontSize: '0.8rem',
                  align: "center",
                  lineHeight: 28,
                },
                f2: {
                  color: "#6DBEFF",
                  fontSize: '0.8rem',
                  align: "center",
                  lineHeight: 28,
                },
                f3: {
                  color: "#28F2E7",
                  fontSize: '0.8rem',
                  align: "center",
                  lineHeight: 28,
                },
              },
            },
            center: ["50%", "50%"],
            radius: ["20%", "30%"],
            data: echartData,
          },
        ],
      };
      // 使用 setOption 方法设置图表配置项
      chart.setOption(option);
      window.addEventListener("resize", function () {
        chart.resize();
      });
    },
  },
  mounted() {
    // 在组件挂载后初始化和绘制图表
    this.getData();

  },
};
</script>
<style>

</style>