<template>
  <el-card style="background-color: transparent; border: none">
    <div ref="chart" style="width: 100%; height: 200%"></div>
  </el-card>
</template>
<script>
import * as echarts from "echarts";
import request from "@/request/request";
export default {
  name: "TableFour",
  data() {
    return {
      items: [],
    };
  },
  methods: {
    async getData() {
      const res = await request("/area/getHomeAreaData");
      res.data.content.map((x, index) => {
        x.id = index + 1;
      });
      // 将前六条数据复制到数组末尾
      this.items = res.data.content.map(x => ({
        name: x.areaName,
        value: parseFloat(x.power) // 将 power 转换为数字类型
      }));
      this.initChart();
    },
    initChart() {
      const chartContainer = this.$refs.chart;

      // 使用 echarts.init 初始化图表
      const chart = echarts.init(chartContainer);
      var option;
      option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "3%",
          left: "75%",
          textStyle: {
            fontSize: "0.8rem",
            color: "white", // 设置图例文字颜色为白色
          },
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold",
                color: "white",
              },
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              normal: {
                //饼状图阴影，值越大阴影亮度越高
                shadowBlur: 200,
                shadowColor: "#FFA07A",
              },
            },
            data: this.items,
          },
        ],
      };
      // 使用 setOption 方法设置图表配置项
      chart.setOption(option);
      window.addEventListener("resize", function () {
        chart.resize();
      });
    },
  },
  mounted() {
    // 在组件挂载后初始化和绘制图表
    this.getData();
    console.log(this.items);
  },
};
</script>