<template>
  <div class="home" ref="draggableElement">
    <div class="header">
      <span class="header01">山东华莱智科智慧综合能源管理平台</span>
      <div class="header02" @click="onClick"></div>
    </div>
    <div class="center">
      <div class="center01">
        <div class="center0101">
          <div class="lefttop01">
            <span class="span01">用能统计</span>
          </div>
          <div class="lefttop02">
            <div class="leftt01"></div>
            <div class="leftt02">
              <span class="leftt0201">当日用电量/kwh</span><br />
              <span class="leftt0202">15017.6</span>
            </div>
            <div class="leftt03">
              <span class="leftt0301"> 当月/万kwh</span><br />
              <span class="leftt0302">110.9087</span>
            </div>
            <div class="leftt04">
              <span class="leftt0401">当年/万kwh</span><br />
              <span class="leftt0402">110.9087</span>
            </div>
          </div>
          <div class="lefttop02n1">
            <div class="leftt01n1"></div>
            <div class="leftt02">
              <span class="leftt0201">当日用气量/m³</span><br />
              <span class="leftt0202">15017.6</span>
            </div>
            <div class="leftt03">
              <span class="leftt0301"> - 当月/m³</span><br />
              <span class="leftt0302">110.9087</span>
            </div>
            <div class="leftt04">
              <span class="leftt0401"> - 当年/m³</span><br />
              <span class="leftt0402">110.9087</span>
            </div>
          </div>
          <div class="lefttop02n2">
            <div class="leftt01n2"></div>
            <div class="leftt02">
              <span class="leftt0201">当日用水量/t</span><br />
              <span class="leftt0202">15017.6</span>
            </div>
            <div class="leftt03">
              <span class="leftt0301"> 当月/t</span><br />
              <span class="leftt0302">110.9087</span>
            </div>
            <div class="leftt04">
              <span class="leftt0401">当年/t</span><br />
              <span class="leftt0402">110.9087</span>
            </div>
          </div>
        </div>
        <div class="center0102">
          <div class="lefttop01">
            <span class="span01">设备类型分类</span>
          </div>
          <div class="lecent01">
            <TableOne></TableOne>
          </div>
        </div>
        <div class="center0103">
          <div class="lefttop01">
            <span class="span01">报警种类</span>
          </div>
          <div class="lecent02">
            <TableTwo></TableTwo>
            <span class="spandi01"></span>
          </div>
        </div>
      </div>
      <div class="center02">
        <div class="center0201">
          <div class="ta01">
            <img class="img01" src="../../assets/icon04.png" alt="" />
            <img class="img02" src="../../assets/icon05.png" alt="" />
            <img class="img03" src="../../assets/icon02.png" alt="" />
            <img class="img04" src="../../assets/icon03.png" alt="" />
            <p>总能耗 / tce</p>
            <span>14.1579</span>
          </div>
          <div class="ta01">
            <img class="img01" src="../../assets/icon04.png" alt="" />
            <img class="img02" src="../../assets/icon05.png" alt="" />
            <img class="img03" src="../../assets/icon02.png" alt="" />
            <img class="img04" src="../../assets/icon03.png" alt="" />
            <p>额定能耗 / tce</p>
            <span>2823.4</span>
          </div>
          <div class="ta01">
            <img class="img01" src="../../assets/icon04.png" alt="" />
            <img class="img02" src="../../assets/icon05.png" alt="" />
            <img class="img03" src="../../assets/icon02.png" alt="" />
            <img class="img04" src="../../assets/icon03.png" alt="" />
            <p>节能情况 / %</p>
            <span>42.6</span>
          </div>
          <div class="ta01">
            <img class="img01" src="../../assets/icon04.png" alt="" />
            <img class="img02" src="../../assets/icon05.png" alt="" />
            <img class="img03" src="../../assets/icon02.png" alt="" />
            <img class="img04" src="../../assets/icon03.png" alt="" />
            <p>人均能耗 / kgce/p</p>
            <span>70.79</span>
          </div>
          <div class="ta01">
            <img class="img01" src="../../assets/icon04.png" alt="" />
            <img class="img02" src="../../assets/icon05.png" alt="" />
            <img class="img03" src="../../assets/icon02.png" alt="" />
            <img class="img04" src="../../assets/icon03.png" alt="" />
            <p>建筑面积能耗/kgce/㎡</p>
            <span>141.58</span>
          </div>
          <div class="ta01">
            <img class="img01" src="../../assets/icon04.png" alt="" />
            <img class="img02" src="../../assets/icon05.png" alt="" />
            <img class="img03" src="../../assets/icon02.png" alt="" />
            <img class="img04" src="../../assets/icon03.png" alt="" />
            <p>总能耗 / tce</p>
            <span>14.1579</span>
          </div>
        </div>
        <div class="center0202"></div>
        <div class="center0203">
          <div class="lefttop01">
            <span class="span01">实时用电量</span>
          </div>
          <div class="c020301">
            <TableThree></TableThree>
          </div>
        </div>
      </div>
      <div class="center03">
        <div class="center0301">
          <div class="lefttop01">
            <span class="span01">分区用能</span>
          </div>
          <div class="right01">
            <span>#</span>
            <span>名称</span>
            <span>实时电压(V)</span>
            <span>实时电流(A)</span>
            <span>实时功率(W)</span>
          </div>
          <div class="cgxrt01">
            <div class="container">
              <div class="scroll-list">
                <!-- 每条数据都会被包裹在li标签中 -->
                <div v-for="(item,index) in items" :key="index">
                  <div>{{ item.id }}</div>
                  <div style="margin-left: 6%;">{{ item.areaName }}</div>
                  <div style="width: 6rem;display: flex;justify-content: center;align-items: center;">
                    {{
                    item.voltage
                  }}
                  </div>
                 <div style="width: 6rem;display: flex;justify-content: center;align-items: center;"> {{
                    item.current
                  }}</div>
                 <div style="width: 6rem;display: flex;justify-content: center;align-items: center;"> {{ item.power }}</div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
        <div class="center0302">
          <div class="lefttop01">
            <span class="span01">不同区域占比</span>
          </div>
          <TableFour></TableFour>
        </div>
        <div class="center0303">
          <div class="lefttop01">
            <span class="span01">当月用能</span>
          </div>
          <div class="lastdiv">
            <TableFive></TableFive>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>




  <script>
import TableOne from "../../components/TableOne.vue";
import TableTwo from "../../components/TableTwo.vue";
import TableThree from "../../components/TableThree.vue";
import TableFour from "../../components/TableFour.vue";
import TableFive from "../../components/TableFive.vue";
import request from "@/request/request";
export default {
  components: {
    TableOne,
    TableTwo,
    TableThree,
    TableFour,
    TableFive,
  },
  data() {
    return {
      items: [
        // 存放要显示的数据项
        { id: 1, text: "设备1", gl: "333.0", dr: "999.1", dy: "256.1" },
        { id: 2, text: "设备2", gl: "363.0", dr: "699.1", dy: "124.1" },
        { id: 3, text: "设备3", gl: "133.0", dr: "201.1", dy: "578.1" },
        { id: 4, text: "设备4", gl: "100.0", dr: "999.1", dy: "256.1" },
        { id: 5, text: "设备5", gl: "363.4", dr: "699.1", dy: "124.1" },
        { id: 6, text: "设备6", gl: "256.2", dr: "201.1", dy: "578.1" },
        { id: 1, text: "设备1", gl: "333.0", dr: "999.1", dy: "256.1" },
        { id: 2, text: "设备2", gl: "363.0", dr: "699.1", dy: "124.1" },
        { id: 3, text: "设备3", gl: "133.0", dr: "201.1", dy: "578.1" },
        { id: 4, text: "设备4", gl: "100.0", dr: "999.1", dy: "256.1" },
        { id: 5, text: "设备5", gl: "363.4", dr: "699.1", dy: "124.1" },
        { id: 6, text: "设备6", gl: "256.2", dr: "201.1", dy: "578.1" },
        // ...更多数据项0
      ],
    };
  },
  methods: {
    onClick() {
      this.$router.push("/industry");
      loaction.reload();
    },
    handleDragEnd() {
      // 拖动结束时刷新页面
      location.reload();
    },
    async getData(){
      const res = await request('/area/getHomeAreaData')
      res.data.content.map((x,index)=>{
        x.id = index+1
      })
      let firstSixElements = res.data.content.slice(0, 6);

// 将前六条数据复制到数组末尾
      this.items = res.data.content.concat(firstSixElements);
      console.log(res.data.content);
    },
  },
  mounted() {
    
    this.getData()
    // 每16秒添加一次数据
    // 获取动画元素
    const scrollBox = document.querySelector(".scroll-box");

    // 监听动画重复播放事件
    scrollBox.addEventListener("animationiteration", () => {
      // 重新设置动画起始位置
      console.log(10000);
      scrollBox.style.transform = "translateY(0)";
    });
  },
};
</script>
  <style lang="scss" scoped>
   .container {
        position: relative;
        // background-color: #a4ffcc;
        /* 父容器需要有明确的高度 */
        height: 10rem;
        margin: auto;
        overflow: hidden;
    }

    .container > .scroll-list {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        animation: scroll 10s linear infinite normal;
        padding-left: 3%;
    }

    .container > .scroll-list > div {
        width: 100%;
        /* 滚动的项目需要有具体的高度 */
        height: 1.8rem;
        // background-color: #1ea7ff;
        display: flex;
        // justify-content: center;
        align-items: center;
        color: white;
        font-size: .8rem;
    }


    @keyframes scroll {
        100% {
            /* 需要滚动内容的总高度 */
            top: -10.8rem;
        }
    }
@media screen and (max-width: 1400px) {
  .leftt04,
  .leftt03 {
    margin-top: 5%;
  }
  .ta01 p {
    margin-top: 15%;
  }
}
@media screen and (max-width: 1200px) {
  .ta01 p {
    margin-top: 25%;
  }
}
@media screen and (min-width: 2201px) {
  .leftt04,
  .leftt03 {
    margin-top: 1%;
  }
  .ta01 p {
    margin-top: 4%;
  }
}
.home {
  cursor: grab;
}
.ta01 p {
  font-size: 0.8rem;
}

.lastdiv {
  margin: 0 auto;
  width: 90%;
  height: 90%;
}
/* CSS样式 */
.scroll-box {
  height: 16rem; /* 设置容器高度 */
  overflow: hidden; /* 隐藏超出容器高度的内容 */
  color: #fff;
}

.scroll-box ul {
  height: 100%;
  overflow: hidden;
  list-style: none; /* 去除默认列表样式 */
  padding-left: 3%; /* 清空padding值 */
  margin: 0; /* 清空margin值 */
  animation: scrollUp 6s linear infinite; /* 添加滚动动画 */
}
.scroll-box ul li {
  font-size: 0.8rem;
}

@keyframes scrollUp {
  0% {
    transform: translateY(0);
  } /* 初始位置为0 */
  100% {
    transform: translateY(-50%);
  } /* 最终位置为-100%，也就是完全移出视图 */
}
.cgxrt01 {
  width: 100%;
  height: 80%;
}
.home {
  overflow-y: auto;
  background-image: url(../../assets/bg01.jpg);
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
::-webkit-scrollbar {
  display: none;
}
.header {
  width: 100%;
  height: 10vh;
  // background-color: pink;
  background-image: url(../../assets/header01.png);
  background-size: cover;
  text-align: center;
  position: relative;
  .header01 {
    color: #fff;
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 10vh;
  }
  .header02 {
    width: 5vw;
    display: inline-block;

    height: 5.7vh;
    position: absolute;
    right: 1.2%;
    background-image: url(../../assets/header02.png);
    background-size: 90% 99%;
    background-repeat: no-repeat;
    top: 2.2vh;
  }
}
.center {
  width: 95.5%;

  height: 88vh;
  background-color: rgba($color: #fff, $alpha: 0.1);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  .center01 {
    background-color: rgba($color: #fc4e4e, $alpha: 0.1);
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .center0103,
    .center0102,
    .center0101 {
      width: 100%;
      height: 32.5%;
      background-color: rgba(#011343, 0.3);
      border: 1px solid #4294ff;
      box-shadow: inset 0 0 10px 5px rgba(66, 148, 255, 0.8);
      div {
        margin-bottom: 2px;
      }
      .lefttop01 {
        height: 15%;
        width: 100%;
        background-color: rgba($color: #1c459f, $alpha: 0.5);
        background: linear-gradient(
          to right,
          rgba(#2150b3, 0.5),
          rgba(#081e55, 0.5),
          rgba(#2150b3, 0.5)
        );
        .span01 {
          display: inline-block;
          line-height: 4vh;
          color: #fff;
          padding-left: 3%;
        }
      }
      .lefttop02n1,
      .lefttop02n2,
      .lefttop02 {
        margin-top: 5%;
        margin-bottom: 5%;
        width: 95%;
        height: 24%;
        border: 1px solid #4294ff;
        margin: 7px auto;
        display: flex;

        justify-content: space-between;
        div {
          width: 24%;
          height: 100%;
        }
        .leftt01 {
          background-image: url(../../assets/icon01.png);
          background-repeat: no-repeat;
          background-size: 40%;
          background-position: 10%;
        }
        .leftt01n1 {
          background-image: url(../../assets/icon0102.png);
          background-repeat: no-repeat;
          background-size: 40%;
          background-position: 10%;
        }
        .leftt01n2 {
          background-image: url(../../assets/icon0103.png);
          background-repeat: no-repeat;
          background-size: 40%;
          background-position: 10%;
        }
        .leftt02 {
          .leftt0201 {
            color: #fff;
            font-size: 0.5rem;
          }
          .leftt0202 {
            color: #0084ff;
            font-size: 0.9rem;
          }
        }
        .leftt03 {
          color: #fff;
          font-size: 0.6rem;
          .leftt0301 {
            display: inline-block;
          }
        }
        .leftt04 {
          color: #fff;
          font-size: 0.6rem;
          .leftt0401 {
            display: inline-block;
          }
        }
      }
    }
    .center0102 {
      width: 100%;
      height: 32.5%;

      .lecent01 {
        width: 100%;
        height: 83%;
      }
    }
    .center0103 {
      width: 100%;
      height: 32.5%;

      .lecent02 {
        width: 100%;
        height: 83%;
        display: flex;
        .spandi01 {
          margin-left: 1%;
          margin-top: 1%;
          display: inline-block;
          width: 35%;
          height: 90%;
          border: 1px solid #4294ff;
        }
      }
    }
  }
  .center02 {
    height: 100%;
    width: 38%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .center0201 {
      width: 100%;
      height: 25%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      div {
        width: 31.2%;
        height: 43%;
        position: relative;
        border: 1px solid #4294ff;
        background-color: rgba($color: #4294ff, $alpha: 0.2);
        text-align: center;
        p {
          color: #fff;
          line-height: 3vh;
        }
        span {
          color: #3de7c9;
          font-size: 1.2rem;
        }
        image {
          position: absolute;
        }
        .img01 {
          top: -5%;
          left: -2%;
          position: absolute;
        }
        .img02 {
          top: -5%;
          right: -2%;
          position: absolute;
        }
        .img03 {
          bottom: -5%;
          left: -2%;
          position: absolute;
        }
        .img04 {
          bottom: -5%;
          right: -2%;
          position: absolute;
        }
      }
    }
    .center0202 {
      width: 100%;
      height: 38%;
      background-color: rgba($color: #011c4c, $alpha: 0.3);
      border: 1px solid #4294ff;
      background-image: url(../../assets/bg02.png);
    }
    .center0203 {
      width: 100%;
      height: 35%;

      background-color: rgba(#011343, 0.3);
      border: 1px solid #4294ff;
      box-shadow: inset 0 0 10px 5px rgba(66, 148, 255, 0.8);
      .c020301 {
        width: 100%;
        height: 82%;
      }
      .lefttop01 {
        height: 15%;
        width: 100%;
        background-color: rgba($color: #1c459f, $alpha: 0.5);
        background: linear-gradient(
          to right,
          rgba(#2150b3, 0.5),
          rgba(#081e55, 0.5),
          rgba(#2150b3, 0.5)
        );
        .span01 {
          display: inline-block;
          line-height: 4vh;
          color: #fff;
          padding-left: 3%;
        }
      }
    }
  }
  .center03 {
    background-color: rgba($color: #fc4e4e, $alpha: 0.1);
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .center0301 {
      width: 100%;
      height: 32.5%;
      background-color: rgba(#011343, 0.3);
      border: 1px solid #4294ff;
      box-shadow: inset 0 0 10px 5px rgba(66, 148, 255, 0.8);
      .right01 {
        width: 100%;
        height: 13%;
        background-color: rgba($color: #01649e, $alpha: 0.8);
        color: #fff;
        span {
          display: inline-block;
          margin-right: 4%;
          margin-left: 3.5%;
          font-size: 0.7rem;
        }
      }
      .lefttop01 {
        height: 15%;
        width: 100%;
        background-color: rgba($color: #1c459f, $alpha: 0.5);
        background: linear-gradient(
          to right,
          rgba(#2150b3, 0.5),
          rgba(#081e55, 0.5),
          rgba(#2150b3, 0.5)
        );
        .span01 {
          display: inline-block;
          line-height: 4vh;
          color: #fff;
          padding-left: 3%;
        }
      }
    }
    .center0302 {
      width: 100%;
      height: 32.5%;
      background-color: rgba(#011343, 0.3);
      border: 1px solid #4294ff;
      box-shadow: inset 0 0 10px 5px rgba(66, 148, 255, 0.8);
      .lefttop01 {
        height: 15%;
        width: 100%;
        background-color: rgba($color: #1c459f, $alpha: 0.5);
        background: linear-gradient(
          to right,
          rgba(#2150b3, 0.5),
          rgba(#081e55, 0.5),
          rgba(#2150b3, 0.5)
        );
        .span01 {
          display: inline-block;
          line-height: 4vh;
          color: #fff;
          padding-left: 3%;
        }
      }
    }
    .center0303 {
      width: 100%;
      height: 32.5%;
      background-color: rgba(#011343, 0.3);
      border: 1px solid #4294ff;
      box-shadow: inset 0 0 10px 5px rgba(66, 148, 255, 0.8);
      .lefttop01 {
        height: 15%;
        width: 100%;
        background-color: rgba($color: #1c459f, $alpha: 0.5);
        background: linear-gradient(
          to right,
          rgba(#2150b3, 0.5),
          rgba(#081e55, 0.5),
          rgba(#2150b3, 0.5)
        );
        .span01 {
          display: inline-block;
          line-height: 4vh;
          color: #fff;
          padding-left: 3%;
        }
      }
    }
  }
}
</style>

