<template>
  <div class="about">
    <div class="top">
      <div class="data">
        <el-radio-group
          v-model="radio3"
          size="small"
          style="margin-left: 1.5vw; margin-right: 1.5vw"
        >
          <el-radio-button label="月"></el-radio-button>
          <el-radio-button label="年"></el-radio-button>
        </el-radio-group>
        <el-date-picker
          v-if="radio3 === '日'"
          v-model="value"
          type="date"
          placeholder="选择日"
        >
        </el-date-picker>
        <el-date-picker
          v-if="radio3 === '月'"
          v-model="value2"
          type="month"
          placeholder="选择月"
        >
        </el-date-picker>
        <el-date-picker
          v-if="radio3 === '年'"
          v-model="value2"
          type="year"
          placeholder="选择年"
        >
        </el-date-picker>

        <el-button type="primary" style="margin-left: 1vw">查询</el-button>
      </div>
    </div>
    <div class="body">
      <el-tabs class="tabs01" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="区域能耗" name="first">
          <div id="start01" style="width: 80vw; height: 60vh"></div>
        </el-tab-pane>
        <el-tab-pane label="分项能耗" name="second">
          <div id="start02" style="width: 80vw; height: 60vh"></div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
  <script>
import * as echarts from "echarts";
import request from '@/request/request';

export default {
  data() {
    return {
      activeName: "second",
      radio3: "日",
      value: "2024-1",
      value2: "2024-1",
      list: [],
      data: [],
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    tu01() {
      var chartDom = document.getElementById("start01");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove",
        },
        series: {
          type: "sankey",
          layout: "none",
          emphasis: {
            focus: "adjacency",
          },
          data: this.list.data,
          links: this.list.links.filter(
            (x) => x.target !== null && x.value !== null
          ),
        },
      };

      option && myChart.setOption(option);
    },
    tu02() {
      var chartDom = document.getElementById("start02");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove",
        },
        series: {
          type: "sankey",
          layout: "none",
          emphasis: {
            focus: "adjacency",
          },
          data: this.data.data,
          links: this.data.links.filter(
            (x) => x.target !== null && x.value !== null
          ),
        },
      };

      option && myChart.setOption(option);
    },
    async getData() {
      const res = await request({
        method: "post",
        url: "/area/regionalEnergyConsumption",
        data: {
          type: 2,
        },
      });
      this.list = res.data.content;
      console.log(this.list.links.filter(
            (x) => x.target !== null && x.value !== null
          ));
      this.tu01();
    },
    async getTypeData() {
      const res = await request({
        method: "post",
        url: "/type/typeList",
        data: {
          type: 2,
        },
      });
      console.log(res.data.content);
      this.data = res.data.content;
      console.log(
        this.data.links.filter((x) => x.target !== null && x.value !== null)
      );
      this.tu02();
    },
  },
  mounted() {
    this.getData();
    this.getTypeData();
  },
};
</script>
  <style lang="scss" scoped>
.data {
  padding-top: 1%;
}
.top {
  width: 97%;
  height: 10vh;
  background-color: #fff;
  margin: 0 auto;
  border-radius: 3px;
  margin-bottom: 2vh;
}

.el-button {
  width: 4rem;
  height: 2rem;
  font-size: 0.8rem;
  padding: 0;
}

::v-deep .el-radio-button__inner {
  padding: 0.5rem 1rem !important;
  font-size: 0.8rem !important;
}

::v-deep .el-input--suffix .el-input__inner {
  padding-left: 1.8rem !important;
  padding-right: 1.8rem !important;
}
::v-deep .el-input__inner {
  width: 10rem;
  border-radius: 0.25rem;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 0.9rem;
  font-size: 0.8rem;
}
::v-deep .el-input {
  font-size: 0.8rem;
}
::v-deep .el-input__icon {
  width: 1.5rem;
  font-size: 0.8rem !important;
  line-height: 2.5rem;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 10rem;
  font-size: 0.8rem !important;
}
.tabs01 {
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 2%;
  height: 100% !important;
}
::v-deep .el-tabs__item {
  padding: 0 1.2rem;
  height: 2.2rem;
  box-sizing: border-box;
  line-height: 2.2rem;
  font-size: 0.7rem;
}
.body {
  width: 97%;
  border-radius: 2px;
  height: 80vh;
  background-color: #fff;
  margin: 0 auto;
  margin-bottom: 2vh;
}
.about {
  background-color: #f1f4f5;
}
.data {
  display: flex;
  align-items: center;
  margin-left: 1%;
}

</style>