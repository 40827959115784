<template>
  <div class="neng">
    <Tree
      @transmit="getMessage"
      :name="transformerName"
      :checked="true"
        :data="data"
        @gz01="getMessage02"
    ></Tree>

    <div class="re">
      <div class="right" :style="{ width: isExpanded ? '78vw' : '88vw' }">
        <div class="topp">
          <div class="data">
            <span class="cgx01">查询时间： </span>
            <el-date-picker
              v-if="radio3 === '日'"
              v-model="value"
              type="date"
              placeholder="选择日"
            >
            </el-date-picker>
            <el-date-picker
              v-if="radio3 === '月'"
              v-model="value2"
              type="month"
              placeholder="选择月"
            >
            </el-date-picker>
            <el-date-picker
              v-if="radio3 === '年'"
              v-model="value2"
              type="year"
              placeholder="选择年"
            >
            </el-date-picker>
            <el-date-picker
              v-if="radio3 === '自定义'"
              :picker-options="pickerOptions"
              v-model="value1"
              @change="onChange"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
            <el-form
              :inline="true"
              id="cgx"
              :model="ruleForm"
              ref="ruleForm"
              class="demo-ruleForm"
            >
              <el-form-item label="数据类型">
                <el-select v-model="ruleForm.region" placeholder="请选择">
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="数据项">
                <el-select v-model="ruleForm.region02" placeholder="请选择">
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="标称电压">
                <el-select v-model="ruleForm.region03" placeholder="请选择">
                  <el-option label="0.38KW" value="shanghai"></el-option>
                  <el-option label="6KW" value="beijing"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <el-button size="small" type="primary" style="margin-left: 1vw"
              >查询</el-button
            >
            <el-button type="success" size="small">导出</el-button>
          </div>
        </div>

        <div class="bottom">
          <el-tabs v-model="activeName" @tab-click="handleClick" class="elta">
            <el-tab-pane label="表格" name="first">
              <el-table
                :header-cell-style="{ background: '#ebeef5' }"
                :stripe="true"
                :fit="true"
                :data="tableData"
                height="400"
                border
                style="width: 100%"
              >
                <el-table-column prop="date" label=" # 时间"> </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="图表" name="second">
              <!-- <div id="main"></div> -->
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Tree from "../../components/tree/leftTree.vue";
import request from "@/request/request";

export default {
  components: {
    Tree,
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },

  data() {
    return {
      transformerName: "谐波监测",
      ruleForm: {
        name: "0.38",
      },
      radio3: "日",
      value: "2024-1-30",
      value2: "2024-1",
      tableData: [],
      activeName: "second",
      data: [],
      isExpanded: true,
      filterText: "",
      data: [{}],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  methods: {
    async getMessage02(data){
        console.log(data,677777);
        const res = await request({
        url: "/system/equipEquipment",
        params:{
          areaIds:data.join(','),
          eqName:this.eqName
        }
      });
      this.tableData = res.data.content;
      
    },
    transformData(data, pid = 0) {
      if (!data) {
        return []; // 如果data为null或undefined，则返回一个空数组
      }
      const result = [];
      for (const node of data) {
        if (node.pid === pid) {
          const newNode = {
            id: node.id,
            label: node.name,
            children: this.transformData(data, node.id),
          };
          result.push(newNode);
        }
      }
      return result;
    },
   
    async getArea() {
      const res = await request("/area/getAreaList");
      this.data = this.transformData(res.data.content);
      console.log(res.data.content);
    },
    getMessage(data) {
      this.isExpanded = data;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    format(percentage) {
      return percentage === 100 ? "满" : `${percentage}%`;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    toggleSidebar() {
      this.isExpanded = !this.isExpanded;
    },
  },
  mounted() {
    this.getArea();

  },
};
</script>
  
  <style lang="scss" scoped>
.el-date-editor .el-range__icon {
  font-size: 0.8rem !important;
  line-height: 2.5rem;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 10rem;
  height: 2rem !important;
  line-height: 2rem !important;
}
::v-deep .el-date-editor .el-range-input {
  font-size: 0.6rem;
}
::v-deep .el-date-editor .el-range__icon {
  font-size: 0.8rem;
  line-height: 1.9rem;
}
::v-deep .el-form-item__label {
  font-size: 0.8rem;
}
.el-button--small {
  width: 2.5rem;
  height: 1.6rem;
  padding: 0.1rem;
  font-size: 0.8rem;
}

::v-deep .el-table {
  font-size: 0.8rem !important;
}

::v-deep .el-tree-node__label {
  font-size: 0.7rem;
}

::v-deep .el-input__inner {
  font-size: 0.8rem;
  height: 2rem !important;
  line-height: 2rem !important;
}

.el-button--primary {
  width: 2.5rem;
  height: 1.6rem;
  padding: 0.1rem;
  font-size: 0.8rem;
}
::v-deep .el-input--prefix .el-input__inner {
  padding-left: 2rem;
}

::v-deep .el-input--suffix .el-input__inner {
  padding-right: 2rem;
}

::v-deep .el-input__inner {
  padding: 0 1rem;
  font-size: 0.8rem;
  height: 2rem !important;
  line-height: 2rem !important;
  padding: 0;
}

.zd {
  margin-top: 3px;
}
#cgx {
  margin-top: 1.2%;
  margin-left: 1%;
}
.data {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}
.topp {
  width: 99%;
  vertical-align: middle;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 1.5%;
  border-radius: 3px;
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.3);
  height: 10vh;
  background-color: #fff;
}
.vv {
  color: #07ccfa;
  font-size: 1.4vw;
  font-weight: 800;
}
.topp02 {
  vertical-align: middle;
  width: 99%;
}
.topp03 {
  border-radius: 2px;
  margin-left: 0.5%;
  position: relative;
  display: inline-block;
  width: 32.8%;
  background-color: #fff;
  height: 10vh;
  margin-top: 1%;
  img {
    margin-left: 5%;
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  div {
    position: absolute;
    top: 20px;
    display: inline-block;
  }
}

.elta {
  margin-left: 1%;
  margin-right: 1%;
}
.bottom {
  height: 80vh;
  width: 99%;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 1.5%;
  border-radius: 3px;
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.3);
}
.max02 {
  color: red;
}
.min02 {
  color: #658e63;
}
.bdy01 {
  width: 100%;
  margin: 3vw auto;

  margin-left: 3vw;
}

.kw {
  font-size: 0.8vw;
  font-weight: 400;
}
.fzl {
  font-size: 1vw;
}
.fz {
  color: #02ae4e;
}
.r05 {
  color: #02ae4e;
  font-size: 1.2vw;
  font-weight: 600;
}

::v-deep .el-progress-bar__outer {
  height: 20px !important;
}
.el-progress {
  width: 65%;
  display: inline-block;
}
.shiyong {
  color: #67c23a;
}
.re {
  width: 80%;

  background-color: #f1f4f5;
}
.neng {
  display: flex;
}
.right {
  background-color: #f1f4f5;
  width: 100%;
}
.top01 {
  p {
    display: inline-block;
    margin-top: 5%;
    margin-left: 2%;
  }
}
.sidebar {
  width: 2%;
  height: 100vh;
  background-color: #cfd3dc;
  transition: width 0.3s ease;
}

.sidebar-expanded {
  width: 12%;
}

.toggle-button {
  position: fixed;
  top: 50%;
  transform: translateY(0);
  left: 10%;
  width: 1.7vw;
  height: 2vw;
  background-color: #ccc;
  border: none;
  cursor: pointer;
  transition: transform 0.08s ease;
}
.el-tree {
  background-color: #cfd3dc;
}

.button-expanded {
  transform: translateX(520%);
}

::v-deep .el-tabs__item {
  padding: 0 1.2rem;
  height: 2.2rem;
  box-sizing: border-box;
  line-height: 2.2rem;
  font-size: 0.7rem;
}

.el-form--inline .el-form-item {
  display: inline-block;
  margin-right: 0.5rem;
  vertical-align: top;
}
.el-input {
  font-size: 0.8rem;
}

::v-deep .el-form-item__content {
  font-size: 1rem;
}
::v-deep .el-form-item__label {
  padding: 0;
  font-size: 0.8rem;
}
::v-deep .el-input__icon {
  line-height: 2rem;
}
.cgx01 {
  font-size: 0.8rem;
}
::v-deep .el-form-item {
  margin-bottom: 1.1rem;
}
</style>