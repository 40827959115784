<template>
  <div class="container">
    <div class="box">
      <Tree :name="'电表实时数据'" :checked="false" :data="data" @dataId="getId"></Tree>

      <div class="right" :style="{ width: isExpanded ? '76vw' : '86vw' }">
        <div class="btn">
          <!-- <div class="tag">
                  <el-radio-group v-model="radio1" @change="changeTable" size="small">
                    <el-radio-button label="基本数据"></el-radio-button>
                    <el-radio-button label="扩展参数"></el-radio-button>
                  </el-radio-group>
                </div> -->
          <el-tabs v-model="selectedChart">
            <el-tab-pane name="basic">
              <el-table :header-cell-style="{ background: '#ebeef5' }" :stripe="true" :fit="true" :data="tableData"
                border style="width: 100%; border-top: 0">
                <el-table-column prop="index" label="#" width="100">
                  <template slot-scope="scope">
                    {{ scope.$index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="名称" flex="1"></el-table-column>
                <el-table-column prop="value" label="当前值" flex="1"></el-table-column>
                <el-table-column prop="time" label="变化时间" flex="1"></el-table-column>
                <!-- <el-table-column prop="name" label="查看详情" flex="1"></el-table-column> -->
                <el-table-column prop="address" label="操作" flex="1">--</el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane name="extended">
              <el-table :header-cell-style="{ background: '#ebeef5' }" :stripe="true" :fit="true" :data="tableData"
                border style="width: 100%; border-top: 0">
                <el-table-column prop="name" label="名称" flex="1"></el-table-column>
                <el-table-column prop="value" label="当前值" flex="1"></el-table-column>
                <el-table-column prop="time" label="变化时间" flex="1"></el-table-column>
                <!-- <el-table-column prop="name" label="查看详情" flex="1"></el-table-column> -->
                <el-table-column prop="address" label="操作" flex="1">--</el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tree from "@/components/tree/leftTree.vue";
import request from "@/request/request";

export default {
  components: { Tree },
  data() {
    return {
      tableData: [],
      radio1: "基本数据",
      selectedChart: "basic",
      activeName: "second",
      filterText: "",
      isExpanded: true,
      eqId: "",
      searchKeyword: "",
      filteredTreeData: [],
      data: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    async getData() {
      const res = await request({
        url: "/diagnosisDiagnosis/getElectricMeterReading",
        params: {
          id: this.eqId.toString(),
        },
      });
      const a = [];
      a.push(res.data.content);
      this.tableData = a;
      console.log(this.tableData);
    },
    getMsg(msg) {
      this.isExpanded = msg;
    },
    showChart(chartType) {
      this.selectedChart = chartType;

      // 在这里添加初始化和显示图表的逻辑
      if (chartType === "basic") {
        // 初始化和显示基本数据图表
        this.initBasicChart();
      } else if (chartType === "extended") {
        // 初始化和显示扩展参数图表
        this.initExtendedChart();
      }
    },
    initBasicChart() {
      // 在这里初始化和显示基本数据图表的逻辑
      // 使用 echarts 或其他图表库初始化基本数据图表
    },
    initExtendedChart() {
      // 在这里初始化和显示扩展参数图表的逻辑
      // 使用 echarts 或其他图表库初始化扩展参数图表
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    toggleSidebar() {
      this.isExpanded = !this.isExpanded;
    },
    changeTable() {
      // 切换单选按钮时触发，更新 selectedChart 以显示相应的表格
      if (this.radio1 === "基本数据") {
        this.selectedChart = "basic";
      } else if (this.radio1 === "扩展参数") {
        this.selectedChart = "extended";
      }
    },
    async getId(data) {
      console.log(data);
      this.eqId = data;
      this.getData();
    },
    transformData(data, pid = 0) {
      if (!data) {
        return []; // 如果data为null或undefined，则返回一个空数组
      }
      const result = [];
      for (const node of data) {
        if (node.pid === pid) {
          const newNode = {
            id: node.stationId,
            label: node.name,
            children: this.transformData(data, node.id),
          };
          result.push(newNode);
        }
      }
      return result;
    },
    async getShebei() {
      const res = await request({
        url: "/dataAnalysis/listEquipment",
        method: "get",
        params: {},
      });
      // = res.data.content;
      // console.log(res.data.content, "98989898998989");
      // this.gz = res.data.content;
      // console.log(this.gz, "gzgzgzgzgzgz");
      // for (const item of this.gz) {
      //   if (item.type === 1) {
      //     this.typeOneIds.push(item.id);
      //   }
      //   // console.log(this.typeOneIds, "qaaaaaaaaaaaaaaaaaaaaaaaa");
      // }
      console.log(res.data.content);
      this.data = this.transformData(res.data.content);
      console.log(this.data);
    },
  },

  mounted() {
    this.getShebei();
  },
};
</script>

<style scoped>
.container {
  width: 100rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.tab {
  width: 70rem;
  padding: 0.625rem;
  box-sizing: border-box;
}

.btn {
  width: 30rem;
  padding: 0.625rem;
  margin-top: 1.25rem;
  box-sizing: border-box;
}

.box {
  width: 100rem;
  height: 94vh;
  background-color: #f1f4f5;
  display: flex;
}

.left {
  width: 8rem;
  position: relative;
  background-color: #cfd3dc;
  overflow: hidden;
}

.watt {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  margin-left: 0.625rem;
  font-size: 0.8rem;
}

.title {
  width: 100%;
  box-sizing: border-box;
}

.inline-input {
  width: 100%;
  margin-top: 1.25rem;
  box-sizing: border-box;
}

.select {
  width: 12rem;
  height: 80vh;
}

.right {
  width: 93rem;
  height: 100vh;
  display: flex;

  background-color: #f1f4f5;
  flex-direction: column;
}

::v-deep.el-radio-button--small .el-radio-button__inner {
  /* padding: 0; */
  font-size: 0.8rem !important;
}

.data {
  display: flex;
  align-items: center;
  margin-top: 0.625rem;
}

.date {
  display: flex;
  align-items: center;
  margin-left: 0.625rem;
}

.data button {
  margin-right: 0.375rem;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
}

.data .active {
  background-color: rgb(0, 204, 255);
  color: white;
}

.image {
  width: 82rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.sidebar {
  width: 2rem;
  height: 100vh;
  background-color: #cfd3dc;
  transition: width 0.3s ease;
}

.sidebar-expanded {
  width: 12%;
}

::v-deep .el-input__inner {
  font-size: 0.8rem;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 1rem;
}

::v-deep .el-tree-node__content .el-tree-node__label {
  font-size: 0.8rem !important;
}

.toggle-button {
  position: absolute;
  top: 50%;

  left: 0.2%;
  width: 1.8rem;
  height: 2rem;
  background-color: #cfd3dc;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.el-tree {
  padding: 0;
  background-color: #cfd3dc;
}

.button-expanded {
  transform: translateX(401%);
}

.bottom {
  width: 90%;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 1.5%;
  border-radius: 0.1875rem;

  margin-left: 0;
}

#main {
  width: 80%;
  height: 60vh;
}

.btn {
  width: 99.5%;
  margin-left: 1.25rem;
  background-color: #ffffff;
}

.two {
  transform: translateX(-10px);
}

.el-table {
  font-size: 0.8rem;
}

::v-deep .el-table .el-table__cell {
  padding: 0.8rem 0;
}
</style>