<template>
  <div>
    <div class="header">
      <div class="header-left"></div>
      <div class="header-middle">
        <div class="small" @mouseover="handleMouseOver(index)" @mouseout="handleMouseOut(index)"
          @click="handleClick(index)" v-for="(item, index) in parentNodes" :key="index"
          :class="{ active: index === selectedBoxIndex }">
          <img :src="item.url" alt="" id="hua" />
          <div>{{ item.label }}</div>
        </div>
      </div>
      <div class="header-right">
        <!-- <i class="el-icon-message-solid" id="gz01" @click="onClick"> -->
        <i class="el-icon-message-solid" id="gz01" @click="onClick" title="报警查询">
          <div id="gz02"><span>0</span></div>
        </i>
        <i class="el-icon-chat-dot-square" @click="dialogVisible = true"></i>
        <el-dialog title="添加意见反馈" :visible.sync="dialogVisible" width="30%" :modal-append-to-body="false">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="标题：">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-form>
          <p>意见反馈:</p>
          <div class="cgxd">
            <div class="cgxd01">
              <span class="i01"></span>
              <span class="i02"></span>
              <span class="i03"></span>
            </div>
            <div class="cgxd02">
              <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" placeholder="Insert text here ..."
                v-model="textarea2">
              </el-input>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
          </span>
        </el-dialog>
        <img src="../../../assets/touxiang.png" alt="" id="tu" />
        <el-dropdown :hide-on-click="false">
          <span class="el-dropdown-link">
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item><span @click="onUser">个人信息</span></el-dropdown-item>
            <el-dropdown-item><span @click="dialogVisible03 = true">修改密码</span></el-dropdown-item>
            <el-dropdown-item><span @click="open">退出登录</span></el-dropdown-item>
            <!-- <el-dropdown-item>隐藏tag标签页</el-dropdown-item> -->
          </el-dropdown-menu>
        </el-dropdown>

        <el-dialog class="dialog1" title="个人名片" :visible.sync="dialogVisible02" width="30%">
          <div class="content">
            <span class="dia">账号:</span>&nbsp;&nbsp;<span class="dia">{{
            userForm.username
          }}</span><br />
            <span class="dia diaa">身份:</span>&nbsp;&nbsp;<span class="dia">{{
              userForm.role == 1 ? "管理员" : "用户"
            }}</span>
          </div>
        </el-dialog>
        <el-dialog title="修改密码" :visible.sync="dialogVisible03" width="30%">
          <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
            class="demo-ruleForm">
            <el-form-item label="旧密码" prop="pass01">
              <el-input v-model="ruleForm.pass01" autocomplete="new-password" type="password"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="pass">
              <el-input type="password" v-model="ruleForm.pass" autocomplete="new-password"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass">
              <el-input type="password" v-model="ruleForm.checkPass" autocomplete="new-password"></el-input>
            </el-form-item>

            <el-form-item> </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible03 = false">取 消</el-button>
            <el-button type="primary" @click="onEdit">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script scoped>
import { mapState, mapMutations } from "vuex";
import request from "@/request/request";
export default {
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("年龄不能为空"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入数字值"));
        } else {
          if (value < 18) {
            callback(new Error("必须年满18岁"));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      textarea2: "",
      userForm: {},
      ruleForm: {
        pass01: "",
        pass: "",
        checkPass: "",
        age: "",
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        age: [{ validator: checkAge, trigger: "blur" }],
      },
      dialogVisible: false,
      dialogVisible02: false,
      dialogVisible03: false,
      form: {
        name: "",
      },
    };
  },
  computed: {
    ...mapState(["menus", "selectedBoxIndex", "activeIndex"]),
    parentNodes() {
      return this.menus.map((item) => {
        if (!item.children || item.children.length === 0) {
          return item;
        } else {
          return {
            name: item.name,
            label: item.label,
            url: item.url,
          };
        }
      });
    },
  },
  watch: {
    selectedBoxIndex(newIndex, oldIndex) {
      // 处理变化的逻辑
      console.log("selectedBoxIndex 改变了：", newIndex);
    },
  },
  methods: {
    async onUser() {
      this.dialogVisible02 = true;
      const res = await request({
        url: "/user/userInfo",
      });
      this.userForm = res.data.content;
      // console.log(res.data.content);
    },
    async onEdit() {
      console.log(this.ruleForm.pass01);
      console.log(this.ruleForm.checkPass);
      if (this.ruleForm.checkPass == this.ruleForm.pass) {
        const res = await request({
          method: "post",
          url: "/user/updatePassword",
          data: {
            oldPassword: this.ruleForm.pass01,
            newPassword: this.ruleForm.checkPass,
          },
        });
        console.log(res);
        if (res.data.success) {
          this.$message({
            type: "success",
            message: "修改成功!",
          });
          this.dialogVisible03 = false;
          localStorage.clear();
          this.$router.push("/login");
        } else {
          this.$message({
            type: "error",
            message: "修改失败!",
          });
        }
      }
    },
    onClick() {
      if (this.$route.path !== "/warningReport") {
        this.handleClick(4);
        // this.$router.push("/warningReport");
      }
    },
    open() {
      this.$confirm("确定退出登录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          localStorage.clear();
          this.$router.push("/login");
          this.$message({
            type: "success",
            message: "退出成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },

    ...mapMutations([
      "selectparentmenu",
      "selectedIndex",
      "setActiveIndex",
      "selectMenu",
    ]),
    handleClick(index) {
      if (this.$route.path !== this.menus[index].children[0].path) {
        this.$router.push(this.menus[index].children[0].path);
      }
      this.setActiveIndex(this.menus[index].children[0].path); // 更新 Vuex 中的 activeIndex
      localStorage.setItem("activeIndex", this.activeIndex);
      this.selectMenu(this.menus[index].children[0]);
      this.selectparentmenu(this.menus[index].name);
      this.selectedIndex(index);
      this.$store.commit("setSelectedBoxIndex", index); // 保存索引到 Vuex
      localStorage.setItem("selectedBoxIndex", index);
      this.resetColors();
      document.querySelectorAll(".small")[index].style.backgroundColor =
        "#4098ff";
    },

    handleMouseOver(index) {
      if (index !== this.selectedBoxIndex) {
        document.querySelectorAll(".small")[index].style.backgroundColor =
          "#4098ff";
      }
    },

    handleMouseOut(index) {
      if (index !== this.selectedBoxIndex) {
        document.querySelectorAll(".small")[index].style.backgroundColor =
          "#070e22";
      }
    },

    resetColors() {
      document.querySelectorAll(".small").forEach((box) => {
        box.style.backgroundColor = "#070e22";
      });
    },
  },

  mounted() {
    console.log(this.parentNodes);
    const storedIndex = localStorage.getItem("selectedBoxIndex");
    if (storedIndex !== null) {
      this.selectedIndex(parseInt(storedIndex, 10));
    }
  },
};
</script>

<style scoped lang="scss">
.header {
  width: 100rem;
  height: 4rem;
  background-color: #070e22;
  display: flex;
  font-size: 0.8rem;

  .header-left {
    width: 14rem;
    height: 4rem;
    background-color: #070e22;
  }

  .header-middle {
    width: 72rem;
    height: 4rem;
    background-color: #070e22;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .small {
      width: 8rem;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      cursor: pointer;
      flex-direction: column;
    }

    .active {
      background-color: #4098ff;
    }
  }

  .header-right {
    #gz01 {
      margin-left: 25%;
      background-color: #070e22;
      position: relative;
    }

    #gz02 {
      position: absolute;
      top: -20%;
      right: -30%;
      width: 1rem;
      height: 1rem;
      background-color: red;
      border: 1px solid #fff;
      border-radius: 50%;
      text-align: center;

      span {
        font-size: 0.5rem;
        position: absolute;
        top: 15%;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    flex: 1;

    i {
      width: 2rem;
      height: 2.5rem;
      color: #fff;
      font-size: 1.9rem;
      position: relative;
      top: 0.75rem;
      margin-right: 6%;
    }
  }
}

#tu {
  width: 2rem;
  height: 2rem;
  vertical-align: middle;
  position: relative;
  top: 0.2rem;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 0.75rem;
}

img {
  width: 1.5rem;
}

.cgxd {
  width: 100%;
  height: 15vh;
  border: 1px solid #cccccc;

  .cgxd01 {
    width: 100%;
    height: 15%;
    // background-color: #4098ff;
    border-bottom: 1px solid #cccccc;

    span {
      width: 2vw;
      height: 1rem;
      // background-color: pink;
      display: inline-block;
    }

    .i01 {
      background-image: url(../../../assets/lian01.png);
      background-repeat: no-repeat;
      background-size: 60%;
    }

    .i02 {
      background-image: url(../../../assets/lian02.png);
      background-repeat: no-repeat;
      background-size: 55%;
    }

    .i03 {
      background-image: url(../../../assets/lian03.png);
      background-repeat: no-repeat;
      background-size: 58%;
    }
  }

  .cgxd02 {
    width: 100%;
    height: 83%;
  }
}

::v-deep .el-dialog__body {
  // transform: translateY(-1rem);
}

.dia {
  background: linear-gradient(to right, #fff, red);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: flicker 1.5s infinite alternate;
}

@keyframes flicker {
  0% {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  }

  100% {
    text-shadow: 0 0 20px rgba(255, 255, 255, 0.8),
      0 0 30px rgba(255, 255, 255, 0.8), 0 0 40px rgba(255, 255, 255, 0.8);
  }
}

::v-deep .dialog1 .el-dialog__body {
  background-color: #070e22;
}

::v-deep .dialog1 .el-dialog__footer {
  background-color: #041634;
}

::v-deep .dialog1 .el-dialog__body {
  text-align: center;
  /* 设置文本居中 */
}

::v-deep .dialog1 .el-dialog__body .dia {
  display: inline-block;
  /* 设置内联元素 */

  margin-top: 2rem;
}

::v-deep .dialog1 .el-dialog__body .diaa {
  margin-bottom: 4rem;
}

::v-deep .dialog1 .content {
  background-image: url("../../../assets/k02.jpg");
  background-size: 100%;
  background-position-y: -5rem;
  background-repeat: no-repeat;

  // border-radius: 10px;
  background-repeat: no-repeat;
}

::v-deep .dialog1 .el-dialog__body {
  padding: 0;
}

::v-deep .dialog1 .el-dialog__header {
  background: transparent;
}

::v-deep .el-dialog__header {
  background-color: #98bbf4 !important;
}
</style>
