<template>
  <div class="menu">
    <el-menu
      default-active="2"
      class="el-menu-vertical-demo"
      collapse-transition
      background-color="#070e22"
      text-color="#fff"
    >
      <el-menu-item-group
        v-for="(subItem, index) in childrenNode"
        :key="subItem.path"
        style="overflow: hidden"
      >
        <el-menu-item
          @click="clickMenu(subItem, index)"
          :index="subItem.path"
          :class="{
            'active-item': activeIndex === subItem.path,
            'hover-item': activeIndex !== subItem.path,
          }"
        >
          {{ subItem.label }}
        </el-menu-item>
      </el-menu-item-group>
    </el-menu>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  computed: {
    ...mapState(["parentName", "menus", "activeIndex", "selectedBoxIndex"]),
    childrenNode() {
      // const parentItem = this.menus.find((item) => item.name === this.parentName);
      const parentItem = this.menus.find(
        (item) => item.name === this.parentName
      );
      return parentItem ? parentItem.children : [];
    },
  },
  methods: {
    ...mapMutations(["selectMenu", "setActiveIndex"]),
    clickMenu(item) {
      console.log(item, 6666);

      if (this.$route.path !== `${item.path}`) {
        this.$router.push(`${item.path}`);
        this.setActiveIndex(item.path); // 更新 Vuex 中的 activeIndex
        localStorage.setItem("activeIndex", this.activeIndex);
        this.selectMenu(item);
      }
    },
  },
  mounted() {
    const storedActiveIndex = localStorage.getItem("activeIndex");
    if (storedActiveIndex) {
      this.setActiveIndex(storedActiveIndex); // 初始化时从 Vuex 中获取 activeIndex
    }
  },
};
</script>

<style lang="scss" scoped>
.menu {
  width: 10rem;
  height: 92vh;
  overflow-y: auto;
  position: fixed;
  left: 0;
  background-color: #070e22;
  font-size: 1rem !important;

  .el-menu {
    width: 10rem;
    border-right: none;

    v-deep .el-dropdown-menu__item,
    .el-menu-item {
      width: 10rem !important;
      height: 5rem !important;
      font-size: 0.8rem !important;
    }
    .el-menu-item,
    .el-submenu__title {
      height: 3rem !important;
      line-height: 3rem !important;
    }

    .active-item {
      background-color: #445eac !important;
      color: #fff !important;
    }
    .hover-item:hover {
      background-color: #445eac !important;
      color: #fff !important;
    }
  }
}
::-webkit-scrollbar {
  display: none;
}
</style>
