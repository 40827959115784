<template>
  <div class="container">
    <div class="box">
      <Tree
        name="分区能耗"
        :checked="false"
        :data="data"
        @gz01="getMessage02"
      ></Tree>

      <div class="right">
        <div class="data">
          <!-- <el-radio-group
            v-model="radio3"
            size="small"
            style="margin-left: 1.5vw; margin-right: 1.5vw"
          >
            <el-radio-button label="总能耗"></el-radio-button>
            <el-radio-button label="电"></el-radio-button>
            <el-radio-button label="水"></el-radio-button>
          </el-radio-group> -->

          <!-- <el-radio-group
            v-model="radio3"
            size="small"
            style="margin-left: 1.5vw; margin-right: 1.5vw"
          >
            <el-radio-button label="能耗"></el-radio-button>
            <el-radio-button label="单位面积能耗"></el-radio-button>
            <el-radio-button label="人均能耗"></el-radio-button>
          </el-radio-group> -->

          <el-radio-group
            v-model="radio3"
            size="small"
            style="margin-left: 1.5vw; margin-right: 1.5vw"
          >
            <el-radio-button label="日"></el-radio-button>
            <el-radio-button label="月"></el-radio-button>
            <!-- <el-radio-button label="年"></el-radio-button> -->
            <el-radio-button label="自定义"></el-radio-button>
          </el-radio-group>
          <el-date-picker
            v-if="radio3 === '日'"
            v-model="value1"
            type="date"
            placeholder="选择日"
          >
          </el-date-picker>
          <el-date-picker
            v-if="radio3 === '月'"
            v-model="value2"
            type="month"
            placeholder="选择月"
          >
          </el-date-picker>
          <el-date-picker
            v-if="radio3 === '年'"
            v-model="value2"
            type="year"
            placeholder="选择年"
          >
          </el-date-picker>
          <el-date-picker
            v-if="radio3 === '自定义'"
            :picker-options="pickerOptions"
            v-model="value3"
            @change="onChange"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <el-button size="small" type="primary" style="margin-left: 1vw" @click="onSearch"
            >查询</el-button
          >
        </div>
        <!-- 将所有的 box 放在同一个容器中 -->
        <div class="image">
          <div class="center">
            <div class="box1" id="box1"></div>
            <div class="box2" id="box2"></div>
          </div>
          <div class="center-bottom">
            <div class="box4" id="box4"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import * as echarts from "echarts";
import request from "@/request/request";
import Tree from "@/components/tree/leftTree.vue";
import formatDates from "@/utils";
export default {
  components: {
    Tree,
  },
  data() {
    return {
      list: [],
      areaData: [],
      radio3: "日",
      value1: "2024-04-01",
      value2: "",
      value3:'',
      nameData: [],
      valueData: [],
      searchKeyword: "",
      filteredTreeData: [],
      data: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      parmas: {
        areaId: 1,
        startTime:'2024-04-01',
        endTime:'',
        type: 1,
      },
      allValuesArray: [],
    };
  },
  methods: {
    onSearch() {
      if (this.radio3 == "日") {
        this.parmas.type = 1;
        const value1 = [] 
        if (this.value1 != "2024-04-01") {
          value1.push(this.value2)
          this.value1 = value1
          this.params.startTime = formatDates(value1)[0]
        }
        // this.getThree(this.value1);
        this.getList()

        this.getData();
        this.value2 = "";
        this.value3 = "";
      } else if (this.radio3 == "月") {
        console.log(777777777777);
        this.parmas.type = 2;
        // this.ruleForm.region02 = 1440;
        this.value1 = "";
        this.value3 = "";
        if (this.value2 != "") {
          // this.value2 = new Date(this.value2);
          const value2 = []
          value2.push(this.value2)
          this.parmas.startTime = formatDates(value2)[0]
          // this.getThree(formatDates(value2)[0]);
        }
        this.getList()

        this.getData();
      } else if (this.radio3 == "自定义") {
        this.parmas.type = 1;
        this.value1 = "";
        this.value2 = "";
        if (this.value3 != "") {
          console.log(this.value3,333333333333333);
          this.value3 = formatDates(this.value3);
          //  console.log(this.value3);
          // this.getThree(this.value3.join(";"));
          this.parmas.startTime = this.value3[0]
          this.parmas.endTime = this.value3[1]
          this.getList()
          this.getData();
        }
      }
    },
    async getData() {
      const res = await request({
        method: "post",
        url: "/area/getByArea",
        data: this.parmas,
      });
      this.nameData = res.data.content.map((x) => x.name);
      this.valueData = res.data.content.map((x) => x.value);
      this.areaData = res.data.content;
      this.box1();
      this.box2();
      console.log(res.data.content);
    },
    async getList() {
      const res = await request({
        method: "post",
        url: "/area/getByAreaList",
        data: this.parmas
      });
      this.list = res.data.content;
      this.dates = res.data.content.map((x) =>
        x.data.map((entry) => Object.keys(entry)[0])
      );
      if (this.radio3 ==  '日') {
        this.xAxis = this.dates[0].map((x) => x.substring(11, 16));
      } else {
        this.xAxis = this.dates[0].map((x) => x.substring(0, 10));
      }
      console.log(this.dates);
      // 遍历所有车间
      // res.data.content.forEach((item) => {
      //   // 获取当前车间的data数组
      //   let data = item.data;

      //   // 从data中提取值组成数组，并存储到allValuesArray中
      //   let valuesArray = data.map((entry) => Object.values(entry)[0]);
      //   this.allValuesArray.push(valuesArray);
      // });

      // // 打印结果
      // console.log(this.allValuesArray);
      this.allValuesArray = res.data.content.map((item) => {
        let name = item.name;
        let data = item.data.map((entry) => Object.values(entry)[0]);

        return {
          name: name,
          type: "bar",
          data: data,
        };
      });
      this.box4();

      // 打印结果
      // console.log(transformedArray);

      // console.log(res.data.content);
    },
    async getAreaList() {
      const res = await request({
        method: "post",
        url: "/area/regionalEnergyConsumption",
        data: {
          type: 1,
        },
      });
      console.log(res.data.content);
    },
    async getMessage02(data){
        console.log(data,677777);
        const res = await request({
        url: "/system/equipEquipment",
        params:{
          areaIds:data.join(','),
          eqName:this.eqName
        }
      });
      this.tableData = res.data.content;
      
    },
    transformData(data, pid = 0) {
      if (!data) {
        return []; // 如果data为null或undefined，则返回一个空数组
      }
      const result = [];
      for (const node of data) {
        if (node.pid === pid) {
          const newNode = {
            id: node.id,
            label: node.name,
            children: this.transformData(data, node.id),
          };
          result.push(newNode);
        }
      }
      return result;
    },
   
    async getArea() {
      const res = await request("/area/getAreaList");
      this.data = this.transformData(res.data.content);
      console.log(res.data.content);
    },
    querySearch(queryString, cb) {
      if (queryString) {
        const filteredTreeData = this.generateFilteredTreeData(queryString);
        this.filteredTreeData = filteredTreeData;
        cb(filteredTreeData);
      } else {
        this.filteredTreeData = this.data;
        cb(this.data);
      }
    },
    handleSelect(item) {
      console.log(item);
    },
    handleNodeClick(data) {
      console.log(data);
    },
    generateFilteredTreeData(queryString) {
      return this.data
        .filter((branch) =>
          branch.children.some((leaf) =>
            leaf.label.toLowerCase().includes(queryString.toLowerCase())
          )
        )
        .map((branch) => ({
          ...branch,
          children: branch.children.filter((leaf) =>
            leaf.label.toLowerCase().includes(queryString.toLowerCase())
          ),
        }));
    },
    box1() {
      var chartDom = document.getElementById("box1");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          text: "区域能耗排名",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        xAxis: {
          type: "category",
          data: this.nameData,
        },
        yAxis: {
          type: "value",
          name: "kgce",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#999",
              width: 2,
              type: "solid",
            },
          },
          title: {
            text: "kgce",
            textStyle: {
              fontSize: 12,
              color: "#333",
            },
            padding: 0,
            rotate: 0,
            textAlign: "left",
            margin: 0,
          },
        },
        series: [
          {
            data: this.valueData,
            type: "bar",
          },
        ],
      };

      option && myChart.setOption(option);
    },

    box2() {
      var chartDom = document.getElementById("box2");

      var myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "3%",
          left: "center",
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
            },
            data: this.areaData,
            label: {
              position: "outer",
              alignTo: "none",
              bleedMargin: 20,
              formatter: "{b}: {c} ({d}%)", // 显示标签的格式
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },

    box4() {
      var chartDom = document.getElementById("box4");

      var myChart = echarts.init(chartDom);
      var option;
      var hours = [];
      for (var i = 0; i < 24; i++) {
        hours.push(i + ":00");
      }
      option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: this.nameData,
          orient: "horizontal",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            magicType: {
              type: ["line", "bar"],
            },
          },
        },
        xAxis: {
          type: "category",
          data: this.xAxis,

          axisLabel: {
            interval: 3,
            rotate: 45,
          },
        },

        yAxis: {
          type: "value",
          axisLine: {
            show: true,
            lineStyle: {
              type: "solid",
              color: "#000000",
            },
          },
        },
        // series: [
        //   {
        //     name: "车间1",
        //     type: "bar",
        //     data: [
        //       120, 132, 101, 134, 90, 230, 210, 120, 132, 101, 134, 90, 230,
        //       210, 120, 132, 101, 134, 90, 230, 210, 120, 132, 101,
        //     ],
        //   },
        //   {
        //     name: "车间2",
        //     type: "bar",
        //     data: [
        //       220, 182, 191, 234, 290, 330, 310, 220, 182, 191, 234, 290, 330,
        //       310, 220, 182, 191, 234, 290, 330, 310, 220, 182, 191,
        //     ],
        //   },
        //   {
        //     name: "车间3",
        //     type: "bar",
        //     data: [
        //       150, 232, 201, 154, 190, 330, 410, 150, 232, 201, 154, 190, 330,
        //       410, 150, 232, 201, 154, 190, 330, 410, 150, 232, 201,
        //     ],
        //   },
        //   {
        //     name: "车间4",
        //     type: "bar",
        //     data: [
        //       320, 332, 301, 334, 390, 330, 320, 320, 332, 301, 334, 390, 330,
        //       320, 320, 332, 301, 334, 390, 330, 320, 320, 332, 301,
        //     ],
        //   },
        //   {
        //     name: "综合楼",
        //     type: "bar",
        //     data: [
        //       820, 932, 901, 934, 1290, 1330, 1320, 820, 932, 901, 934, 1290,
        //       1330, 1320, 820, 932, 901, 934, 1290, 1330, 1320, 820, 932, 901,
        //     ],
        //   },
        //   {
        //     name: "辅助车间",
        //     type: "bar",
        //     data: [
        //       220, 12, 201, 234, 290, 301, 220, 120, 232, 101, 134, 129, 133,
        //       132, 220, 132, 201, 234, 129, 103, 120, 220, 132, 201,
        //     ],
        //   },
        //   {
        //     name: "生活区域",
        //     type: "bar",
        //     data: [
        //       420, 332, 301, 434, 329, 330, 300, 420, 432, 401, 334, 390, 389,
        //       325, 420, 432, 401, 334, 390, 330, 307, 420, 432, 301,
        //     ],
        //   },
        // ],
        series: this.allValuesArray,
      };

      option && myChart.setOption(option);
    },
  },
  mounted() {
    this.getData();
    this.getList();
    this.getAreaList();
    this.getArea();

    this.filteredTreeData = this.data;
    this.box1();
    this.box2();
  },
};
</script>
  
<style scoped>
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  position: relative;
  width: 100vw;
  height: 6vh;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  /* 设置下边框高度 */
  background-color: #ccc;
  /* 设置下边框颜色 */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  /* 设置下边框内部阴影 */
}

.tab {
  width: 70vw;
  padding: 10px;
  box-sizing: border-box;
}

.btn {
  width: 30vw;
  padding: 10px;
  box-sizing: border-box;
}

.box {
  width: 100vw;
  height: 94vh;
  display: flex;
}

.left {
  width: 8vw;
  display: flex;
  flex-direction: column;
  background-color: #cfd3dc;
  overflow: hidden;
}

.title {
  width: 100%;
  box-sizing: border-box;
}

.inline-input {
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;
}

.select {
  width: 12vw;
  height: 80vh;
}

.right {
  width: 77vw;
  display: flex;
  flex-direction: column;
}

.data {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.date {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

/* .data .active {
  background-color: rgb(0, 204, 255);
  color: white;
} */

.image {
  width: 77vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.center,
.center-bottom {
  width: 77vw;
  height: 40vh;
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;
  margin-top: 1rem;
}

.box1,
.box2,
.box3,
.box4,
.box5,
.box6 {
  flex: 1;
  height: 40vh;
  border: 2px solid #ccc;
  border-radius: 4px;
  margin: 8px;
  margin-bottom: 20px;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 12rem;
}
::v-deep .el-date-editor .el-range-separator {
  height: 100%;
  padding: 0 0.3rem;
  margin: 0;
  text-align: center;
  line-height: 2rem;
  font-size: 0.8rem;
  width: 5%;
  color: #303133;
}
::v-deep .el-date-editor .el-range__icon {
  line-height: 2rem;
}
::v-deep .el-date-editor .el-range-input {
  font-size: 0.8rem;
}
::v-deep .el-range-editor.el-input__inner {
  width: 16rem !important;
  border-radius: 0.25rem;
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.9rem !important;
  font-size: 0.8rem;
}
.el-button {
  width: 4rem;
  height: 2rem;
  font-size: 0.8rem !important;
  padding: 0;
}

::v-deep .el-radio-button__inner {
  padding: 0.5rem 1rem !important;
  font-size: 0.8rem !important;
}

::v-deep .el-input--suffix .el-input__inner {
  padding-left: 1.8rem !important;
  padding-right: 1.8rem !important;
}
::v-deep .el-input__inner {
  width: 12rem;
  border-radius: 0.25rem;
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.9rem;
  font-size: 0.8rem;
}
::v-deep .el-input {
  font-size: 0.8rem;
}
::v-deep .el-input__icon {
  width: 1.5rem;
  font-size: 0.8rem !important;
  line-height: 2rem;
}
.el-date-editor .el-range__icon {
  font-size: 0.8rem !important;
  line-height: 2rem;
}
::v-deep .el-date-editor .el-input,
.el-date-editor .el-input__inner {
  width: 12rem;
}
::v-deep .el-date-editor .el-range-input {
  font-size: 0.6rem;
}
::v-deep .el-date-editor .el-range__icon {
  font-size: 0.8rem;
  line-height: 1.9rem;
}
span {
  font-size: 0.8rem !important;
}
</style>
  