<template>
  <div style="padding: 1.5vw; height: 100vh; overflow-y: auto">
    <div style="width: 39rem;display: flex;">
          <div class="content">
            <el-radio-group
              v-model="radio3"
              size="small"
              style="margin-left: 1.5vw; margin-right: 1.5vw"
            >
              <!-- <el-radio-button label="月"></el-radio-button>
              <el-radio-button label="年"></el-radio-button> -->
              <!-- <el-radio-button label="自定义"></el-radio-button> -->
            </el-radio-group>
            <el-date-picker
              v-if="radio3 === '月'"
              v-model="value2"
              type="month"
              placeholder="选择月"
            >
            </el-date-picker>
            <el-date-picker
              v-if="radio3 === '年'"
              v-model="value2"
              type="year"
              placeholder="选择年"
            >
            </el-date-picker>
            <el-date-picker
              v-if="radio3 === '自定义'"
              v-model="value1"
              type="date"
            >
            </el-date-picker>
          </div>
          <el-button
            size="small"
            type="primary"
            style="margin-left: 1vw"
            @click="onClick"
            >查询</el-button
          >
    </div>
    <div class="echarts-box">
      <div class="top">
        <div id="left"></div>
        <div id="right"></div>
      </div>
      <div class="footer">
        <div id="pieLeft"></div>
        <div id="pieRight"></div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      radio3: "自定义",
      value1: "2024-05-20",
      value2: "2024",
      echarts: "",
      data: "",
    };
  },
  methods: {
    onClick() {
      this.data = this.generateRandomArray();
      this.echarts = this.generateRandomArray();
      console.log(this.data);
      this.left();
      this.right();
    },
    generateRandomArray() {
      const array = [];
      for (let i = 0; i < 24; i++) {
        const randomNum = Math.floor(Math.random() * 501);
        array.push(randomNum);
      }
      return array;
    },
    left() {
      var chartDom = document.getElementById("left");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [
              "00:00",
              "01:00",
              "02:00",
              "03:00",
              "04:00",
              "05:00",
              "06:00",
              "07:00",
              "08:00",
              "09:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
              "24:00",
            ],
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单位:吨",
          },
        ],
        series: [
          {
            name: "Direct",
            type: "bar",
            barWidth: "60%",
            data: this.data,
          },
        ],
        title: {
          text: "碳排放趋势", // 头部标题
          left: "center",
          top: "0",
        },
      };

      option && myChart.setOption(option);
    },
    right() {
      var chartDom = document.getElementById("right");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [
              "00:00",
              "01:00",
              "02:00",
              "03:00",
              "04:00",
              "05:00",
              "06:00",
              "07:00",
              "08:00",
              "09:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
              "24:00",
            ],
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单位:吨",
            axisLine: {
              show: true, // Y 轴线
              lineStyle: {
                type: "solid", // 设置为实线
                color: "#333",
              },
            },
          },
        ],
        series: [
          {
            name: "Direct",
            type: "bar",
            barWidth: "60%",
            data: this.echarts,
          },
        ],
        title: {
          text: "区域排名", // 头部标题
          left: "center",
          top: "0",
        },
      };

      option && myChart.setOption(option);
    },
    pieLeft() {
      var chartDom = document.getElementById("pieLeft");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          text: "碳排放主要来源",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "horizontal",
          bottom: 10, // 距离底部的距离
        },
        series: [
          {
            name: "碳排放主要来源",
            type: "pie",
            radius: "50%",
            data: [{ value: 300, name: "外购电力间接排放" }],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
    pieRight() {
      var chartDom = document.getElementById("pieRight");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          text: "外购电力间接排放",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "horizontal",
          bottom: 10, // 距离底部的距离
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: "50%",
            data: [{ value: 300, name: "外购电力间接排放" }],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
  mounted() {
    this.onClick();

    this.left();
    this.right();
    this.pieLeft();
    this.pieRight();
  },
};
</script>
  
  <style lang="scss" scoped>
.echarts-box {
  width: 86vw;
  height: 120vh;
  // background-color: pink;
  margin: 2vh;
  .top {
    display: flex;
    #left {
      width: 44vw;
      height: 40vh;
      // background-color: #fff;
    }
    #right {
      flex: 1;
      width: 44vw;
      height: 40vh;
      // background-color: #fff;
    }
  }
  .footer {
    margin-top: 5vh;
    display: flex;
    #pieLeft {
      width: 44vw;
      height: 40vh;
      // background-color: #fff;
    }
    #pieRight {
      flex: 1;
      width: 44vw;
      height: 40vh;
      // background-color: #fff;
    }
  }
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 12rem;
}
::v-deep .el-date-editor .el-range-separator {
  height: 100%;
  padding: 0 0.3rem;
  margin: 0;
  text-align: center;
  line-height: 2rem;
  font-size: 0.8rem;
  width: 5%;
  color: #303133;
}
::v-deep .el-date-editor .el-range__icon {
  line-height: 2rem;
}
::v-deep .el-date-editor .el-range-input {
  font-size: 0.8rem;
}
::v-deep .el-range-editor.el-input__inner {
  width: 16rem !important;
  border-radius: 0.25rem;
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.9rem !important;
  font-size: 0.8rem;
}
.el-button {
  width: 4rem;
  height: 2rem;
  font-size: 0.8rem !important;
  padding: 0;
}

::v-deep .el-radio-button__inner {
  padding: 0.5rem 1rem !important;
  font-size: 0.8rem !important;
}

::v-deep .el-input--suffix .el-input__inner {
  padding-left: 1.8rem !important;
  padding-right: 1.8rem !important;
}
::v-deep .el-input__inner {
  width: 12rem;
  border-radius: 0.25rem;
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.9rem;
  font-size: 0.8rem;
}
::v-deep .el-input {
  font-size: 0.8rem;
}
::v-deep .el-input__icon {
  width: 1.5rem;
  font-size: 0.8rem !important;
  line-height: 2rem;
}
.el-date-editor .el-range__icon {
  font-size: 0.8rem !important;
  line-height: 2rem;
}
::v-deep .el-date-editor .el-input,
.el-date-editor .el-input__inner {
  width: 12rem;
}
::v-deep .el-date-editor .el-range-input {
  font-size: 0.6rem;
}
::v-deep .el-date-editor .el-range__icon {
  font-size: 0.8rem;
  line-height: 1.9rem;
}
span {
  font-size: 0.8rem !important;
}
</style>