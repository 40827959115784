function  getTodayTimeArray() {
    // 获取当前日期
    var today = new Date();

    // 设置时间为当天的起始时间（00:00:00）
    var startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    // 设置时间为当天的结束时间（23:59:59）
    var endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);

    // 格式化日期为指定格式
    function formatDate(date) {
        var year = date.getFullYear();
        var month = ('0' + (date.getMonth() + 1)).slice(-2);
        var day = ('0' + date.getDate()).slice(-2);
        var hours = ('0' + date.getHours()).slice(-2);
        var minutes = ('0' + date.getMinutes()).slice(-2);
        var seconds = ('0' + date.getSeconds()).slice(-2);
        return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    }

    // 返回格式化后的时间数组
    return [formatDate(startDate), formatDate(endDate)];
}
export default getTodayTimeArray